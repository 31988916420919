import * as actions_invoices from 'actions/invoices-actions';
import * as actions_quickbooks from 'actions/quickbooks-actions';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { FormBuilder, ValidateForm, ModalForm } from 'enspire-manager-framework';
import { INVOICES, PAYMENTS } from 'components/constants';
import { Table } from 'em-table';
import { receive_payment_fields, receive_payment_form_layout } from './receive-payment-form-layout';
import { sortByField } from 'components/common/toolbox';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';

var _ = require('lodash');

const ReceivePaymentForm = (props) => {

	/* Hooks ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------*/

	const params = useParams();
	const dispatch = useDispatch();
	const history = useHistory();

	const quickbooksSettings = useSelector((store) => store.quickbooks.quickbooks);
	const settings = useSelector((store) => store.settings);
	var invoices = useSelector((store) => store.invoices);
	var payments = invoices?.payments?.filter((p) => p.statusId == PAYMENTS.SUCCESS.id);

	const [openInvoices, setOpenInvoices] = useState([]);
	const [creditsUsed, setCreditsUsed] = useState([]);

	const [state, setState] = useState({
		populated: false,
		form_error: null,
		receive_payment: receive_payment_fields(),
		invoices: [],
		checked: false,
		amountEdited: false
	});
	
	const isEdit = props?.edit;
	const fromCustomerDashboard = (!parseInt(params?.request_id));
	const taxRates = settings?.settings?.invoices?.tax_rates;
	
	if (isEdit) payments = payments?.filter((p) => p.id != params?.payment_id);
	const payment = invoices?.payments?.find((p) => p.id == params?.payment_id);
	
	var invoicesList = invoices?.invoices ?? []; //?.filter((i) => i.id == params.invoice_id);

	if (isEdit && payment) invoicesList = invoicesList?.map((invoice) => {
		if (payment.invoices.includes(invoice.id)) {
			// setState(prev => ({ ...prev, invoices: [...prev.invoices, i] }));
			return { ...invoice, statusId: INVOICES.SENT.id };
		} else return invoice;
	});

	/* Hooks ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------*/

	useEffect(() => {
		dispatch(actions_invoices.subPaymentsByField(params.handle, 'customerId', params.customer_id));
		dispatch(actions_invoices.subInvoicesByField(params.handle, 'profileId', params.customer_id));

		return () => {
			var unsubscribe = invoices.payments_unsubscribe;
			if (typeof unsubscribe === 'function') unsubscribe();

			var unsubscribe = invoices.invoices_unsubscribe;
			if (typeof unsubscribe === 'function') unsubscribe();
		};
	}, []);

	useEffect(() => {
		if (payments?.length) {
			var paymentsWithCredit = payments?.filter((p) => p.unapplied > 0);
			paymentsWithCredit = paymentsWithCredit?.map((p) => ({ ...p, openBalance: parseFloat(p.unapplied).toFixed(2), originalAmount: parseFloat(p.amount).toFixed(2) }));

			setCreditsUsed(paymentsWithCredit);
			const credit = (paymentsWithCredit?.reduce((a, b) => a + parseFloat(b.unapplied), 0.00) ?? 0.00);
		}
	}, [invoices?.payments]);

	useEffect(() => {
		if (invoicesList?.length) {
			var formattedInvoicesList = invoicesList?.map((invoice) => ({ ...invoice,
				_stripe_color: Object.values(INVOICES).find((o) => o.id == invoice.statusId)?.color,
				created: moment(invoice.created.seconds, 'X').format('MM/DD/YYYY'),
				checked: state.invoices?.find((w) => w.id == invoice.id) ? true : false,
				originalAmount: parseFloat(getTotal([invoice]).toFixed(2)),
				openBalance: (getTotal([invoice]).toFixed(2) - (invoice.applied ?? 0.00).toFixed(2)).toFixed(2),
			}));
			formattedInvoicesList = formattedInvoicesList.filter((invoice) => invoice.statusId != INVOICES.PAID.id);
			formattedInvoicesList = sortByField(formattedInvoicesList, 'originalAmount', 'desc');
			formattedInvoicesList = formattedInvoicesList?.map((invoice) => ({ ...invoice, originalAmount: invoice?.originalAmount?.toFixed(2) }));
			setOpenInvoices(formattedInvoicesList);
		}
	}, [invoices?.invoices]);

	// If editing, prepopulate the form
	useEffect(() => {
		if (isEdit && payment && invoices?.invoices) {
			setState(prev => ({ ...prev, receive_payment: { ...prev.receive_payment, ...payment } }));

			var newInvoiceList = [...(invoicesList ?? [])];

			payment.invoices.forEach((i) => {
				const index = newInvoiceList.findIndex((wo) => wo.id == i.id);
				if (index > -1) {
					newInvoiceList[index].applied -= parseFloat(i.amount);
					newInvoiceList[index].payment = i.amount;
					newInvoiceList[index].checked = true;

					const paymentsIndex = newInvoiceList[index].payments.findIndex((p) => p.id == payment.id);
					newInvoiceList[index].payments.splice(paymentsIndex, 1);
				}
				if (getTotal([newInvoiceList[index]]) > newInvoiceList[index].applied) newInvoiceList[index].statusId = INVOICES.SENT.id;
			});

			newInvoiceList = newInvoiceList?.map((invoice) => ({ ...invoice,
				_stripe_color: Object.values(INVOICES).find((o) => o.id == invoice.statusId)?.color,
				created: moment(invoice.created.seconds, 'X').format('MM/DD/YYYY'),
				_disable_select: (invoice.statusId == INVOICES.PAID.id),
				originalAmount: parseFloat(getTotal([invoice]).toFixed(2)),
				openBalance: (getTotal([invoice]).toFixed(2) - (invoice.applied ?? 0.00).toFixed(2)).toFixed(2),
			}));
			newInvoiceList = newInvoiceList.filter((invoice) => invoice.statusId != INVOICES.PAID.id);
			newInvoiceList = sortByField(newInvoiceList, 'originalAmount', 'desc');
			newInvoiceList = newInvoiceList?.map((invoice) => ({ ...invoice, originalAmount: invoice?.originalAmount?.toFixed(2) }));
			setOpenInvoices(newInvoiceList);
		}
	}, [params?.payment_id, invoices.invoices]);

	/* Handlers ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------*/

	const handleChange = (event, value) => {
		let newValue = (value) ? value : event.target.value;
		let form_error = _.filter(state.form_error, (o) => { return o.field !== event.target.name; });
		setState(prev => ({ ...prev, form_error, amountEdited: (event.target.name == "amount") ? true : prev.amountEdited, receive_payment: { ...state.receive_payment, [event.target.name]: newValue } }));
	};

	const handleSelect = (item, type) => {

		if (type == 'invoices') {
			let newInvoices = (openInvoices.length) ? [...openInvoices] : [];
			let newAmount = (state.receive_payment.amount) ? state.receive_payment.amount : 0;

			const index = newInvoices.findIndex((wo) => wo.id == item.id);
			if (index > -1) {
				newInvoices[index].checked = !newInvoices[index].checked;

				if (newInvoices[index].checked) {
					newAmount = parseFloat(newAmount) + parseFloat(newInvoices[index].openBalance);
					newInvoices[index].payment = newInvoices[index].openBalance;
				} else {
					newAmount = parseFloat(newAmount) - parseFloat(newInvoices[index].payment);
					newInvoices[index].payment = 0;
				}
			}

			if (!state.amountEdited) {
				setState(prev => ({ ...prev, receive_payment: { ...prev.receive_payment, amount: parseFloat(newAmount).toFixed(2) } }));
			}
			setOpenInvoices(newInvoices);

		} else {
			let newCreditsUsed = (creditsUsed) ? [...creditsUsed] : [];

			const index = creditsUsed.findIndex((p) => p.id == item.id);
			if (index > -1) {
				newCreditsUsed[index].checked = !newCreditsUsed[index].checked;

				if (newCreditsUsed[index].checked) {
					newCreditsUsed[index].payment = newCreditsUsed[index].openBalance;
				} else {
					newCreditsUsed[index].payment = 0;
				}
			}
			setCreditsUsed(newCreditsUsed);
		}
	};

	const handleEdit = (field, item, newValue, type) => {
		const itemCopy = { ...item };

		if (type == 'invoices') {
			const index = openInvoices.findIndex((wo) => wo.id == item.id);
			if (index > -1) {
				let newInvoices = [...openInvoices];
				newInvoices[index].payment = newValue;

				if (newValue <= 0) newInvoices[index].checked = false;
				else newInvoices[index].checked = true;

				if (!state.amountEdited) {

					let newAmount = (state.receive_payment.amount) ? parseFloat(state.receive_payment.amount) : 0;
					newAmount -= parseFloat(itemCopy?.payment ?? 0);
					newAmount += parseFloat(newValue);

					setState(prev => ({ ...prev, receive_payment: { ...prev.receive_payment, amount: parseFloat(newAmount).toFixed(2) } }));
				}

				setOpenInvoices(newInvoices);
			}
		} else {
			const index = creditsUsed.findIndex((wo) => wo.id == item.id);
			if (index > -1) {
				let newCreditsUsed = [...creditsUsed];
				newCreditsUsed[index].payment = newValue;

				if (newValue <= 0) newCreditsUsed[index].checked = false;
				else newCreditsUsed[index].checked = true;

				setCreditsUsed(newCreditsUsed);
			}
		}

	};

	/* Actions ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------*/

	const submitForm = (receive_payment_form_layout) => {
		var form_error = ValidateForm(state.receive_payment, receive_payment_form_layout);

		if (creditsUsedTotal > 0 && !state.receive_payment.amount) {
			form_error = form_error.filter((e) => e.field != 'amount' && e.field != 'method');
		}
		setState(prev => ({ ...prev, form_error }));

		if (state.receive_payment.amount <= 0 && creditsUsedTotal <= 0) {
			window.toastr.error('The Payment Amount must be greater than $0', 'Payment Amount Error');
			return;
		}
		if (!form_error.length) {

			var paymentObject = {
				...state.receive_payment,
				statusId: PAYMENTS.SUCCESS.id,
				customerId: params.customer_id,
				requestId: params.request_id,
				invoices: openInvoices.filter((i) => i.checked),
			};

			if (fromCustomerDashboard) delete paymentObject.requestId;
			if (isEdit) {
				payment.invoices.forEach((i) => {
					if (!paymentObject.invoices.find((inv) => inv.id == i.id)) {
						paymentObject.invoices.push(openInvoices.find((inv) => inv.id == i.id));
					}
				});
			}

			dispatch(actions_invoices.savePayment(params.handle, paymentObject, (isEdit) ? params?.payment_id : null, taxRates, creditsUsed, (payment_id) => {
				dispatch(actions_quickbooks.updateQuickbooks({ handle: params.handle, type: 'payment' }, quickbooksSettings));

				if (props?.setState) {
					props.setState(prev => ({ ...prev, showEditPayment: false }));
				} else {
					history.goBack();
				}
			}));
		}
	};

	const getTotal = (invoices) => invoices?.reduce((total, invoice) => {
		let taxRate = settings?.settings?.invoices?.tax_rates?.[invoice?.taxRateId]?.rate ?? 0;
		return total + parseFloat(invoice.serviceItems.reduce((serviceTotal, item) => {
			let tax = (item.taxable && taxRate) ? taxRate / 100 * parseFloat(item.calculatedPrice) : 0;
			return serviceTotal + tax + parseFloat(item.calculatedPrice);
		}, 0));
	}, 0) ?? 0.00;


	/* Constants ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------*/

	var paymentsWithCredit = payments?.filter((p) => p.unapplied > 0);
	paymentsWithCredit = paymentsWithCredit?.map((p) => ({ ...p, openBalance: parseFloat(p.unapplied).toFixed(2), originalAmount: parseFloat(p.amount).toFixed(2) }));
	const credit = (paymentsWithCredit?.reduce((total, b) => total + parseFloat(b.unapplied), 0.00) ?? 0.00);
	const amountApplied = openInvoices?.reduce((total, b) => total + parseFloat(b.payment ?? 0.00), 0.00);
	const creditsUsedTotal = creditsUsed?.reduce((total, b) => total + parseFloat(b.payment ?? 0.00), 0.00);
	const amountToCredit = (parseFloat((isNaN(parseFloat(state.receive_payment.amount))) ? 0 : state.receive_payment.amount)) + parseFloat(creditsUsedTotal ?? 0) - (amountApplied ?? 0);
	const receive_payment_layout = receive_payment_form_layout(state.receive_payment);

	/* Render ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------*/

	return (

		<ModalForm {...props}
			modal_header={'Receive Payment'}
			cancel_button_title="Cancel"
			save_button_title={'Receive Payment'}
			submitFormHandler={() => submitForm(receive_payment_layout)}
			hide_cancel={true}
			show_spinner={invoices.payments_save_pending}
			confirm_button_disabled={amountToCredit < 0}
			style={1}
		>
			<FormBuilder
				callbacks={{
					text: handleChange,
					select: handleChange,
				}}
				form_error={state.form_error}
				form={receive_payment_layout}
				record={state.receive_payment}
			/>
			<h3>Outstanding Invoices</h3>
			<h4 className="text-muted">Note:  Deposit Payment may be received without selecting an Invoice</h4>
			<Table
				data={openInvoices}
				show_limit={false}
				columns={[
					{ name: 'ID', field: 'id' },
					{ name: 'Created', field: 'created' },
					{ name: 'Memo', field: 'memo' },
					{ name: 'Amount', field: 'originalAmount', prefix: '$' },
					{ name: 'Balance', field: 'openBalance', prefix: '$' },
					{ name: 'Payment', field: 'payment', prefix: '$', edit: { type: 'text', callback: (field, item, newValue) => handleEdit(field, item, newValue, 'invoices') } },
					{ name: '', field: 'checked', type: 'checkbox', callback: (item) => handleSelect(item, 'invoices')},
				]}
			/>

			{credit > 0 &&
				<>
					<h3>Credits</h3>
					<Table
						data={creditsUsed}
						show_limit={false}
						columns={[
							{ name: 'Payment ID', field: 'id' },
							{ name: 'Amount', field: 'originalAmount', prefix: '$' },
							{ name: 'Open Balance', field: 'openBalance', prefix: '$' },
							{ name: 'Payment', field: 'payment', prefix: '$', edit: { type: 'text', callback: (field, item, newValue) => handleEdit(field, item, newValue, 'credits') } },
							{ name: '', field: 'checked', type: 'checkbox', callback: (item) => handleSelect(item, 'credits')},
						]}
					/>
					<hr />
				</>
			}

			<div className="row">
				<div className="col-12">
					<div className="row">
						<div className="col-8 text-right">
							<h3 className="m-1 font-weight-bold">Amount to Apply:</h3>
						</div>
						<div className="col-4 text-right">
							<h3 className="m-1">${amountApplied.toFixed(2)}</h3>
						</div>
					</div>
					{(amountToCredit != 0 && !isNaN(amountToCredit)) &&
						<div className="row">
							<div className="col-8 text-right">
								<h3 className="m-1 font-weight-bold">Amount to Credit:</h3>
							</div>
							<div className="col-4 text-right">
								<h3 className="m-1">${amountToCredit.toFixed(2)}</h3>
							</div>
						</div>
					}
					{/* 					
					<div className="row">
						<div className="col-8 text-right">
							<h3 className="m-1 font-weight-bold">Current Total:</h3>
						</div>
						<div className="col-4 text-right">
							<h3 className="m-1">${currentTotal}</h3>
						</div>
					</div>
					<div className="row">
						<div className="col-8 text-right">
							<h3 className="m-1 font-weight-bold">Paid Invoices:</h3>
						</div>
						<div className="col-4 text-right">
							<h3 className="m-1">${paid}</h3>
						</div>
					</div>
					<div className="row">
						<div className="col-8 text-right">
							<h3 className="m-1 font-weight-bold">Remaining Credit:</h3>
						</div>
						<div className="col-4 text-right">
							<h3 className="m-1">${credit.toFixed(2)}</h3>
						</div>
					</div>
					<div className="row">
						<div className="col-8 text-right">
							<h3 className="m-1 font-weight-bold">Balance:</h3>
						</div>
						<div className="col-4 text-right">
							<h3 className="m-1">${balance}</h3>
						</div>
					</div> */}
				</div>
			</div>
		</ModalForm>
	);
};

export default ReceivePaymentForm;

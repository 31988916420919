import * as toolbox from '../components/common/toolbox';
import * as types from './action-types';
import _ from 'lodash';
import dayjs from 'dayjs';
import firebase from 'firebase/compat/app';
import { ModalAlert } from 'enspire-manager-framework';
import { SERVICE_ITEMS, PURCHASE_ORDERS, UNITS } from 'components/constants';
import { collection, query, where, or, onSnapshot } from "firebase/firestore";

const firestore = firebase.firestore();

/*-----------------------------------------------*/
/*  STOCK PARTS
/*-----------------------------------------------*/

export async function exportInventory(handle, columns) {
    
	var stockParts = [];
	var snapshot = await firestore.collection(handle + '/stock-parts/stock-parts').where('deleted', '==', false).get();
	snapshot.forEach((doc) => {
		var entry = {};
		columns.forEach((column) => {
			entry[column] = doc.data()[column.replace('*','')] ?? '';
		});
		stockParts.push({ 
			...entry, 
			id: doc.id, 
			assetTypeIds: doc.data().assetTypeIds?.join(',') ?? '',
			categoryIds: doc.data().categoryIds?.join(',') ?? '',
		});
	});

	return stockParts;
}

export async function importInventoryPreview(handle, stockParts, callback) {

    var assetTypes = [];
    var snapshot = await firestore.collection(handle + "/settings/assetTypes").get();
    snapshot.forEach((doc) => {
        assetTypes.push({ ...doc.data(), id: doc.id });
    });

    var partsCategories = [];
    var snapshot = await firestore.collection(handle + "/settings/partsCategories").get();
    snapshot.forEach((doc) => {
        partsCategories.push({ ...doc.data(), id: doc.id });
    });
    
    let errors = [];
    let newAssetTypes = [];
    let newCategories = [];

    for (var stockPart of stockParts) {
    
        // check for linked AssetTypes
        if (stockPart.assetTypeIds) {
            let typeArray = stockPart.assetTypeIds.split(',');
            for (let typeId of typeArray) {
                typeId = typeId.trim();
                let assetType = _.find(assetTypes, { id: typeId});
                if (!assetType && !parseInt(typeId)) {
                    let assetByName = _.find(assetTypes, { value: typeId});
                    if (!assetByName) {
                        if (!newAssetTypes.includes(typeId)) newAssetTypes.push(typeId);
                    }
                } else {
                    errors.push(`Asset Type Id "${typeId}" not found!`);
                }
            }
        }

        // check for linked Categories   
        if (stockPart.categoryIds) {
            let categoryArray = stockPart.categoryIds.split(',');
            for (let categoryId of categoryArray) {
                categoryId = categoryId.trim();
                let category = _.find(partsCategories, { id: categoryId});
                if (!category && !parseInt(categoryId)) {
                    let categoryByName = _.find(partsCategories, { name: categoryId});
                    if (!categoryByName) {
                        if (!newCategories.includes(categoryId)) newCategories.push(categoryId);
                    }
                } else {
                    errors.push(`Category Id "${categoryId}" not found!`);
                }
            }
        }
    }

    if (typeof callback === 'function') callback({ 
        errors,
        numRecords: stockParts.length,
        createdItems: [
            ...(newAssetTypes.length) ? [{ name: 'Asset Types', records: newAssetTypes }] : [],
            ...(newCategories.length) ? [{ name: 'Categories', records: newCategories }] : [],
        ]
    });
};
export async function importItem(handle, stockPart) {

    var batch = firestore.batch();

    var stockPartId = await nextStockPartId(handle, batch);
    stockPart.type = SERVICE_ITEMS.STOCK_PARTS.id;
    stockPart.deleted = false;
    stockPart.created = new Date();
    stockPart.modified = new Date();

    // check for linked unitId
    if (stockPart.unitId) {
        let unitId = stockPart.unitId.trim();
        let unit = _.find(Object.values(UNITS), { id: unitId});
        if (unit) {
            stockPart.unitId = unitId;
        } else {
            let unit = _.find(Object.values(UNITS), { name: unitId});
            if (unit) {
                stockPart.unitId = unit.id;
            } else {
                stockPart.unitId = UNITS.EACH.id;
            }
        }
    } else {
        stockPart.unitId = UNITS.EACH.id;
    }

    // check for linked AssetTypes
    var assetIds = [];
    if (stockPart.assetTypeIds) {

        var assetTypes = [];
        var snapshot = await firestore.collection(handle + "/settings/assetTypes").get();
        snapshot.forEach((doc) => {
            assetTypes.push({ ...doc.data(), id: doc.id });
        });

        let typeArray = stockPart.assetTypeIds.split(',');
        for (let typeId of typeArray) {
            typeId = typeId.trim();
            let assetType = _.find(assetTypes, { id: typeId});
            if (assetType) {
                assetIds.push(typeId);
            } else if (!parseInt(typeId)) {
                let assetByName = _.find(assetTypes, { value: typeId});
                if (assetByName) {
                    assetIds.push(assetByName.id);
                } else {
                    let id =  await nextAssetTypeId(handle, batch);
                    const assetTypeRef = firestore.collection(handle + "/settings/assetTypes").doc(id);
                    batch.set(assetTypeRef, { value: typeId, deleted: false, position: assetTypes.length }, { merge: true });
                    assetIds.push(id);
                }
            } else {
                window.toastr.success(`The AssetTypeId: ${typeId} could not be found`, 'Stock Part NOT Saved!');
                return null;
            }
        }
    }
    stockPart.assetTypeIds = assetIds;

    // check for linked Categories
    var catIds = [];
    if (stockPart.categoryIds) {

        var partsCategories = [];
        var snapshot = await firestore.collection(handle + "/settings/partsCategories").get();
        snapshot.forEach((doc) => {
            partsCategories.push({ ...doc.data(), id: doc.id });
        });
        
        let categoryArray = stockPart.categoryIds.split(',');
        for (let categoryId of categoryArray) {
            categoryId = categoryId.trim();
            let category = _.find(partsCategories, { id: categoryId});
            if (category) {
                catIds.push(categoryId);
            } else if (!parseInt(categoryId)) {
                let categoryByName = _.find(partsCategories, { name: categoryId});
                if (categoryByName) {
                    catIds.push(categoryByName.id);
                } else {
                    let id =  await nextPartsCategoryId(handle, batch);
                    const stockPartRef = firestore.collection(handle + '/settings/partsCategories').doc(id);
                    batch.set(stockPartRef, { name: categoryId }, { merge: true });
                    catIds.push(id);
                }
            } else {
                window.toastr.success(`The CategoryId: ${categoryId} could not be found`, 'Stock Part NOT Saved!');
                return null;
            }
        }
    }
    stockPart.categoryIds = catIds;

    // check for Transfer Values: _category, _primaryVendorId, and _secondaryVendorId
    if (stockPart._category) {
        let category = _.find(partsCategories, { id: stockPart._category});
        stockPart.categoryIds = (category) ? [ category.id ] : [];
    }
    if (stockPart._primaryVendorId) {
        var query = await firestore.collection(handle + '/profiles/profiles').where('_id', '==', stockPart._primaryVendorId).get();
        if (query.docs[0]?.id) {
            stockPart.primaryVendorId = query.docs[0].id;
        }
    }
    if (stockPart._secondaryVendorId) {
        var query = await firestore.collection(handle + '/profiles/profiles').where('_id', '==', stockPart._secondaryVendorId).get();
        if (query.docs[0]?.id) {
            stockPart.secondaryVendorId = query.docs[0].id;
        }
    }
    
    const stockPartsRef = firestore.collection(handle + '/stock-parts/stock-parts');
    batch.set(stockPartsRef.doc(stockPartId), { ...stockPart }, { merge: true });

    await batch.commit().catch((error) => {
        toolbox.process_error(error, 'Stock Part NOT Saved!');
    });
};

export function subStockPart(handle, id) {

    return async dispatch => {

        dispatch({ type: types.INVENTORY_ITEM + '_PENDING' });

        var query = firestore.collection(handle + '/stock-parts/stock-parts').doc(id);

        const unsubscribe = onSnapshot(query, (doc) => {
            var stockPart = { ...doc.data(), id: doc.id };
            dispatch({ type: types.INVENTORY_ITEM + '_FULFILLED', data: stockPart, unsubscribe });
        });

    };
}

export function subInventoryByAssetId(handle, assetId) {

    return async dispatch => {

        dispatch({ type: types.INVENTORY + '_PENDING' });

        var query = firestore.collection(handle + '/stock-parts/stock-parts').where('associatedAssets', 'array-contains', assetId);

        const unsubscribe = onSnapshot(query, (querySnapshot) => {
            const stockParts = [];
            querySnapshot.forEach((doc) => { stockParts.push({ ...doc.data(), id: doc.id }); });
            dispatch({ type: types.INVENTORY + '_FULFILLED', data: stockParts, unsubscribe });
        });
    };
}

export function subInventoryByVendorId(handle, vendorId) {

    return async dispatch => {

        dispatch({ type: types.INVENTORY + '_PENDING' });

        const q = query(collection(firestore, handle + '/stock-parts/stock-parts'), or(
            where('primaryVendorId', '==', vendorId), 
            where('secondaryVendorId', '==', vendorId)
        ));

        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const stockParts = [];
            querySnapshot.forEach((doc) => { stockParts.push({ ...doc.data(), id: doc.id }); });
            dispatch({ type: types.INVENTORY + '_FULFILLED', data: stockParts, unsubscribe });
        });
    };
}

export function saveInventory({ handle, inventoryItem, itemId, silent, callback }) {

    return async dispatch => {

        dispatch({ type: types.INVENTORY + '_SAVE_PENDING' });

        var batch = firestore.batch();
        var non = (inventoryItem.type == 'non-stock')?'Non-':'';

        if (!parseInt(itemId)) {
            itemId = await nextStockPartId(handle, batch);
            inventoryItem.deleted = false;
            inventoryItem.created = new Date();
        }
        inventoryItem.modified = new Date();
        
        /* Check for newVendorName & newVendorPrice ------------------------*/

        if (inventoryItem.newVendorName) {
      
            const snapshot = await firestore.collection(handle + "/profiles/profiles").where('displayName', '==', inventoryItem.newVendorName).get();
            if (!snapshot.empty) {
                toolbox.process_error('A vendor already exists with this name!');
                dispatch({ type: types.INVENTORY + '_SAVE_FULFILLED' });
                return;
            }
            
            var profileId = await nextProfileId(handle, batch);
            
            var profile = {
                displayName: inventoryItem.newVendorName,
                type: 'vendor',
                created: new Date(),
                deleted: false,
                modified: new Date(),
            }

            if (!inventoryItem.primaryVendorId) {
                inventoryItem.primaryVendorId = profileId;
                inventoryItem.primaryPrice = inventoryItem.newVendorPrice;
            } else if (!inventoryItem.secondaryVendorId) {
                inventoryItem.secondaryVendorId = profileId;
                inventoryItem.secondaryPrice = inventoryItem.newVendorPrice;
            }
    
            const profileRef = firestore.collection(handle + '/profiles/profiles');
            batch.set(profileRef.doc(profileId), { ...profile }, { merge: true });

            delete inventoryItem.newVendorName;
            delete inventoryItem.newVendorPrice;
        }

        const stockPartsRef = firestore.collection(handle + '/stock-parts/stock-parts');
        batch.set(stockPartsRef.doc(itemId), { ...inventoryItem }, { merge: true });
        batch.commit().then(() => {
            if (inventoryItem.deleted) {
                (!silent) && window.toastr.success(`The ${non}Stock Part record has been successfully archived`, `Stock Part Archived!`);
            } else {
                (!silent) && window.toastr.success(`The ${non}Stock Part record has been successfully saved/updated`, `Stock Part Saved!`);
            }
            dispatch({ type: types.INVENTORY + '_SAVE_FULFILLED' });
            if (typeof callback === 'function') callback(itemId);
        }).catch((error) => {
            toolbox.process_error(error, `${non}Stock Part NOT Saved!`);
        });
    };
}

export function getItemById(handle, id, callback) {

    return async dispatch => {

        firestore.collection(handle + '/stock-parts/stock-parts').doc(id).get().then((doc) => {
            var item = { ...doc.data(), id: doc.id };

            if (typeof callback === 'function') callback(item);
        });
    };
}

/*-----------------------------------------------*/
/*  PURCHASE ORDERS
/*-----------------------------------------------*/

export function subOpenPurchaseOrders(handle) {

    return async dispatch => {

        dispatch({ type: types.PURCHASE_ORDERS + '_PENDING' });

        var query = firestore.collection(handle + '/purchase-orders/purchase-orders').where('deleted', '==', false);

        const unsubscribe = onSnapshot(query, (querySnapshot) => {
            var purchaseOrders = [];
            querySnapshot.forEach((doc) => { 
                purchaseOrders.push({ ...doc.data(), id: doc.id });
            });
            dispatch({ type: types.PURCHASE_ORDERS + '_FULFILLED', data: purchaseOrders, unsubscribe });
        });
    };
}

export function subOpenPurchaseOrdersByVendor(handle, vendorId) {

    return async dispatch => {

        dispatch({ type: types.PURCHASE_ORDERS + '_PENDING' });

        var query = firestore.collection(handle + '/purchase-orders/purchase-orders').where('deleted', '==', false)
            .where('vendorId', '==', vendorId);

        const unsubscribe = onSnapshot(query, (querySnapshot) => {
            var purchaseOrders = [];
            querySnapshot.forEach((doc) => { 
                purchaseOrders.push({ ...doc.data(), id: doc.id });
            });
            dispatch({ type: types.PURCHASE_ORDERS + '_FULFILLED', data: purchaseOrders, unsubscribe });
        });
    };
}

export function subPurchaseOrder(handle, id) {

    return async dispatch => {

        dispatch({ type: types.PURCHASE_ORDER + '_PENDING' });

        var query = firestore.collection(handle + '/purchase-orders/purchase-orders').doc(id);

        const unsubscribe = onSnapshot(query, (doc) => {
            var purchaseOrder = { ...doc.data(), id: doc.id };
            dispatch({ type: types.PURCHASE_ORDER + '_FULFILLED', data: purchaseOrder, unsubscribe });
        });
    };
}

export function newPurchaseOrder(handle, vendorId, statusId, serviceItems, callback) {

    return async dispatch => {

        dispatch({ type: types.PURCHASE_ORDER + '_SAVE_PENDING' });

        var batch = firestore.batch();

        var purchaseOrderId = 'A281'; await nextPurchaseOrderId(handle, batch);
        var newPO = {
            vendorId: vendorId,
            statusId: (statusId) ? statusId : PURCHASE_ORDERS.DRAFT.id,
            serviceItems: serviceItems,
            deleted: false,
            created: new Date(),
            modified: new Date(),
        }
        
        const purchaseOrdersRef = firestore.collection(handle + '/purchase-orders/purchase-orders');
        batch.set(purchaseOrdersRef.doc(purchaseOrderId), { ...newPO });
        batch.commit().then(() => {
            window.toastr.success('The Purchase Order has been successfully created', 'Purchase Order Created!');
            dispatch({ type: types.PURCHASE_ORDER + '_SAVE_FULFILLED' });
            if (typeof callback === 'function') callback(purchaseOrderId);
        }).catch((error) => {
            toolbox.process_error(error, 'Purchase Order NOT Created!');
        });
    };
}
export function convertPurchaseOrder(handle, autoPurchaseOrderId, vendorId, statusId, serviceItems, callback) {

    return async dispatch => {

        dispatch({ type: types.PURCHASE_ORDER + '_SAVE_PENDING' });

        var batch = firestore.batch();

        var purchaseOrderId = await nextPurchaseOrderId(handle, batch);
        var newPO = {
            vendorId: vendorId,
            statusId: (statusId) ? statusId : PURCHASE_ORDERS.DRAFT.id,
            ... (statusId == PURCHASE_ORDERS.RECEIVED.id) ? { dateReceived: new Date() } : {},
            ... (statusId == PURCHASE_ORDERS.PAID.id) ? { datePaid: new Date() } : {},
            serviceItems: serviceItems,
            deleted: false,
            created: new Date(),
            modified: new Date(),
        }
        
        const purchaseOrdersRef = firestore.collection(handle + '/purchase-orders/purchase-orders');
        batch.set(purchaseOrdersRef.doc(purchaseOrderId), { ...newPO });
		batch.delete(firestore.collection(handle + '/purchase-orders/purchase-orders').doc(autoPurchaseOrderId));
        batch.commit().then(() => {
            window.toastr.success('The Purchase Order has been successfully converted', 'Purchase Order Converted!');
            dispatch({ type: types.PURCHASE_ORDER + '_SAVE_FULFILLED' });
            if (typeof callback === 'function') callback(purchaseOrderId);
        }).catch((error) => {
            toolbox.process_error(error, 'Purchase Order NOT Converted!');
        });
    };
}

export function updatePurchaseOrder(handle, update, purchaseOrderId, callback, silent) {

	return async dispatch => {
		const batch = firestore.batch();
		batch.update(firestore.collection(handle + '/purchase-orders/purchase-orders').doc(purchaseOrderId), { ...update, modified: new Date() });

		batch.commit().then(() => {
			dispatch({ type: types.PURCHASE_ORDER + '_SAVE_FULFILLED' });
			if (!silent) window.toastr.success('The Purchase Order has been successfully saved/updated', 'Purchase Order Saved!');
			if (typeof callback === 'function') callback();

		}).catch((error) => {
			toolbox.process_error(error, 'Record NOT Saved!');
		});
	};
}

export function deletePurchaseOrder(handle, purchaseOrderId, callback) {

	return async dispatch => {

		dispatch({ type: types.PURCHASE_ORDER + '_SAVE_PENDING' });

		firestore.collection(handle + '/purchase-orders/purchase-orders').doc(purchaseOrderId).delete().then(() => {
			window.toastr.success('The Purchase Order has been permanently deleted', 'Purchase Order Saved!');
			dispatch({ type: types.PURCHASE_ORDER + '_SAVE_FULFILLED' });
			if (typeof callback === 'function') callback();
		}).catch((error) => {
			toolbox.process_error(error, 'Purchase Order NOT Saved!');
		});
	};
}
export function createPurchaseOrderPdf(options, callback) {
	return async dispatch => {
		firebase.functions().httpsCallable('createPurchaseOrderPdf')(options).then((result) => {
			if (typeof callback === 'function') callback(result);
		}).catch((error) => {
			console.log(error);
		});
	};
}

/*-----------------------------------------------*/
/*  QUICKBOOKS
/*-----------------------------------------------*/

export function importQBInventory(handle, inventory, importType, index, importId, quickbooksSettings, callback, lastSelection, applyToAll) {

	return async dispatch => {

		if (index == 0) dispatch({ type: types.QUICKBOOKS_IMPORT + '_PENDING' });
        if (!importId) importId = dayjs().format('YYYYMMDDHHmmssSSS');

		const item = inventory[index];
		const existingSnapshot = await firestore.collection(handle + '/stock-parts/stock-parts').where('nameSearchable', '==', toolbox.removeWhitespace(item.Name).toLowerCase()).get();
		const existing = { ...existingSnapshot.docs[0]?.data(), id: existingSnapshot.docs[0]?.id };

		if (existing.id) {
			if (applyToAll) {
				if (lastSelection != 'skip') {
					callMerge(lastSelection, true);
				} else {
					// Run next iteration
					if (index == inventory.length - 1) {
						dispatch({ type: types.QUICKBOOKS_IMPORT + '_FULFILLED' });
					} else {
						dispatch(importQBInventory(handle, inventory, importType, index + 1, importId, quickbooksSettings, callback, 'skip', true));
					}
				}
			} else {
				ModalAlert({
					title: `Existing Item: "${item.Name}" already exists`,
					text: "These Items will be merged into one. Which data should take priority?",
					icon: 'warning',
					input: 'radio',
					value: (lastSelection) ? lastSelection : 'quickbooks',
					options: {
						'quickbooks': 'QuickBooks',
						'mobiletrack': 'MobileTrack',
						'skip': 'Skip Merge'
					},

					// Continue
					confirm_color: '#8FBC8B',
					confirm_text: 'Continue',
					callback_success: async (inputValue) => {
						if (inputValue != 'skip') {
							callMerge(inputValue, false);
						} else {
							// Run next iteration
							if (index == inventory.length - 1) {
								dispatch({ type: types.QUICKBOOKS_IMPORT + '_FULFILLED' });
							} else {
								dispatch(importQBInventory(handle, inventory, importType, index + 1, importId, quickbooksSettings, callback, 'skip'));
							}
						}
					},

					// Apply to All
					show_deny: (index != inventory.length - 1),
					deny_color: '#4682B4',
					deny_text: 'Apply to All',
					callback_denied: async (inputValue) => {
						if (inputValue != 'skip') {
							callMerge(inputValue, true);
						} else {
							// Run next iteration
							if (index == inventory.length - 1) {
								dispatch({ type: types.QUICKBOOKS_IMPORT + '_FULFILLED' });
							} else {
								dispatch(importQBInventory(handle, inventory, importType, index + 1, importId, quickbooksSettings, callback, 'skip', true));
							}
						}
					},
					show_cancel: false,
				});
			}

		} else {
            const inventoryItem = {
				type: importType,
				name: toolbox.removeWhitespace(item.Name),
				nameSearchable: toolbox.removeWhitespace(item.Name)?.toLowerCase(),
				description: item?.Description,
				pricePerUnit: item.UnitPrice,
				unitTypeId: UNITS.EACH.id,

				quickbooksId: item.Id,
				importId: importId,
			};

			Object.keys(inventoryItem).forEach((key) => { if (inventoryItem[key] == undefined) inventoryItem[key] = ''; });

            dispatch(saveInventory({ 
                handle, 
                inventoryItem, 
                itemId: '0', 
                silent: true, 
                callback: () => {
                    window.toastr.success(`Inventory Item(s) imported successfully`, 'Item(s) Saved!');

                    const quickbooksSettingsRef = firestore.collection(handle + '/settings/quickbooks').doc('quickbooks');
                    quickbooksSettingsRef.set({ 
                        lastImport: { importId, importType },
                        importHistory: firebase.firestore.FieldValue.arrayUnion({importId, importType}),
                    }, { merge: true });

                    // Run next iteration
                    if (index == inventory.length - 1) {
                        dispatch({ type: types.QUICKBOOKS_IMPORT + '_FULFILLED' });
                    } else {
                        dispatch(importQBInventory(handle, inventory, importType, index + 1, importId, quickbooksSettings, callback));
                    }
                }
            }));
		}
        function callMerge(priority, applyToAll) {
            mergeItems({
                handle: handle,
                itemId: existing.id,
				QBName: item?.Name, // QB non-whitespace-removed version
                priority,
                importType,
                final: (index == inventory.length - 1)
            }, quickbooksSettings, () => {
                // Run next iteration
                if (index == inventory.length - 1) {
                    dispatch({ type: types.QUICKBOOKS_IMPORT + '_FULFILLED' });
                } else {
                    dispatch(importQBInventory(handle, inventory, importType, index + 1, importId, quickbooksSettings, callback, priority, applyToAll));
                }
            });
        };
	};
}
export function mergeItems(options, quickbooksSettings, callback) {

    if (quickbooksSettings?.useEmulator) firebase.functions().useEmulator("localhost", 5001); // Testing locally with emulator
    firebase.functions().httpsCallable('mergeItems')(options).then(() => {
    	window.toastr.success(`Item merged successfully`, 'Item Saved!');
    	if (typeof callback == 'function') callback();
    }).catch((error) => {
    	window.toastr.error(error, 'Error Saving Item!');
    	if (typeof callback == 'function') callback();
    });
}
export function undoInventoryImport(handle, lastImport) {
    
	return async dispatch => {
		var batch = firestore.batch();

		// Get all Item records with this importId
		const itemsRef = firestore.collection(handle + '/stock-parts/stock-parts').where('importId', '==', lastImport.importId);
		const itemsSnapshot = await itemsRef.get();
		itemsSnapshot.forEach((doc) => {
			batch.delete(firestore.collection(handle + '/stock-parts/stock-parts').doc(doc.id));
		});

		// Remove lastImport information from Quickbooks settings
		const quickbooksSettingsRef = firestore.collection(handle + '/settings/quickbooks').doc('quickbooks');
		batch.set(quickbooksSettingsRef, { 
            lastImport: firebase.firestore.FieldValue.delete(),
            importHistory: firebase.firestore.FieldValue.arrayRemove(lastImport),
        }, { merge: true });

		batch.commit().then(() => {
			window.toastr.success('The import has been successfully undone', 'Import Undone!');
		}).catch((error) => {
			window.toastr.error('Error: ' + error, 'Import NOT Undone!');
		});
	};
}


async function nextAssetTypeId(handle, batch) {
	const table = 'settings';
	const field = 'nextAssetTypeId';
	const startingId = 1000;

	return toolbox.nextId(handle, batch, table, field, startingId);
}
async function nextPartsCategoryId(handle, batch) {
    const table = 'settings';
    const field = 'nextPartsCategoryId';
    const startingId = 3000;

    return toolbox.nextId(handle, batch, table, field, startingId);
}
async function nextPurchaseOrderId(handle, batch) {
    const table = 'purchase-orders';
    const field = 'nextPurchaseOrderId';
    const startingId = 10000;

    return toolbox.nextId(handle, batch, table, field, startingId);
}
async function nextStockPartId(handle, batch) {
    const table = 'stock-parts';
    const field = 'nextStockPartId';
    const startingId = 5000;

    return toolbox.nextId(handle, batch, table, field, startingId);
}
async function nextProfileId(handle, batch) {
	const table = 'profiles';
	const field = 'nextProfileId';
	const startingId = 1000;

	return toolbox.nextId(handle, batch, table, field, startingId);
}

export function transform(handle) {

    return async dispatch => {

        // const batch = firestore.batch();
        // const stockPartsRef = firestore.collection(handle + '/stock-parts/stock-parts');
        
        // var stockParts = [];
        // const snapshot = await stockPartsRef.get();
        // snapshot.forEach((doc) => {
        //     stockParts.push({ ...doc.data(), id: doc.id });
        // });

        // for (let stockPart of stockParts) {
        //     let update = { ...stockPart,
        //         primaryPrice: stockPart.price,
        //         averageCost: stockPart.price,
        //         price: firebase.firestore.FieldValue.delete(),
        //         currentInventory: stockPart.inventory,
        //         inventory: firebase.firestore.FieldValue.delete()
        //     }
        //     console.log(stockPart.id, stockPart.price, stockPart.inventory);
            
        //     batch.update(stockPartsRef.doc(stockPart.id), { ...update });
        // }
        
        // batch.commit().then(() => {
        //     window.toastr.success('The Stock Part record has been successfully saved/updated', 'Stock Part Saved!');
        // }).catch((error) => {
        //     toolbox.process_error(error, 'Stock Part NOT Saved!');
        // });
    };
}


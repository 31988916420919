import * as toolbox from '../components/common/toolbox';
import * as types from './action-types';
import firebase from 'firebase/compat/app';
import { SERVICE_ITEMS, UNITS } from 'components/constants';
import { doc, updateDoc, deleteField, getDoc, setDoc, onSnapshot } from "firebase/firestore";
import moment from 'moment';;

const firestore = firebase.firestore();
const _ = require('lodash');

/*-----------------------------------------------*/
/*  ASSETS
/*-----------------------------------------------*/

export async function exportAssets(handle, columns) {
    
	var stockParts = [];
	var snapshot = await firestore.collection(handle + '/assets/assets').where('deleted', '==', false).get();
	snapshot.forEach((doc) => {
		var entry = {};
		columns.forEach((column) => {
			entry[column] = doc.data()[column.replace('*','')] ?? '';
		});
		stockParts.push({ ...entry, 
			id: doc.id, 
		});
	});

	return stockParts;
}

export async function importAssetsPreview(handle, assets, callback) {

    var assetTypes = [];
    var snapshot = await firestore.collection(handle + "/settings/assetTypes").get();
    snapshot.forEach((doc) => {
        assetTypes.push({ ...doc.data(), id: doc.id });
    });

    var assetClassifications = [];
    var snapshot = await firestore.collection(handle + "/settings/assetClassifications").get();
    snapshot.forEach((doc) => {
        assetClassifications.push({ ...doc.data(), id: doc.id });
    });
    
    var customers = [];
    var snapshot = await firestore.collection(handle + '/profiles/profiles/').where('type', '==', 'customer').get();
    snapshot.forEach((doc) => {
        customers.push({ ...doc.data(), id: doc.id });
    });
    
    let errors = [];
    let newAssetTypes = [];

    for (var asset of assets) {
    
        // check for linked AssetTypes
        if (asset.assetTypeId) {
            let typeId = asset.assetTypeId.trim();
            let assetType = _.find(assetTypes, { id: typeId});
            if (!assetType && !parseInt(typeId)) {
                let assetByName = _.find(assetTypes, { value: typeId});
                if (!assetByName) {
                    if (!newAssetTypes.includes(typeId)) newAssetTypes.push(typeId);
                }
            } else {
                errors.push(`Asset Type Id "${typeId}" not found!`);
            }
        }

        // check for linked Categories   
        if (asset.classificationId) {
            let classificationId = asset.classificationId.trim();
            let classification = _.find(assetClassifications, { id: classificationId});
            if (!classification) {
                errors.push(`Classification Id "${classificationId}" not found!`);
            }
        }
        // check for linked Customers   
        if (asset.customerId) {
            let customerId = asset.customerId.trim();
            let customer = _.find(customers, { id: customerId});
            if (!customer) {
                errors.push(`Customer Id "${customerId}" not found!`);
            }
        }
    }

    if (typeof callback === 'function') callback({ 
        errors,
        numRecords: assets.length,
        createdItems: [
            ...(newAssetTypes.length) ? [{ name: 'Asset Types', records: newAssetTypes }] : [],
        ]
    });
};
export async function importAsset(handle, asset) {

    var batch = firestore.batch();

    var assetId = await nextAssetId(handle, batch);
    asset.deleted = false;
    asset.created = new Date();
    asset.modified = new Date();
    asset.purchaseDate = (asset.purchaseDate) ? moment(asset.purchaseDate).toDate() : null;

    // check for linked AssetTypes
    var assetIds = [];
    var assetTypes = [];
    if (asset.assetTypeId) {

        var snapshot = await firestore.collection(handle + "/settings/assetTypes").get();
        snapshot.forEach((doc) => {
            assetTypes.push({ ...doc.data(), id: doc.id });
        });

        let typeId = asset.assetTypeId.trim();
        let assetType = _.find(assetTypes, { id: typeId});
        if (assetType) {
            assetIds.push(typeId);
        } else if (!parseInt(typeId)) {
            let assetByName = _.find(assetTypes, { value: typeId});
            if (assetByName) {
                assetIds.push(assetByName.id);
            } else {
                let id =  await nextAssetTypeId(handle, batch);
                const assetTypeRef = firestore.collection(handle + "/settings/assetTypes").doc(id);
                batch.set(assetTypeRef, { value: typeId, deleted: false, position: assetTypes.length }, { merge: true });
                assetIds.push(id);
            }
        } else {
            window.toastr.success(`The AssetTypeId: ${typeId} could not be found`, 'Stock Part NOT Saved!');
            return null;
        }
    }
    asset.assetTypeIds = assetIds;
  
    const assetsRef = firestore.collection(handle + '/assets/assets');
    batch.set(assetsRef.doc(assetId), { ...asset }, { merge: true });

    await batch.commit().catch((error) => {
        toolbox.process_error(error, 'Stock Part NOT Saved!');
    });
};
export function subAssets(handle, customerId, customerAssets) {

    return async dispatch => {

        dispatch({ type: types.ASSETS + '_PENDING' });

        var query_ = (customerAssets)
            ?   (parseInt(customerId))
                ?   firestore.collection(handle + '/assets/assets').where('customerId', '==', customerId).where('deleted', '==', false)
                :   firestore.collection(handle + '/assets/assets').where('customerId', '==', '0').where('deleted', '==', false)
            :   firestore.collection(handle + '/assets/assets').where('deleted', '==', false);

        const unsubscribe = onSnapshot(query_, (querySnapshot) => {
            var assets = [];
            querySnapshot.forEach((doc) => { assets.push({ ...doc.data(), id: doc.id }); });
            dispatch({ type: types.ASSETS + '_FULFILLED', data: assets, unsubscribe });
        });
    };
}
export function subAsset(handle, id) {

	return dispatch => {

		dispatch({ type: types.ASSET + '_PENDING' });

		const unsubscribe = firestore.collection(handle + '/assets/assets').doc(id).onSnapshot(async (doc) => {
			const asset = { ...doc.data(), id: doc.id };

			dispatch({ type: types.ASSET + '_FULFILLED', data: asset, unsubscribe });
		});
	};
}
export function saveAsset(handle, assetForm, assetId, callback) {

    return async dispatch => {

        dispatch({ type: types.ASSET + '_SAVE_PENDING' });

        var batch = firestore.batch();

        if (!parseInt(assetId)) {
            assetId = await nextAssetId(handle, batch);
        }
        const assetsRef = firestore.collection(handle + '/assets/assets');
        batch.set(assetsRef.doc(assetId), { ...assetForm }, { merge: true });
        batch.commit().then(() => {
            if (assetForm.deleted) {
                window.toastr.success('The Asset record has been successfully archived', 'Asset Archived!');
            } else {
                window.toastr.success('The Asset record has been successfully saved/updated', 'Asset Saved!');
            }
            dispatch({ type: types.ASSET + '_SAVE_FULFILLED' });
            if (typeof callback === 'function') callback(assetId);
        }).catch((error) => {
            toolbox.process_error(error, 'Asset NOT Saved!');
        });
    };
}
export function saveStockPartToAsset(handle, assetId, stockPartId, remove, callback) {

    return dispatch => {

        dispatch({ type: types.ASSET + '_SAVE_PENDING' });

        var batch = firestore.batch();

        const assetsRef = firestore.collection(handle + '/assets/assets').doc(assetId);
        batch.set(assetsRef, { parts: (remove) ? firebase.firestore.FieldValue.arrayRemove(stockPartId) : firebase.firestore.FieldValue.arrayUnion(stockPartId) }, { merge: true });

        const stockPartRef = firestore.collection(handle + '/stock-parts/stock-parts').doc(stockPartId);
        batch.set(stockPartRef, { associatedAssets: (remove) ? firebase.firestore.FieldValue.arrayRemove(assetId) : firebase.firestore.FieldValue.arrayUnion(assetId) }, { merge: true });
        batch.commit().then(() => {
            if (!remove) window.toastr.success(`The Stock Part has been successfully linked`);
            if (remove) window.toastr.warning(`The Stock Part has been successfully unlinked`);
            dispatch({ type: types.ASSET + '_SAVE_FULFILLED' });
            if (typeof callback === 'function') callback(assetId);
        }).catch((error) => {
            toolbox.process_error(error, 'Stock Part NOT linked!');
        });
    };
}

export function selectAssetType(handle, typeId) {
    if (parseInt(typeId)) localStorage.setItem(`${handle}-selectAssetType`, typeId)
    return dispatch => {
        dispatch({ type: types.ASSET_TYPE + '_SELECTED', data: typeId });
    };
}
export function selectCustomer(handle, id) {
    if (parseInt(id)) localStorage.setItem(`${handle}-selectCustomer`, id)
    return dispatch => {
        dispatch({ type: types.CUSTOMER + '_SELECTED', data: id });
    };
}
export function showCustomerAssets(value) {
    return dispatch => {
        dispatch({ type: types.CUSTOMER + '_ASSETS_SELECTED', data: value });
    };
}
export function saveAssetStockParts(handle, parts, id) {
    return async dispatch => {

        dispatch({ type: types.ASSET + '_SAVE_PENDING' });
        var query = firestore.collection(handle + '/assets/assets').doc(id);
        await updateDoc(query, {
            ['parts']: [...parts]
        }).then(() => {
            window.toastr.success('The record has been successfully saved/updated', 'Stock Part Saved!');
            dispatch({ type: types.ASSET + '_SAVE_FULFILLED' });
        }).catch((error) => {
            toolbox.process_error(error, 'Asset NOT Saved!');
        });
    };
}
async function nextAssetTypeId(handle, batch) {
	const table = 'settings';
	const field = 'nextAssetTypeId';
	const startingId = 1000;

	return toolbox.nextId(handle, batch, table, field, startingId);
}
async function nextAssetId(handle, batch) {
    const table = 'assets';
    const field = 'nextAssetId';
    const startingId = 1000;

    return toolbox.nextId(handle, batch, table, field, startingId);
}
async function nextAssetClassificationId(handle, batch) {
	const table = 'settings';
	const field = 'nextAssetClassificationId';
	const startingId = 2000;

	return toolbox.nextId(handle, batch, table, field, startingId);
}

export function transform(handle) {

    return async dispatch => {

        // const batch = firestore.batch();
        // const assetsRef = firestore.collection(handle + '/assets/assets');
        
        // var assets = [];
        // const snapshot = await assetsRef.get();
        // snapshot.forEach((doc) => {
        //     assets.push({ ...doc.data(), id: doc.id });
        // });

        // for (let asset of assets) {
        //     let update = {
        //         customerId: '1060',
        //     }
        //     console.log(asset.id, update);
            
        //     batch.update(assetsRef.doc(asset.id), { ...update });
        // }
        
        // batch.commit().then(() => {
        //     window.toastr.success('The Asset record has been successfully saved/updated', 'Asset Saved!');
        // }).catch((error) => {
        //     toolbox.process_error(error, 'Asset NOT Saved!');
        // });
    };
}

import * as types from 'actions/action-types';

var initialState = {

    asset_analytics: { topAssetArray: [] },

};

const analyticsReducer = (state = initialState, action) => {

    switch (action.type) {
        case types.ASSET_ANALYTICS + '_CLEAR': state = { ...state, asset_analytics: { topAssetArray: [] }}; break;
		case types.ASSET_ANALYTICS + '_PENDING': pending('asset_analytics'); break;
		case types.ASSET_ANALYTICS + '_FULFILLED': fulfilled('asset_analytics'); break;
        
		// case types.ACTIVITY_LOG_USER + '_CLEAR': state = { ...state, activity_log_user: [] }; break;
		// case types.ACTIVITY_LOG_USER + '_PENDING': pending('activity_log_user'); break;
		// case types.ACTIVITY_LOG_USER + '_FULFILLED': fulfilled('activity_log_user'); break;
        
        // case types.ATTACHMENTS_SPINNER: set('attachments_spinner'); break;
        
        // case types.BOTTOM_SHEET_SHOW: set('bottom_sheet_show'); break;
        
        // case types.DASHBOARD + '_SELECTED': selected('dashboard'); break;
        // case types.DASHBOARD_COUNTS + '_FULFILLED': fulfilled('dashboard_counts'); break;
        
        // case types.ERROR_LOG + '_SAVE_PENDING': save_pending('error_log'); break;
        // case types.ERROR_LOG + '_SAVE_FULFILLED': save_fulfilled('error_log'); break;
        
        // case types.FLUTTER_KEYBOARD: set('flutter_keyboard'); break;
        // case types.FLUTTER_PLATFORM: set('flutter_platform'); break;
        
        // case types.HELP + '_SHOW': set('help_show'); break;
        // case types.HELP + '_URL': set('help_url'); break;
        
        // case types.LIGHTBOX_INDEX: set('lightbox_index'); break;
        // case types.LIGHTBOX_SHOW: set('lightbox_show'); break;
        // case types.LIGHTBOX_SOURCES: set('lightbox_sources'); break;
        
        // case types.SERVICE_ITEM_SEARCH: set('service_item_search'); break;

		// case types.APP_CHANGED: state = { ...state, service_item_search: null }; break;
        
        default: break;
    }

    return state;
    
    function set(table) {
        state = {
            ...state,
			[table]: action.data,
		};
	}
    function pending(table) {
        state = {
            ...state,
            [table + '_pending']: true,
        };
    }
    function save_pending(table) {
        state = {
            ...state,
            [table + '_save_pending']: true,
        };
    }
    function save_fulfilled(table) {
        state = {
            ...state,
            [table + '_save_pending']: false,
        };
    }
    function fulfilled(table) {
        state = {
            ...state,
            [table]: action.data,
            [table + '_pending']: false,
            [table + '_unsubscribe']: (action.unsubscribe) ? action.unsubscribe : state[table + '_unsubscribe'],
        };
    }
    function selected(table) {
        state = {
            ...state,
            [table]: action.data,
        };
    }
};
export default analyticsReducer;



import React from 'react';
import ResultsWorkOrders from '../components/results-work-orders';
import { APPS, COUNTS } from 'components/constants';
import { Expanding } from 'components/common/expanding';
import { Widget } from 'components/dashboard/components/widget';
import { WidgetTask } from 'components/dashboard/components/widget-task';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { AreaChart, CartesianGrid, XAxis, YAxis, Tooltip, Area, ResponsiveContainer } from 'recharts';

export default function WorkOrdersTasks(props) {

	const params = useParams();

    const counts = useSelector((store) => store.admin.dashboard_counts);
    const MyWorkOrders = useSelector((store) => store.workOrders.work_orders_technician);

	const data = [
		{
		  name: 'Page A',
		  uv: 4000,
		  pv: 2400,
		  amt: 2400,
		},
		{
		  name: 'Page B',
		  uv: 3000,
		  pv: 1398,
		  amt: 2210,
		},
		{
		  name: 'Page C',
		  uv: 2000,
		  pv: 9800,
		  amt: 2290,
		},
		{
		  name: 'Page D',
		  uv: 2780,
		  pv: 3908,
		  amt: 2000,
		},
		{
		  name: 'Page E',
		  uv: 1890,
		  pv: 4800,
		  amt: 2181,
		},
		{
		  name: 'Page F',
		  uv: 2390,
		  pv: 3800,
		  amt: 2500,
		},
		{
		  name: 'Page G',
		  uv: 3490,
		  pv: 4300,
		  amt: 2100,
		},
	  ];

    return (
		<div className="row mb-2 no-gutters" >

			{/* TASKS ------------------------------------------------------------------------------------- */}

			<div className="col-3 p-1">
				<WidgetTask 
					active={ params.widget_id == 'unassigned-wo' }
					badge={ (params.appId == APPS.ASSETS.id) ? counts?.[COUNTS.ASSETS_WORKORDERS_UNASSIGNED.id] ?? 0 : counts?.[COUNTS.SERVICE_WORKORDERS_UNASSIGNED.id] ?? 0 }
					icon={ <i className="fa-duotone fa-solid fa-file-xmark fa-3x"></i> }
					text={ 'Unassigned Work Orders' }
					value="unassigned-wo"
					onClick={ props.handleClick }
				/>
			</div>
			<div className="col-3 p-1">
				<WidgetTask 
					active={ params.widget_id == 'inprogress-wo' }
					badge={ (params.appId == APPS.ASSETS.id) ? counts?.[COUNTS.ASSETS_WORKORDERS_INPROGRESS.id] ?? 0 : counts?.[COUNTS.SERVICE_WORKORDERS_INPROGRESS.id] ?? 0 }
					icon={ <i className="fa-duotone fa-solid fa-rotate fa-3x"></i> }
					text={ 'Work Orders in Progress' }
					value="inprogress-wo"
					onClick={ props.handleClick }
				/>
			</div>
			<div className="col-3 p-1">
				<WidgetTask 
					active={ params.widget_id == 'incomplete-wo' }
					badge={ (params.appId == APPS.ASSETS.id) ? counts?.[COUNTS.ASSETS_WORKORDERS_INCOMPLETE.id] ?? 0 : counts?.[COUNTS.SERVICE_WORKORDERS_INCOMPLETE.id] ?? 0 }
					icon={ <i className="fa-duotone fa-solid fa-file-exclamation fa-3x"></i> }
					text={ 'Incomplete Work Orders' }
					value="incomplete-wo"
					onClick={ props.handleClick }
				/>
			</div>

			{/* RESULTS TABLES ---------------------------------------------------------------------------- */}

			<Expanding open={ params.widget_id == 'unassigned-wo' }>
				<ResultsWorkOrders />
			</Expanding>
			<Expanding open={ params.widget_id == 'inprogress-wo' }>
				<ResultsWorkOrders />
			</Expanding>
			<Expanding open={ params.widget_id == 'incomplete-wo' }>
				<ResultsWorkOrders />
			</Expanding>

			<Expanding open={ params.widget_id == 'analytics' }>
				<div className="col pl-1">
					<div className="widget lazur-bg no-padding" style={{height: '300px'}}>
						<div className="p-m">
							<h1 className="m-xs">$ 210,660</h1>

							<h3 className="font-bold no-margins">
								Monthly income
							</h3>
							<small>Income form project Beta.</small>
						</div>
						<ResponsiveContainer width="100%" height="50%">
							<AreaChart
							width={500}
							height={400}
							data={data}
							margin={{
								top: 10,
								right: 30,
								left: 0,
								bottom: 0,
							}}
							>
							<CartesianGrid strokeDasharray="3 3" />
							<XAxis dataKey="name" />
							<YAxis />
							<Tooltip />
							<Area type="monotone" dataKey="uv" stackId="1" stroke="#8884d8" fill="#8884d8" />
							<Area type="monotone" dataKey="pv" stackId="1" stroke="#82ca9d" fill="#82ca9d" />
							<Area type="monotone" dataKey="amt" stackId="1" stroke="#ffc658" fill="#ffc658" />
							</AreaChart>
						</ResponsiveContainer>
					</div>
				</div>
			</Expanding>
		</div>
	);
};

import * as types from './action-types';
import firebase from 'firebase/compat/app';

const firestore = firebase.firestore();

/*-----------------------------------------------*/
/*  SETTINGS
/*-----------------------------------------------*/

export function subQuickbooksSettings(handle) {

    return async dispatch => {

        dispatch({ type: types.QUICKBOOKS + '_PENDING' });
        var unsubscribe = firestore.collection(handle + '/settings/quickbooks').doc('quickbooks').onSnapshot(async (doc) => {
            var quickbooks = doc.data();
            dispatch({ type: types.QUICKBOOKS + '_FULFILLED', data: quickbooks, unsubscribe });
        });
    };
}
export function updateQuickbooksSettings(handle, update) {

    return async dispatch => {

        dispatch({ type: types.QUICKBOOKS + '_SAVE_PENDING' });
        firestore.collection(handle + '/settings/quickbooks').doc('quickbooks').update(update).then(() => {
            dispatch({ type: types.QUICKBOOKS + '_SAVE_FULFILLED' });
        });
    };
}
// Sync data TO QuickBooks
export function updateQuickbooks(options, quickbooksSettings) {

    return async dispatch => {

        if (quickbooksSettings?.synchronizeContinuously && quickbooksSettings?.tokens) {

            dispatch({ type: types.QUICKBOOKS_SYNC + '_SAVE_PENDING' });
            
            setTimeout(async () => {

                var errors = [];
                try {
                     errors = await firebase.functions().httpsCallable('updateQuickbooks')(options);
                } catch (e) {
                    if (e) window.toastr.error(e.message, 'Error Syncing to QuickBooks');
                    dispatch({ type: types.QUICKBOOKS_SYNC + '_SAVE_FULFILLED' });
                }
    
                if (errors?.data?.length) window.toastr.error("QuickBooks Update Errors", "QuickBooks Syncing Errors");
                if (!errors?.data?.length) window.toastr.success("QuickBooks Successfully Updated", "QuickBooks Synced");
    
                dispatch({ type: types.QUICKBOOKS_SYNC + '_SAVE_FULFILLED' });

            }, 3000);
        }
    };
}
export function checkForImports(options, quickbooksSettings, callback) {
    return async dispatch => {

        if (quickbooksSettings?.useEmulator) firebase.functions().useEmulator("localhost", 5001); // Testing locally with emulator
        const result = await firebase.functions().httpsCallable('checkImports')(options);

        if (typeof callback == 'function') callback(result);
    };
}
export function disconnectFromQuickbooks(handle, quickbooksSettings, realmId, callback) {
    return async dispatch => {

        if (quickbooksSettings?.useEmulator) firebase.functions().useEmulator("localhost", 5001); // Testing locally with emulator
        await firebase.functions().httpsCallable('revokeTokens')({ handle, realmId });

        if (typeof callback == 'function') callback();
    };
}



import { combineReducers } from 'redux';
import { reducer as toastrReducer } from 'react-redux-toastr';

// Reducers
import AdminReducer from 'reducers/admin_reducer';
import AnalyticsReducer from 'reducers/analytics_reducer';
import ArchiveReducer from './archive-reducer';
import AssetReducer from 'reducers/assets-reducer';
import ChatReducer from 'reducers/chat-reducer';
import CompanyReducer from './company-reducer';
import EmployeesReducer from 'reducers/employees-reducer';
import InventoryReducer from 'reducers/inventory-reducer';
import InvoicesReducer from './invoices-reducer';
import ProfilesReducer from './profiles-reducer'
import QuickbooksReducer from 'reducers/quickbooks-reducer';
import QuotesReducer from './quotes-reducer';
import ServicesRequestsReducer from 'reducers/service-requests-reducer';
import SettingsReducer from './settings-reducer';
import UsersReducer from './users-reducer';
import WorkOrdersReducer from 'reducers/work-orders-reducer';

// Combine Reducers
var reducers = combineReducers({
	admin: AdminReducer,
	analytics: AnalyticsReducer,
	archive: ArchiveReducer,
	assets: AssetReducer,
	chat: ChatReducer,
	company: CompanyReducer,
	employees: EmployeesReducer,
	inventory: InventoryReducer,
	invoices: InvoicesReducer,
	profiles: ProfilesReducer,
	quickbooks: QuickbooksReducer,
	quotes: QuotesReducer,
	serviceRequests: ServicesRequestsReducer,
	settings: SettingsReducer,
	toastr: toastrReducer,
	users: UsersReducer,
	workOrders: WorkOrdersReducer,
});

export default reducers;
import * as actions_work_orders from 'actions/work-orders-actions';
import * as toolbox from 'components/common/toolbox';
import React, { useEffect, useState } from 'react';
import { Table } from 'em-table';
import { ACTIVITY_LOG } from 'components/constants';
import { FormBuilder, ValidateForm, ModalForm, } from 'enspire-manager-framework';
import { useDispatch, useSelector } from 'react-redux';
import { split_work_order_fields, split_work_order_form_layout } from './split-work-order-form-layout';
import { ROLES } from 'components/constants';
import { useHistory, useParams } from 'react-router-dom';

const moment = require('moment');
var _ = require('lodash');

export default function SplitWorkOrderForm(props) {

	/* Hooks --------------------------*/
	const params = useParams();
	const history = useHistory();
	const dispatch = useDispatch();

	const departments = useSelector((store) => store.settings.departments);
	const employees = useSelector((store) => store.employees.employees);
	const service_request = useSelector((store) => store.serviceRequests.service_request);
	const settings = useSelector((store) => store.settings.settings);
	const user = useSelector((store) => store.users.user);
	const workOrders = useSelector((store) => store.workOrders);
	const work_order_items = useSelector((store) => store.workOrders.work_order_items);
	
	const [checked, setChecked] = useState([]);
	const [lineItems, setLineItems] = useState([]);
	const [formError, setFormError] = useState(null);
	const [workOrder, setWorkOrder] = useState(split_work_order_fields(settings?.work_orders?.default_duration ?? 2, service_request?.offsite ?? false));
	
	useEffect(() => {
		if (workOrder.endDate < workOrder.startDate) setWorkOrder(prev => ({ ...prev, startDate: moment(prev.endDate).subtract(2, 'hours').toDate() }));
	}, [workOrder?.endDate]);

	useEffect(() => {
		if (workOrder.startDate > workOrder.endDate) setWorkOrder(prev => ({ ...prev, endDate: moment(prev.startDate).add(2, 'hours').toDate() }));
	}, [workOrder?.startDate]);

	/* Handlers --------------------------*/

	const handleDate = (field, date) => {
		var form_error = _.filter(formError, (o) => { return o.field !== field; });
		setFormError(form_error);
		setWorkOrder(prev => ({ ...prev, [field]: date }));
	};

	const handleChange = (event) => {
		let form_error = _.filter(formError, (o) => { return o.field !== event.target.name; });
		setFormError(form_error);
		setWorkOrder(prev => ({ ...prev, [event.target.name]: event.target.value }));
	};

	const handleSelect = (item, e) => {
		if (checked.includes(item.index)) {
			setChecked(_.filter(checked, (o) => o != item.index));
			setLineItems(_.filter(lineItems, (o) => o.index != item.index));
		} else {
			setChecked([ ...checked, item.index ]);
			setLineItems([ ...lineItems, item ]);
		}
	};
	
	/* Actions --------------------------*/
	
	const submitForm = (work_order_layout) => {
		var form_error = ValidateForm(workOrder, work_order_layout);
		setFormError(form_error);

		if (!form_error.length) {
			dispatch(actions_work_orders.saveNewWorkOrder(
				params.handle,
				params.request_id,
				workOrder,
				user,
				(workOrderId, customId) => {
					let newLineItems = _.filter(data, (o) => !_.find(lineItems, { index: o.index }));
					dispatch(actions_work_orders.updateWorkOrderServiceItems(params.handle, params.work_order_id, newLineItems));
					history.go(-2);
					setTimeout(() => {
						let location = toolbox.modifyPath(props.location.pathname, 5, `form/0/service_requests/${params.request_id}/work_orders/${workOrderId}/items`);
						history.replace({ pathname: location });
					}, 250);
					// dispatch(actions_admin.saveActivity(params.handle, `Split Work Order #${workOrders.work_order.customId} to create Work Order #${customId}`, workOrderId, ACTIVITY_LOG.WORK_ORDER_SPLIT?.id, users.user?.id));
				},
				{ lineItems },
			));
		}
	};

	const data = work_order_items.map((item, index) => ({ ...item,
		checked: checked.includes(index),
		index,
	}));
	const technicians = _.filter(employees, (o) => { return o.roles?.includes(ROLES.TECHNICIAN.id) || o.roles?.includes(ROLES.LEAD.id) });
	const work_order_layout = split_work_order_form_layout({
		appId: params.appId,
		departments, 
		technicians,
		work_order: workOrder, 
	});

	return (

		<ModalForm {...props}
			history={props.history}
			modal_header={'Work Order'}
			cancel_button_title="Cancel"
			save_button_title={'Split Work Order'}
			submitFormHandler={() => submitForm(work_order_layout)}
			show_spinner={workOrders?.work_order_save_pending}
		>
			<FormBuilder
				callbacks={{
					text: handleChange,
					select: handleChange,
					date: handleDate,
				}}
				form_error={formError}
				form={work_order_layout}
				record={workOrder}
			/>
			<>
				<h2>Move the selected Line Items to the New Work Order:</h2>
				<Table
					data={data}
					show_limit={false}
					columns={[
						{ name: 'Item ID', field: 'serviceItemId', width: 10 },
						{ name: 'Name', field: 'name', line2: 'description', width: 55 },
						{ name: 'Count', field: 'count', width: 10 },
						{ name: 'Price', field: 'calculatedPrice', type: 'number', format: 'usd', width: 15 },
						{ name: '', field: 'checked', type: 'checkbox', callback: handleSelect, width: 10},
					]}
				/>
			</>
		</ModalForm>
	);
};

import { APPS } from 'components/constants';
import React, { Fragment } from 'react';
import _ from 'lodash';
import { roundDateTime } from 'components/common/toolbox';

const moment = require('moment');

export function split_work_order_fields(default_duration, offsite) {
	return (
		{
			startDate: roundDateTime(moment(), moment.duration(15, "minutes")).toDate(),
			startTime: roundDateTime(moment(), moment.duration(15, "minutes")).toDate(),
			endDate: roundDateTime(moment(), moment.duration(15, "minutes")).add(default_duration, 'hours').toDate(),
			duration: (parseFloat(default_duration ?? '2') * 60).toString(),
			technicianId: '',
			description: '',
			offsite: offsite,
		}
	);
}

export function split_work_order_form_layout({
	appId,
	departments, 
	technicians, 
	work_order, 
}) {

	const department_options = departments.map((department) => {
		return <option key={ department.id } value={ department.id }>{ department.value }</option>
	}); 
	const technician_options = _.orderBy(technicians, ['contact.lastName'], ['asc']).map((tech, index) => {
		return <option key={index} value={tech.id}>{tech.contact?.firstName + ' ' + tech.contact?.lastName}</option>
	});

	return (
		[{
			column_class: 'col-md-12',
			body: [{
				section:
					<Fragment>
						<h3 className="m-t-none m-b">Work Order Information</h3>
					</Fragment>,
				layout: [
					{
						grid: 'col-12',
						label: 'Technician',
						field: 'technicianId',
						type: 'select',
						options:
							<Fragment>
								<option key={'none'} value={null}>- Select -</option>
								{technician_options}
							</Fragment>,
					}, {
						grid: 'col-4',
						label: 'Start Date',
						field: 'startDate',
						type: 'date',
						dateFormat: 'MM-DD-YYYY',
						valid: ['required'],
					}, {
						grid: 'col-4',
						label: 'Start Time',
						field: 'startTime',
						type: 'time',
						dateFormat: 'hh:mm A',
						valid: ['required'],
					}, {
						grid: 'col-4',
						label: 'Duration',
						field: 'duration',
						valid: ['required'],
						type: 'select',
						options:
							<Fragment>
								<option key={'30'} value={'30'}>00:30 hrs</option>
								<option key={'60'} value={'60'}>01:00 hrs</option>
								<option key={'90'} value={'90'}>01:30 hrs</option>
								<option key={'120'} value={'120'}>02:00 hrs</option>
								<option key={'150'} value={'150'}>02:30 hrs</option>
								<option key={'180'} value={'180'}>03:00 hrs</option>
								<option key={'210'} value={'210'}>03:30 hrs</option>
								<option key={'240'} value={'240'}>04:00 hrs</option>
								<option key={'270'} value={'270'}>04:30 hrs</option>
								<option key={'300'} value={'300'}>05:00 hrs</option>
								<option key={'330'} value={'330'}>05:30 hrs</option>
								<option key={'360'} value={'360'}>06:00 hrs</option>
								<option key={'390'} value={'390'}>06:30 hrs</option>
								<option key={'420'} value={'420'}>07:00 hrs</option>
								<option key={'450'} value={'450'}>07:30 hrs</option>
								<option key={'480'} value={'480'}>08:00 hrs</option>
							</Fragment>,
					}, {
						grid: 'col-12',
						label: 'Department',
						field: 'departmentId',
						type: 'select',
						options:
							<Fragment>
								<option value="">Choose...</option>
								{department_options}
							</Fragment>,
					}, {
						grid: 'col-sm-12 mt-2',
						label: 'Asset is Offsite',
						field: 'offsite',
						type: (appId == APPS.SERVICE.id) ? 'skip' : 'checkbox',
					}, {
						grid: 'col-12',
						label: 'Description',
						field: 'description',
						type: 'textarea',
						rows: 6,
						valid: ['required'],
					}],
			},]
		}]
	);
}
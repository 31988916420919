import * as actions_users from 'actions/users-actions';
import * as actions_work_orders from 'actions/work-orders-actions';
import * as toolbox from 'components/common/toolbox';
import ExpandDetails from 'components/common/expand-details';
import React, { useEffect, useState } from 'react';
import ResultsActionIcons from './results-actions/results-action-icons';
import _ from 'lodash';
import moment from 'moment';
import { APPS, WORKORDERS } from 'components/constants';
import { Avatar } from 'enspire-manager-framework';
import { DashboardDetail } from 'enspire-manager-framework';
import { Table } from 'em-table';
import { imageSize, navMultipleUrls, numPanels } from 'components/common/toolbox';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

const ResultsWorkOrders = (props) => {
    
    /* Hooks --------------------------*/
    
    const params = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
	const routeLocation = useLocation();

    const employees = useSelector((store) => store.employees.employees);
    const user = useSelector((store) => store.users.user);
    const workOrders = useSelector((store) => store.workOrders);

    const [data, setData] = useState([]);

    const orderedWorkOrders = _.orderBy(workOrders.work_orders_dashboard, ['_name'], ['asc']);

    /* UseEffect --------------------------*/

    useEffect(() => {
        if (params.widget_id == 'dashboard') {
            dispatch(actions_work_orders.clearDashboardWorkOrders());
        } else {
            dispatch(actions_work_orders.subDashboardWorkOrders(params.handle, params.appId, params.widget_id));
        }
        
        return () => {
			const unsubscribe = workOrders.work_orders_dashboard_unsubscribe;
			if (typeof unsubscribe == 'function') unsubscribe();
		};
    }, [params.handle, params.appId, params.widget_id]);

    useEffect(() => {
        var work_orders = [];
        var currentEntityId = null;
        orderedWorkOrders?.forEach((workOrder) => {
            
            const entityId = (params.appId == APPS.SERVICE.id) ? workOrder.customerId : workOrder.assetId;
            const status = _.find(Object.values(WORKORDERS), { id: workOrder.statusId });
            const owner = _.find(employees, (o) => o.contact?.email == workOrder?.ownerId);

            // Add grouip heading for customer or asset
            if (entityId != currentEntityId) {
                currentEntityId = entityId;
                work_orders.push({ ...workOrder,
                    status: status?.name,
                    _jsx:
                    <div className="d-flex align-items-center justify-content-between p-2" style={{ backgroundColor: '#cccccc' }}>
                        <div role="button" onClick={() => openEntity(workOrder)}>
                            <Avatar className=""
                                color="white"
                                bgColor="saddleBrown"
                                size={45}
                                fontSize={'20px'}
                                name={(params.appId == APPS.SERVICE.id) ? workOrder._displayName : workOrder._name}
                                image={imageSize(workOrder._imageUrl, 'sm')}
                                imageWidth={60}
                                border="4px solid white"
                                role={ (workOrder._imageUrl) ? "button" : null }
                                style={{ flexShrink: 0 }}
                            />
                        </div>
                        <div className="flex-grow-1">
                            <h2 className="ml-3 m-0 text-black font-weight-normal">{
                                ((params.appId == APPS.SERVICE.id) ? workOrder._displayName : workOrder._name) + 
                                ((workOrder._unitNumber) ? ' #' + workOrder._unitNumber : '')
                            }</h2>
                            { workOrder._address &&
                                <p className="ml-3 m-0">{ workOrder._address }</p>
                            }
                        </div>
                        <ResultsActionIcons entity={ 'wo' } entityId={ workOrder.id } />
                    </div> 
                });
            }

            work_orders.push({ ...workOrder,
                status: status?.name,
                _stripe_color: status?.color,
                details: <>
                    <h4>{`WO #${workOrder.customId}`}</h4>
                    <ExpandDetails className='mt-1' default={ 'closed' } top={ -35 } eTop={ 30 } eRight={ 5 } noMore>
                        <div id="work-order-details" className="mt-2 mb-2">
                            <DashboardDetail field={ 'Start Date' } value={ moment(workOrder.startDate?.seconds, 'X').format('MMM DD, YYYY h:mm A') } />
                            <DashboardDetail field={ 'Description' } value={ workOrder.description } type="text" />
                            <DashboardDetail field={ 'Suggested' } value={ workOrder.suggested } type="text" />
                            <DashboardDetail field={ 'Created By' } value={ (owner) ? `${owner?.contact.firstName} ${owner?.contact.lastName}` : '' } type="text" />
                            <div className="clearfix"></div>
                        </div>
                    </ExpandDetails>
                    { workOrder.reason &&
                        <div className="alert alert-warning">
                            <strong>REASON:</strong>&nbsp; { workOrder.reason }
                        </div>
                    }
                </>
            });
        });
        setData(work_orders);
    }, [workOrders.work_orders_dashboard]);

    /* Actions ----------------------------*/

    const openWorkOrder = async (workOrder) => {
        let entityId = (params.appId == APPS.SERVICE.id) ? workOrder.customerId : workOrder.assetId;
        navMultipleUrls({ actions_users, dispatch, history, numPanels, params, user, urlArray: [
            toolbox.modifyPath( routeLocation?.pathname, 4, `${entityId}/form/0/service_requests/${workOrder.serviceRequestId}/work_orders`, 9),
            toolbox.modifyPath( routeLocation?.pathname, 4, `${entityId}/form/0/service_requests/${workOrder.serviceRequestId}/work_orders/${workOrder.id}/items`, 11),
        ]});
    }
    const openEntity = (workOrder) => {
        const entity = (params.appId == APPS.SERVICE.id) ? 'customers' : 'assets';
        const entityId = (params.appId == APPS.SERVICE.id) ? workOrder.customerId : workOrder.assetId;
        const tab = (params.appId == APPS.SERVICE.id) ? 'tab' : 'service_requests';

        navMultipleUrls({ actions_users, dispatch, history, numPanels, params, user, urlArray: [
            toolbox.modifyPath( routeLocation?.pathname, 3, `${entity}`, 3),
            toolbox.modifyPath( routeLocation?.pathname, 3, `${entity}/${entityId}/form/0/${tab}`, 7),
        ]});    
    }

    /* Constants --------------------------*/

    const columns = [
        { name: 'Details', field: 'details', type: 'jsx', width: 100 },
        { name: 'Summary', field: 'description' },
    ];

    return (

        <Table
            data={data}
            columns={columns}
            header_style={{border: 'none'}}
            active_field={'id'}
            active_id={params.tab2_id}
            second_line={'description'}
            group_by={{ fields: ['statusId'], direction: ['desc'], display: ['status'] }}
            hide_header={true}
            click_callback={ openWorkOrder }
            chevron={ true }
        >
        </Table>
    );
};

export default ResultsWorkOrders;
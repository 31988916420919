import * as toolbox from 'components/common/toolbox';
import * as types from './action-types';
import firebase from 'firebase/compat/app';
import { toastr } from 'react-redux-toastr';

const firestore = firebase.firestore();
const _ = require('lodash');
const axios = require('axios');

/*-----------------------------------------------*/
/*  ATTACHMENTS
/*-----------------------------------------------*/

export function subAttachments(handle, id, source, prefix, { field } = {}) {

	return dispatch => {

		dispatch({ type: prefix + '_' + field.toUpperCase() + '_CLEAR' });
		dispatch({ type: prefix + '_' + field.toUpperCase() + '_PENDING' });

		var unsubscribe = firestore.collection(`${handle}/${source}/${source}/${id}/attachments/`).doc(id).onSnapshot((doc) => {

			if (doc.exists) {
				var attachments = doc.data()[field] ?? [];
				dispatch({ type: prefix + '_' + field.toUpperCase() + '_FULFILLED', data: attachments, unsubscribe });
			} else {
				dispatch({ type: prefix + '_' + field.toUpperCase() + '_FULFILLED', data: [], unsubscribe });
			}
		});
	};
}
export function saveAttachment(handle, id, attachments, source, prefix, field, callback) {

	return async dispatch => {

		dispatch({ type: prefix + '_' + field.toUpperCase() + "_SAVE_PENDING" });

		const batch = firestore.batch();

		batch.set(firestore.collection(`${handle}/${source}/${source}/${id}/attachments`).doc(id), { [field]: attachments }, { merge: true });
		// Update serviceItems so it will reload with updated external invoices
		if (field == 'external_invoices') {
			batch.set(firestore.collection(`${handle}/${source}/${source}/${id}/serviceItems`).doc(id), { updated: new Date() }, { merge: true });
		}
		batch.commit().then(() => {
			window.toastr.success('The Attachment has been successfully saved/updated');
			dispatch({ type: prefix + '_' + field.toUpperCase() + '_SAVE_FULFILLED' });
			if (typeof callback === 'function') callback(id);
		}).catch((error) => {
			toolbox.process_error(error, 'Attachment NOT Saved');
		});
	};
}
export function attachmentSpinner(value) {

	return async dispatch => {
		dispatch({ type: types.ATTACHMENTS_SPINNER, data: value });
	};
}

/*-----------------------------------------------*/
/*  FUNCTIONS
/*-----------------------------------------------*/

export function prepUploadedImages(upload, record) {

	var results = { ...record };

	if (Array.isArray(upload)) { // Upload new image
		for (let result of upload) {
			let [field, filename] = result;

			if (!results.imageUrl) {
				results.imageUrl = filename;
			} else {
				for (let index = 0; index < 6; index++) {
					if (!results['imageUrlAlt' + index]) {
						results['imageUrlAlt' + index] = filename;
						break;
					}
				}
			}
		}
	} else if (upload == 'imageUrl') { // delete main image
		if (results['imageUrlAlt0']) {
			results.imageUrl = results['imageUrlAlt0'];
			for (let index = 0; index < 6; index++) {
				results['imageUrlAlt' + index] = results['imageUrlAlt' + (index + 1)] ?? null;
			}
		} else {
			results.imageUrl = null;
		}

	} else { // Delete alt image
		let elementIndex = parseInt(upload.split('imageUrlAlt')[1]);
		for (let index = elementIndex; index < 6; index++) {
			results['imageUrlAlt' + index] = results['imageUrlAlt' + (index + 1)] ?? null;
		}
	}

	return results;
}
import * as actions_admin from 'actions/admin-actions';
import * as actions_profiles from 'actions/profiles-actions';
import * as actions_service_requests from 'actions/service-requests-actions';
import * as actions_users from 'actions/users-actions';
import * as actions_work_orders from 'actions/work-orders-actions';
import * as toolbox from 'components/common/toolbox';
import Authorize from 'components/common/authorize';
import ExpandDetails from 'components/common/expand-details';
import React, { useEffect, useState } from 'react';
import SpeedDialButton from 'components/common/speed-dial-button'; 
import StatusSelect from 'components/common/status-select';
import TooltipOverlay from 'components/common/tooltip-overlay';
import _ from 'lodash';
import { APPS, ADDRESSES, INVOICES, PERMISSIONS, REQUESTS, SERVICE_REQUEST_TERMS, WORKORDERS } from 'components/constants';
import { Avatar, DashboardDetail, Spinner } from 'enspire-manager-framework';
import { confirmChangeStatus } from 'components/common/toolbox';
import { imageSize } from 'components/common/toolbox';
import { navMultipleUrls } from 'components/common/toolbox';
import { numPanels } from 'components/common/toolbox';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';

const ServiceRequestDashboard = (props) => {
    
    /* Hooks ------------------------------------------------------------------------------------------------------------------------------------*/
    
    const seg = 5;
    const params = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
    const routeLocation = useLocation();
    
    const admin = useSelector((store) => store.admin);
    const assets = useSelector((store) => store.assets.assets);
    const employees = useSelector((store) => store.employees.employees);
	const profiles = useSelector((state) => state.profiles);
    const quotes = useSelector((store) => store.quotes);
    const services = useSelector((store) => store.serviceRequests);
    const service_request = useSelector((store) => store.serviceRequests.service_request);
	const user = useSelector(store => store.users?.user);
	const user_permissions = useSelector(store => store.users?.user_permissions);
    const workOrders = useSelector((store) => store.workOrders);

    const [processing, setProcessing] = useState(false);
    const [state, setState] = useState({
        mapAddress: null,
        showGenerateInvoice: false,
    });
    
	const profile = profiles.profile;
    const pathname = routeLocation.pathname;
    const segments = pathname.split('/').length;
    const approved_quote = quotes.quote_approved;
    const asset = assets.find((asset) => asset.id == service_request?.assetId);
    const isRecord = (service_request?.type == 'asset_record');
    const serviceType = (isRecord) ? 'Record' : 'Request';
    const owner = _.find(employees, (o) => o.contact?.email == service_request?.ownerId);
        
    // Permissions ----------------------------------------

    const canInvoice = !!workOrders?.work_orders?.find((workOrder) => (workOrder.statusId == WORKORDERS.COMPLETE.id));
    const canReceivePayment = !!services?.service_request_invoices?.find((invoice) => (invoice.statusId != INVOICES.PAID.id && invoice.profileId));
    const canClose = user_permissions[PERMISSIONS.SERVICE_REQUESTS_CLOSE.key];
    const canViewMySR = (service_request?.techIds?.includes(user?.profileId) || service_request?.techIds?.includes(user?.email));
    const canEditSR = (
        user_permissions[PERMISSIONS.SERVICE_REQUESTS_EDIT.key] || 
        (canViewMySR && user_permissions[PERMISSIONS.SERVICE_REQUESTS_ASSIGNED_EDIT.key])
    );

    /* Effects ---------------------------------------------------------------------------------------------------------------------------*/

    useEffect(() => {
        dispatch(actions_service_requests.subServiceRequest(params.handle, params.request_id));
        dispatch(actions_work_orders.subWorkOrdersByRequestId(params.handle, params.request_id));

        return () => {
            var unsubscribe = services.service_request_unsubscribe;
            if (typeof unsubscribe === 'function') unsubscribe();

            var unsubscribe = workOrders.work_orders_unsubscribe;
            if (typeof unsubscribe === 'function') unsubscribe();

            dispatch(actions_service_requests.clearServiceRequest());
        };
    }, [params.request_id]);

    useEffect(() => {
        if (parseInt(params.customer_id) && props.source == "dashboard") dispatch(actions_profiles.subProfile(params.handle, params.customer_id, 'PROFILE'));
        return () => {
            if (parseInt(params.customer_id) && props.source == "dashboard") {
                let unsubscribe = profiles.profile_unsubscribe;
                if (typeof unsubscribe === "function") unsubscribe();
                dispatch(actions_profiles.clearProfile());
            }
        };
    }, [params.customer_id]);
    
    // Once customer is loaded, get the address for the map
    useEffect(() => {
        let address = _.find(profiles?.profile?.addresses, { addressTypeId: ADDRESSES.SERVICE });
        if (!address) address = profiles?.profile?.addresses?.[0];
        let mapAddress = (address) ? `${address.addressLine1} ${address.addressLine2} ${address.city} ${address.state} ${address.zip}` : null;
        if (mapAddress) setState(prev => ({ ...prev, mapAddress }));
    }, [profiles?.profile?.id]);
    
    // Subscribe by profileId for Customer Dashboard, requestId for Service Request
    // useEffect(() => {
    //     dispatch(actions_quotes.subQuotesByField(params.handle, 'serviceRequestId', params.request_id));

    //     return () => {
    //         dispatch(actions_quotes.clearQuotes());
    //         let unsubscribe = quotes.quotes_unsubscribe;
    //         if (typeof unsubscribe === 'function') unsubscribe();
    //     };
    // }, [params.request_id, params.customer_id]);

    // Sub to Accepted Quote
    // useEffect(() => {
    //     dispatch(actions_quotes.subAcceptedQuoteByServiceRequestId(params.handle, params.request_id));

    //     return () => {
    //         let unsubscribe = quotes.quote_approved_unsubscribe;
    //         if (typeof unsubscribe === 'function') unsubscribe();
    //     };
    // }, [params.request_id]);


    /* Actions/Handlers ------------------------------------------------------------------------------------------------------------------------------------*/

    const handleSpeedDial = (value, action) => {
        if (value == 'close') handleChangeStatus(REQUESTS.CLOSED.id);
        if (value == 'invoice') handleGenerateInvoice();
        if (value == 'payment') openReceivePayment();
    }
    const openForm = () => {
        let location = toolbox.modifyPath(props.location.pathname, seg, 'request-form/' + params.request_id);
        history.push({ pathname: location });
    };
    const handleGenerateInvoice = () => {
        let newLocation = toolbox.modifyPath(props.location.pathname, seg, 'invoice-form/0');
        history.push({ pathname: newLocation });
    };
    const openReceivePayment = () => {
        let newLocation = toolbox.modifyPath(props.location.pathname, seg, 'payment-form/0');
        history.push({ pathname: newLocation });
    };
    const openEntity = () => {
        const entity = (params.appId == APPS.SERVICE.id) ? 'customers' : 'assets';
        const entityId = (params.appId == APPS.SERVICE.id) ? service_request.customerId : service_request.assetId;
        const tab = (params.appId == APPS.SERVICE.id) ? 'tab' : 'service_requests';

        navMultipleUrls({ actions_users, dispatch, history, numPanels, params, user, urlArray: [
            toolbox.modifyPath( routeLocation?.pathname, 3, `${entity}`, 3),
            toolbox.modifyPath( routeLocation?.pathname, 3, `${entity}/${entityId}/form/0/${tab}`, 7),
        ]});    
    }
    const handleChangeStatus = (statusId) => {
        if (statusId == REQUESTS.CLOSED.id) {
            confirmChangeStatus('Service Request and associated Work Orders and Invoices', 'Closed', () => {
                dispatch(actions_service_requests.closeServiceRequest(params.handle, params.request_id));
            });
        }
    };

    // const handleChangeStatus = (statusId, reason) => {
    //     switch (statusId) {
    //         case SERVICE_REQUEST_TERMS.SERVICE_CALL.id: {

    //             // Prevent switch if Quote has been approved
    //             if (approved_quote) {
    //                 window.toastr.error('Cannot change status to Service Call when the Quote has been approved', 'Error Switching Status');
    //                 break;
    //             }
    //             // Prevent switch if more than one Quote option exists
    //             if (quotes?.quotes?.[0]?.options.length > 1) {
    //                 window.toastr.error('Estimate with multiple options cannot be converted to Service Call', 'Error Switching Status');
    //                 break;
    //             }

    //             const workOrder = workOrders?.work_orders?.[0];
    //             const quote = quotes?.quotes?.[0];

    //             dispatch(actions_service_requests.convertToServiceCall(params.handle, service_request, workOrder, quote, () => {
    //                 dispatch(actions_settings.statusModalClose());
    //                 let newLocation = toolbox.modifyPath(props.location.pathname, seg+5, 'work_orders/' + workOrder.id + '/items');
    //                 history.push({ pathname: newLocation });
    //             }));
    //             break;
    //         }
    //         case SERVICE_REQUEST_TERMS.ESTIMATE.id: {

    //             // Prevent switch if Work Order has been completed or invoiced
    //             const completed = workOrders?.work_orders?.find((workOrder) => (workOrder.statusId == WORKORDERS.COMPLETE.id || workOrder.statusId == WORKORDERS.INVOICED.id));
    //             if (completed) {
    //                 window.toastr.error('Cannot change status to estimate if Work Order has been completed or invoiced', 'Error Switching Status', 'Error Switching Status');
    //                 break;
    //             }
    //             dispatch(actions_service_requests.convertToEstimate(params.handle, service_request, () => {
    //                 dispatch(actions_quotes.createQuoteFromWorkOrders(params.handle, params.request_id, (quoteId) => {
    //                     dispatch(actions_settings.statusModalClose());
    //                     let newLocation = toolbox.modifyPath(props.location.pathname, seg+5, 'quotes/' + quoteId + '/items');
    //                     history.push({ pathname: newLocation });
    //                 }));
    //             }));
    //             break;
    //         }
    //     }
    // };
    // const handleStatus = (statusTable, statusId) => {
    //     dispatch(actions_settings.statusModalOpen(statusTable, statusId, service_request.id, handleChangeStatus));
    // };
    // const confirmGenerateQuote = () => {
    //     ModalAlert({
    //         title: 'Generate a Quote?',
    //         text: "This will generate a Quote from the open Work Orders on this Service Request",
    //         icon: 'question',
    //         show_cancel: true,
    //         confirm_color: '#8FBC8B',
    //         confirm_text: 'Yes, Create Quote',
    //         callback_success: () => {
    //             setProcessing(true);
    //             dispatch(actions_quotes.createQuoteFromWorkOrders(params.handle, params.request_id, (quoteId) => {
    //                 setProcessing(false);
    //                 let location = toolbox.modifyPath(props.location.pathname, seg, 'form/0/0/service_requests/' + params.request_id + '/quotes/' + quoteId + '/items');
    //                 history.push({ pathname: location });
    //             }));
    //         },
    //     });
    // };
    // const confirmGenerateChange = () => {
    //     ModalAlert({
    //         title: 'Generate a Change Order?',
    //         text: "This will generate a new Quote highlighting changes from the currently Accepted Quote",
    //         icon: 'question',
    //         show_cancel: true,
    //         confirm_color: '#8FBC8B',
    //         confirm_text: 'Yes, Create Change Order',
    //         callback_success: () => {
    //             setProcessing(true);
    //             dispatch(actions_quotes.createChangeOrder(params.handle, params.request_id, (quoteId) => {
    //                 setProcessing(false);
    //                 let location = toolbox.modifyPath(props.location.pathname, seg, 'form/0/0/service_requests/' + params.request_id + '/quotes/' + quoteId + '/items');
    //                 history.push({ pathname: location });
    //             }));
    //         },
    //     });
    // };
    // const confirmTimeMaterials = () => { };

    const openHelpPanel = (url) => {
        if (window.flutter) {
            window.flutter.callHandler('helpPanel', url); 
        } else {
            dispatch(actions_admin.showHelp(true, url));
        }
	}
	const toggleLightbox = () => {
		let imagesAlt = service_request.imagesAlt?.map((imageUrl, index) => {
			return ({ src: imageSize(imageUrl, 'lg') });
		});
		if (!admin.lightbox_show) dispatch(actions_admin.lightboxSources([
			{ src: imageSize(service_request.imageUrl, 'lg') },
			...(service_request.imagesAlt) ? imagesAlt : []
		]));
		dispatch(actions_admin.lightboxShow(!admin.lightbox_show));
	}

    /* Constants ------------------------------------------------------------------------------------------------------------------------------------*/

    var contactArray = []; // start will all contacts, remove when assigned an address
    if (profile?.contacts) profile.contacts.forEach((contact) => {
        contactArray.push(contact);
    });

    const custAvatar = 
        <div role="button" onClick={openEntity}>
            <Avatar
                className="float-right"
                color="white"
                bgColor="saddleBrown"
                size={45}
                fontSize={'10px'}
                name={(params.appId == APPS.SERVICE.id) ? service_request?._displayName : service_request?._name }
                image={imageSize(service_request?._imageUrl, 'sm')}
                border="3px solid white"
            />
        </div>
    
    /* Render ------------------------------------------------------------------------------------------------------------------------------------*/

    return (

		<div className="row position-relative mb-2">
            {service_request
                ?   <Authorize perm={ PERMISSIONS.SERVICE_REQUESTS_VIEW } or={ canViewMySR } show403>
                        {/* <div className="position-absolute animated fadeIn animation-delay-4" style={{ top: '62px', left: '18px', zIndex: 100 }}>
                            { custAvatar }
                        </div> */}
                        <div id="request-avatar" className={ 'col-12 mb-3' }>
                            <div className="profile-image" onClick={ (service_request.imageUrl) ? toggleLightbox : null }>
                                <div className="mb-2">
                                    { !!service_request.imagesAlt?.length &&
                                        <Avatar
                                            style={{ position: 'absolute', left: '70px', top: '55px' }}
                                            color="white"
                                            bgColor="black"
                                            size={35}
                                            fontSize={'10px'}
                                            name={`+${service_request.imagesAlt.length}`}
                                            initials={ false }
                                            border="3px solid white"
                                            role="button"
                                        />
                                    }
                                    <Avatar
                                        color="white"
                                        bgColor="darkseagreen"
                                        size={90}
                                        name={service_request.customId}
                                        initials={false}
                                        image={imageSize(service_request.imageUrl, 'sm')}
                                        border="4px solid white"
                                        role={ (service_request.imageUrl) ? "button" : null }
                                    />
                                </div>

                            </div>
                            <div className="profile-info pt-1 pr-2">
                                <div className="d-flex">
                                    { isRecord
                                        ?   <h1 className="main-heading heading-service-record align-self-start">
                                                #{service_request.customId} Service Record
                                            </h1>
                                        :   <h1 className="main-heading heading-service-request align-self-start">
                                                #{service_request.customId} Service Request
                                            </h1>
                                    }
                                    {segments < seg+7 && !window.flutter &&
                                        <div className={ 'animated fadeIn mt-n2 ml-3' } style={{ cursor: 'pointer', height: '10px' }} 
                                            onClick={() => { history.goBack() }}>
                                            <i className="fas fa-times" style={{ fontSize: '38px', color: '#bbbbbb' }}></i>
                                        </div>
                                    }
                                </div>
                                <h2 className="line-clamp-2 ml-2 mt-1">{service_request.description}</h2>
                                { service_request?.offsite && <span className="badge badge-primary ml-2">Asset is OFFSITE</span> }
                            </div>
                        </div>

                        {/* BUTTON ROWN ------------------------------------------------------------- */}

                        <div className="col-12 pr-5">
                            <button type="button" 
                                className={'btn btn-default btn-sm mr-2' }
                                disabled={!canEditSR}
                                onClick={openForm}><i className="fa-regular fa-gear fa-lg text-muted mr-1"></i>{ `Edit Service` }
                            </button>
                            <StatusSelect className="mr-2"
                                selectedStatusId={service_request.statusId}
                                statusTypes={Object.values(REQUESTS)}
                                statusTable={'REQUESTS'}
                                static={true}
                            />
                            <StatusSelect className="mr-2"
                                selectedStatusId={service_request.serviceRequestTermsId}
                                statusTypes={Object.values(SERVICE_REQUEST_TERMS)}
                                statusTable={'SERVICE_REQUEST_TERMS'}
                                static={true}
                            />
                        </div>

                        {/* EXPANDABLE DETAILS ------------------------------------------------------ */}

                        <div className="col-12">
                            <div id="details" className="mb-2">
                                <ExpandDetails more="Show More">
                                    <div id="service-request-details" className="mt-3 mb-2">
                                        { params.appId == APPS.SERVICE.id
                                            ?   <>
                                                    <DashboardDetail field={ custAvatar } value={ <span>{service_request._displayName}<br/>{state.mapAddress}</span> } 
                                                        type={ (props.source == 'dashboard') ? 'jsx' : 'text' }
                                                        jsx={ <TooltipOverlay tooltip={ "View Customer" }>
                                                                <i className="fas fa-external-link-square fa-2x mr-2 text-muted" role="button" onClick={openEntity}></i>
                                                            </TooltipOverlay>}
                                                    />
                                                    <DashboardDetail field={ 'Request Date' } value={ toolbox.dateOrDash(service_request.requestDate, 'MMM DD, YYYY h:mm A') } />
                                                </>
                                            :   <>
                                                    <DashboardDetail field={ custAvatar } value={ `#${service_request._unitNumber} ${service_request._name}` }
                                                        type={ (props.source == 'dashboard') ? 'jsx' : 'text' }
                                                        jsx={ <TooltipOverlay tooltip={ "View Asset" }>
                                                                <i className="fas fa-external-link-square fa-2x mr-2 text-muted" role="button" onClick={openEntity}></i>
                                                            </TooltipOverlay>}
                                                    />
                                                    <DashboardDetail field={ 'Request Date' } value={ toolbox.dateOrDash(service_request.requestDate, 'MMM DD, YYYY h:mm A') } />
                                                    <DashboardDetail field={ 'Miles/Hours' } value={ service_request.milesHours } />
                                                </>
                                        }
                                        <DashboardDetail field={ 'Summary' } value={ service_request.description } />
                                        <DashboardDetail field={ 'Suggested' } value={ service_request.suggested } type="text" />
                                        <DashboardDetail field={ 'Total Cost' } value={ service_request.totalCost } type="usd" suffix=" + tax" />
                                        <DashboardDetail field={ 'Created By' } value={ `${owner?.contact.firstName} ${owner?.contact.lastName}` } type="text" />
                                    </div>
                                </ExpandDetails>

                                { workOrders?.work_orders?.map((work_order) => {
                                        if (!work_order.reason) return null;
                                        return (
                                            <div className="alert alert-warning mt-3">
                                                <strong>WO: #{work_order.customId} INCOMPLETE:</strong>&nbsp; { work_order.reason }
                                            </div>
                                        )
                                    })
                                }

                                { service_request.deleted &&
                                    <div className="alert alert-danger mt-3 mb-0 d-flex align-items-center">
                                        <i className="fa-solid fa-circle-xmark fa-2x mr-3"></i>
                                        <span>
                                            This Service Request has been <strong>ARCHIVED!</strong> &nbsp; You may be able to restore it from the <strong>Archive</strong> page on the main menu
                                        </span>
                                    </div>
                                }
                                { service_request.statusId == REQUESTS.COMPLETE.id &&
                                    <div className="alert alert-info mt-3 mb-0 d-flex align-items-center">
                                        <i className="fa-solid fa-circle-info fa-2x mr-3"></i>
                                        <span>
                                            This Service Request has been Completed!  The next step is to generate an <strong>Invoice</strong> from the <strong>Actions</strong> button.
                                        </span>
                                    </div>
                                }

                                { canEditSR && !admin.bottom_sheet_show && params.form == 'form' && segments < 12 &&
                                    <SpeedDialButton 
                                        columnRef={ props.columnRef }
                                        onClick={handleSpeedDial}
                                        actions={[
                                            ...(canClose) ? [{
                                                name: 'Close Service Request', 
                                                value: 'close', 
                                                className: '', 
                                                icon: <i className="fa-solid fa-folder-closed fa-xl"></i>
                                            }] : [],
                                            ...(canReceivePayment) ? [{
                                                name: 'Receive Payment', 
                                                value: 'payment', 
                                                className: '', 
                                                icon: <i className="fa-regular fa-credit-card fa-xl"></i> 
                                            }] : [],
                                            ...(canInvoice) ? [{
                                                name: 'Generate Invoice', 
                                                value: 'invoice', 
                                                className: '', 
                                                icon: <i className="fas fa-dollar-sign fa-xl"></i> 
                                            }] : [],
                                        ]}
                                    />
                                }
                            </div>

                            {/* { params.appId == APPS.SERVICE.id &&
                                <ExpandDetails more="Map" top={ 15 } limitHeight={ 40 } default="closed">
                                    <div className="row">
                                        <h2 className="ml-3" style={{ paddingRight: '70px' }}>{state.mapAddress}</h2>
                                        <div className="mapbox-container mt-2">
                                            {state.mapAddress &&
                                                <Mapbox map_address={state.mapAddress} aspect_ratio={3} />
                                            }
                                        </div>
                                    </div>
                                </ExpandDetails>
                            } */}
                        </div>
                    </Authorize>
                : <Spinner center />
            }
        </div>
    );
};

export default ServiceRequestDashboard;

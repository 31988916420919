import * as actions_invoices from 'actions/invoices-actions';
import * as toolbox from 'components/common/toolbox';
import * as actions_quickbooks from 'actions/quickbooks-actions';
import React, { useEffect, useState } from 'react';
import { APPS } from 'components/constants';
import { ModalForm } from 'enspire-manager-framework';
import { Table } from 'em-table';
import { WORKORDERS } from 'components/constants';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

const moment = require('moment');
const _ = require('lodash');

const GenerateInvoiceForm = (props) => {
	
	/* Hooks ------------------------------------------------------------------------------------------------------------------------------------*/
	
	const params = useParams();
	const dispatch = useDispatch();
	const routeLocation = useLocation();
    const seg = 5;

	const employees = useSelector((store) => store.employees.employees);
	const invoices = useSelector((store) => store.invoices);
	const quickbooksSettings = useSelector((store) => store.quickbooks.quickbooks);
	const user = useSelector((store) => store.users.user);
	const workOrders = useSelector((store) => store.workOrders.work_orders);

	const [selectedWorkOrders, setSelectedWorkOrders] = useState([]);

	useEffect(() => {
		setSelectedWorkOrders(workOrders); 
	}, [workOrders]);
	
	/* Handlers ------------------------------------------------------------------------------------------------------------------------------------*/

	const handleSelect = (item) => {
		let newWorkOrders = (selectedWorkOrders) ? [...selectedWorkOrders] : [];
		let checked = !!selectedWorkOrders?.find((w) => w.id == item.id);

		if (!checked) newWorkOrders.push(item);
		else {
			let index = newWorkOrders.findIndex((wo) => wo.id == item.id);
			if (index > -1) { newWorkOrders.splice(index, 1); }
		}
		setSelectedWorkOrders(newWorkOrders);
	};
	const handleToggleAll = () => {
		let shouldCheck = (!selectedWorkOrders.length);
		let newWorkOrders = [];
		workOrders.forEach((wo) => {
			if (wo.statusId == WORKORDERS.COMPLETE.id && shouldCheck) {
				newWorkOrders.push(wo);
			}
		});
		setSelectedWorkOrders(newWorkOrders);
	}

	/* Actions ------------------------------------------------------------------------------------------------------------------------------------*/
	
	const submitForm = () => {
		if (selectedWorkOrders.length < 1) {
			return window.toastr.error('Please select at least one work order to include on the invoice.', "No Work Orders Selected");
		} else {
			dispatch(actions_invoices.createInvoice(params.handle, params.appId, params.request_id, selectedWorkOrders, user, (invoiceId) => {
				dispatch(actions_quickbooks.updateQuickbooks({ handle: params.handle, type: 'invoice' }, quickbooksSettings));
				let location = toolbox.modifyPath(routeLocation.pathname, seg, `form/0/service_requests/${params.request_id}/invoices/preview/${invoiceId}`);
				props.history.replace({ pathname: location });
			}));
		}
	};
	
	/* Constants ------------------------------------------------------------------------------------------------------------------------------------*/

	const data = workOrders.map((wo) => {
		let technician = employees?.find((e) => e.id == wo.technicianId);
		return ({ ...wo,
			_stripe_color: Object.values(WORKORDERS).find((o) => o.id == wo.statusId)?.color,
			_no_checkbox: (wo.statusId != WORKORDERS.COMPLETE.id) ? ['checked'] : [],
			startDate: moment(wo.startDate.seconds, 'X').format('MM/DD/YYYY'),
			checked: !!selectedWorkOrders?.find((w) => w.id == wo.id),
			technician: (technician?.contact.firstName) ? technician?.contact.firstName + ' ' + technician?.contact.lastName : 'N/A',
		});
	});

	const columns = [
		{ name: 'Custom ID', field: 'customId', width: 30 },
		{ name: 'Start', field: 'startDate', width: 30 },
		{ name: 'Technician', field: 'technician', width: 30 },
        { name: '', field: 'checked', type: 'checkbox', callback: handleSelect, width: 10},
		{ name: 'Description', field: 'description' },
	];

	/* Render ------------------------------------------------------------------------------------------------------------------------------------*/

	return (

		<ModalForm {...props}
			history={props.history}
			modal_header={'Generate Invoice'}
			cancel_button_title="Cancel"
			save_button_title={'Generate Invoice'}
			submitFormHandler={() => submitForm()}
			show_spinner={invoices.invoice_save_pending}
		>
			<button className="btn btn-default btn-sm float-right mt-3" onClick={ handleToggleAll }>Toggle All</button>
			<h2>Select Work Order(s) to Include on the Invoice:</h2>
			<div className="alert alert-info mt-3 mb-0 d-flex align-items-center">
				<i className="fa-solid fa-circle-info fa-2x mr-3"></i>
				<span>Only <strong>Completed</strong> Work Orders are able to be selected and included on the Invoice</span>
			</div>

			<Table
				data={data}
				show_limit={false}
				columns={columns}
				click_callback={handleSelect}
                order_by = {{ fields: ['id'], direction: ['desc'] }}
				second_line={'description'}
			/>
		</ModalForm>
	);
};


export default GenerateInvoiceForm;

import * as toolbox from 'components/common/toolbox';
import React from 'react';
import _ from 'lodash';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

const ResultsActionIcons = (props) => {
    
    /* Hooks --------------------------*/
    
    const history = useHistory();
	const routeLocation = useLocation();

    const serviceRequests = useSelector((store) => store.serviceRequests.service_requests_dashboard);
    const workOrders = useSelector((store) => store.workOrders.work_orders_dashboard);
    
    const entity = (props.entity == 'sr')
        ?   _.find(serviceRequests, { id: props.entityId })
        :   _.find(workOrders, { id: props.entityId });
    
    const openSMS = () => {
        let location = toolbox.modifyPath(routeLocation.pathname, 4, `action-form/${props.entity}/${props.entityId}/sms`, 7);
        history.push({ pathname: location });
    };
    const openPhone = () => {
        let location = toolbox.modifyPath(routeLocation.pathname, 4, `action-form/${props.entity}/${props.entityId}/phone`, 7);
        history.push({ pathname: location });
    };
   
    return (

        <div id="results-actions-icons" className="d-flex nowrap">
            { entity?.technicianId && entity?.customerId &&
                <a className="ml-2"><i className="fa-solid fa-mobile-screen " role="button" style={{ color: 'saddlebrown' }} onClick={ openSMS }></i></a>
            }
            { entity?.customerId &&
                <a><i className="fa-solid fa-phone " role="button" style={{ color: 'green' }} onClick={ openPhone }></i></a>
            }
            { entity?._address &&
                <a href={`https://www.google.com/maps/dir/?api=1&destination=${entity?._address}`} target="_blank"><i className="fa-solid fa-location-arrow" role="button" style={{ color: 'steelblue' }}></i></a>
            }
        </div>
    );
};

export default ResultsActionIcons;


import * as actions_timeclock from 'actions/timeclock-actions';
import * as toolbox from 'components/common/toolbox';
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import dayjs from 'dayjs';
import { Accordion, Card } from 'react-bootstrap';
import { CloseX, Ibox, Input, Select, Spinner } from 'enspire-manager-framework';
import { Table } from 'em-table';
import { confirm } from 'components/common/toolbox';
import { numPanels } from 'components/common/toolbox';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

const advancedFormat = require("dayjs/plugin/advancedFormat");
const duration = require("dayjs/plugin/duration");
const utc = require("dayjs/plugin/utc");
dayjs.extend(advancedFormat);
dayjs.extend(duration);
dayjs.extend(utc);

export default function Timesheets(props) {
	
	const seg = 4;
	const params = useParams();
	const history = useHistory();
	const dispatch = useDispatch();
	const routeLocation = useLocation();

    const employees = useSelector((store) => store.employees.employees);
    const users = useSelector(store => store.users);
    const user_permissions = useSelector(store => store.users?.user_permissions);
	const timeClockSettings = useSelector(store => store.settings.settings.timeclock)
	const timeSheet = useSelector(store => store.users?.time_sheet?.[0]) ?? {};
    const checkedIn = useSelector(store => store.users?.time_sheet?.[1]) ?? {};

	const start_work_week = (timeClockSettings?.start_work_week) ? parseInt(timeClockSettings?.start_work_week) : 1;

    const [open, setOpen] = useState('');
	const [showAnalytics, setShowAnalytics] = useState(false);
	const [startDate, setStartDate] = useState(dayjs().day(start_work_week).format('YYYY-MM-DD'));
	const [renderDate, setRenderDate] = useState(dayjs().day(start_work_week + 7));
	const [rendering, setRendering] = useState(false);

	const fromTimeClock = (props.source == 'timeclock'); // current user
	const isTimecards = (props.source == 'timecards' || fromTimeClock);
	const user_id = (fromTimeClock) ? users.user.id : props.user_id;

	/* Effects -----------------------------------------------------------------------------------------------------------------*/
	
	// Get rendered Timesheets & Timecards
	useEffect(() => {
		if (isTimecards) {
			dispatch(actions_timeclock.subTimeCards(params.handle, user_id));
		} else {
			dispatch(actions_timeclock.subTimeSheets(params.handle));
		}

        return () => {
            var unsubscribe = (isTimecards) ? users.time_cards_unsubscribe : users.time_sheets_unsubscribe;
            if (typeof unsubscribe === 'function') unsubscribe();
        };
	}, [params.appId, params.handle, props.user_id]);

	// Get selected week timesheet data
	useEffect(() => {
		if (startDate) dispatch(actions_timeclock.getTimeSheetData(params.handle, [dayjs(startDate).toDate(), dayjs(startDate).add(6, 'day').toDate()]));
	}, [startDate]);

	// delay to prevent jank
	useEffect(() => {
		setShowAnalytics(false);
		setTimeout(() => {
			setShowAnalytics(true);
		}, 150);
	}, []);

	/* Handlers --------------------------*/

	const handleInc = () => {
		setStartDate(dayjs(startDate).add(1, 'week').format('YYYY-MM-DD'));
	}
	const handleDec = () => {
		setStartDate(dayjs(startDate).subtract(1, 'week').format('YYYY-MM-DD'));
	}
	const handleSelectTimesheet = (event, value) => {
		let newValue = (value) ? value : event.target.value;
		setStartDate(newValue);
	}
	const handleOpenAccordian = (tab) => {
        if (open == tab) setOpen("");
        else setOpen(tab);
	}
	const openEditTimeClock = (id) => {
		let location = toolbox.modifyPath(routeLocation.pathname, seg, id + '/time-clock/' + dayjs(startDate).format('X') + '/' + dayjs(startDate).add(6, 'day').format('X')); 
		history.push({ pathname: location });
	}
	const openEditCheckin = (id) => {
		let location = toolbox.modifyPath(routeLocation.pathname, seg, id + '/check-in/' + dayjs(startDate).format('X') + '/' + dayjs(startDate).add(6, 'day').format('X')); 
		history.push({ pathname: location });
	}
	const handleRender = () => {
		setRendering(true);
		dispatch(actions_timeclock.createTimesheetPdf({ 
			handle: params.handle,
			shadeColor: '#116a75',
			startdate: dayjs(renderDate).format('YYYY-MM-DD'),
		}, isTimecards, (result) => setRendering(false)));
	}

	/* Generate Timecard Components -----------------------------------------------------------------------------------------------------------------*/
	
	const [timecards_array, totalTime] = actions_timeclock.returnTimecardComponents({
		checkedIn,
		employees,
		handleOpenAccordian,
		isTimecards,
		fromTimeClock,
		open,
		openEditCheckin, 
		openEditTimeClock, 
		timeSheet,
		user_id: user_id,
		user_permissions, 
	});
	
	/* Constants --------------------------*/

	const timesheet = _.find(users.time_sheets, { id: startDate });
	const timecard = _.find(users.time_cards, (o) => o.id.startsWith(startDate));

	let periodOptions = [];
	let periodEntries = [];
	let entries = (isTimecards) ? users.time_cards : users.time_sheets;

	for (let entry of entries) {
		let date = dayjs(entry.id);
		periodOptions.push(<option value={ date.format('YYYY-MM-DD') }>{ `${date.format('ddd MMM Do, YYYY')} - Total Time: ${dayjs.duration(entry.totalTime, 'seconds').format("HH:mm")} hrs` }</option>);
		periodEntries.push({ ...entry, 
			duration: dayjs.duration(entry.totalTime, 'seconds').format("HH:mm"),
			alert: (entry.missingClockout?.length) ? <i className="fa-solid fa-triangle-exclamation fa-lg" style={{ color: 'red' }}></i> : null,
		});
	}

	return (
		<>
			{ showAnalytics &&
				<>
					{ !fromTimeClock &&
						<>
							{ isTimecards 

								// RENDERED TIMESHEETS -----------------------------------------------------------------
								
								?	<div className="col-lg-6">
										<Ibox title={ 'Timecards' } show_spinner={users.time_sheets_pending} no_fade={true}>
											<Table
												data={periodEntries}
												limit={5}
												chevron={true}
												active_id={`${startDate}-${props.user_id}`}
												active_field={"id"}
												columns={[
													{ name: "Date", field: "startDate", type: 'date', utc: true, format: 'ddd, MMM D, YYYY', width: 40 },
													{ name: "Rendered", field: "created", type: 'date', utc: true, format: 'MM-DD h:mma', width: 30 },
													{ name: "", field: "alert", type: 'jsx', text_align: 'center', width: 15 },
													{ name: "Time", field: "duration", text_align: 'right', width: 15 },
												]}
												order_by={{ fields: ['name'], direction: ['asc'] }}
												click_callback={ (timesheet) => {
													handleSelectTimesheet(null, dayjs.unix(timesheet.startDate.seconds).utc().format('YYYY-MM-DD'));
												}}
												/>
										</Ibox>
									</div>

								:	<div className="col-lg-6">
										{ numPanels(1) 
											?	<Select
													className="mt-3 mb-3"
													label="Time Period Starting"
													noLabel={false} 
													onChange={handleSelectTimesheet}
												>
													<option value={ dayjs().startOf('week').format('YYYY-MM-DD') }>{ dayjs().startOf('week').format('ddd MMM Do, YYYY') + ' - Current' }</option>
													{ periodOptions }
												</Select>

											:	<Ibox title={ 'Employee Timesheets'} show_spinner={users.time_sheets_pending} no_fade={true}>
													<div className="row">
														<div className="col-7">
															<Input
																name={'renderDate'}
																noLabel={true}
																filterDate={(moment) => {
																	if (dayjs(moment).day() != start_work_week) return true
																}}
																onChange={(field, date) => setRenderDate(date) }
																type="date"
																value={renderDate}
															/>
														</div>
														<div className="col-5">
															<button className={ 'btn btn-primary' } onClick={ () => {
																confirm(`Are you sure to want to Render/Re-Render this ${(isTimecards)?'Timecard':'Timesheet'}?`, handleRender);
															}}>{ (isTimecards) ? 'Render Timecard' : 'Render Timesheet' }
																{ rendering && <span className="spinner-border spinner-border-sm ml-2" role="status" aria-hidden="true"></span> }
															</button>
														</div>
													</div>

													<Table
														data={periodEntries}
														limit={5}
														chevron={true}
														active_id={startDate}
														active_field={"id"}
														columns={[
															{ name: "Date", field: "startDate", type: 'date', utc: true, format: 'ddd, MMM D, YYYY', width: 40 },
															{ name: "Rendered", field: "created", type: 'date', utc: true, format: 'MM-DD h:mma', width: 30 },
															{ name: "", field: "alert", type: 'jsx', text_align: 'center', width: 15 },
															{ name: "Time", field: "duration", text_align: 'right', width: 15 },
														]}
														order_by={{ fields: ['name'], direction: ['asc'] }}
														click_callback={ (timesheet) => {
															handleSelectTimesheet(null, dayjs.unix(timesheet.startDate.seconds).utc().format('YYYY-MM-DD'));
														}}
														/>
												</Ibox>
										}
									</div>
							}
						</>
					}

					{/* INDIVIDUAL TIMECARDS ----------------------------------------------------------------- */}

					<div className={ (fromTimeClock) ? 'col-12' : 'col-lg-6'}>
						
						{/* Header -------------------------------------------------------------------------------- */}

						<div className="d-flex justify-content-between flex-wrap">

							{ (fromTimeClock || isTimecards) 
								?	<>
										<h2 className="mb-0 mt-1 mb-2 ml-2">{(timecard && isTimecards) ? 'Timecard:' : 'Current Time Period:' } <small className="ml-3">{dayjs(startDate).format('MMM Do, YYYY')}</small></h2>
										{ fromTimeClock &&
											<div>
												<i className="fa-solid fa-chevron-left text-muted fa-2x mt-1 px-1" role="button" onClick={handleDec}></i>
												{ startDate < dayjs().day(start_work_week).format('YYYY-MM-DD')
													? <i className="fa-solid fa-chevron-right text-muted fa-2x mt-1 px-1 mr-2" role="button" onClick={handleInc}></i>
													: <i className="fa-solid fa-chevron-right fa-2x mt-1 px-1 mr-2" style={{ color: '#dddddd' }}></i>
												}
											</div>
										}
									</>
								:	<h2 className="mb-0 mt-1 mb-2">{(timesheet && !isTimecards) ? 'Timecard:' : 'Current Time Period:' } <small className="ml-2">{dayjs(startDate).format('MMM Do, YYYY')}</small></h2>
							}
							
							{ ((timesheet || timecard) && !fromTimeClock) && <CloseX onClick={() => setStartDate(dayjs().startOf('week').format('YYYY-MM-DD'))} className="mr-3 mt-0" color={ '#aaaaaa' } /> }
								
							{ timesheet && !isTimecards &&
								<a className="btn btn-secondary mr-5" href={ timesheet.downloadUrl } target="_blank">
									<i className="fas fa-print mr-1"></i> Print Timesheet
								</a>
							}
							{ timecard && isTimecards &&
								<a className="btn btn-secondary mr-5" href={ timecard.downloadUrl } target="_blank">
									<i className="fas fa-print mr-1"></i> Print Timecard
								</a>
							}
						</div>
						<div style={{ clear: 'both' }}></div>

						<ul className="sortable-list agile-list mt-3" id="todo">

							{/* TimeCard(s) ------------------------------------------------------------------------- */}
							
							{ users.time_sheet_pending
								? <Spinner />
								: <Accordion  defaultActiveKey={ (isTimecards || fromTimeClock) ? '0': '' }>{timecards_array}</Accordion>
							}
							{ !timecards_array.length &&
								<p className="ml-3 mb-4">-- No Completed Time Entries --</p>
							}

							{/* Total ------------------------------------------------------------------------------- */}

							<li className="warning-element ui-sortable-handle mt-3 animated fadeIn animation-delay-4">
								<div className="agile-detail">
									<span className="float-right" style={{ fontSize: '18px' }}>
										{ toolbox.formatHoursMinutes(totalTime) } <small>hrs</small>
									</span>
									<h2 className="m-0">Total</h2>
								</div>
							</li>
						</ul>
					</div>
				</>
			}
		</>
	);
};


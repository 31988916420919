import * as actions_admin from 'actions/admin-actions';
import * as toolbox from 'components/common/toolbox';
import Authorize from 'components/common/authorize';
import InvoicesTasks from './invoices-tasks';
import MyTasks from './my-tasks';
import QuotesTasks from './quotes-tasks';
import React, { useEffect, useState } from 'react';
import ResultsMyWorkOrders from 'components/dashboard/components/results-my-work-orders';
import ServiceRequestsTasks from './service-requests-tasks';
import TasksCheckin from 'components/time-clock/tasks-checkin';
import WorkOrdersTasks from './work-orders-tasks';
import { PERMISSIONS } from 'components/constants';
import { numPanels } from 'components/common/toolbox';
import { useHistory, useLocation, useParams } from 'react-router-dom';

export default function DashboardLayout(props) {

	const seg = 3;
	const params = useParams();
	const history = useHistory();
	const routeLocation = useLocation();

	const [currenWidget, setCurrentWidget] = useState(false);

	useEffect(() => {
        setCurrentWidget(params.widget_id);
    });

	const handleClick = (widget_id) => {
		let segments = routeLocation.pathname.split('/').length;
        let num = (segments >= 12) ? -2 : (segments >= 10) ? -1 : 0;
		if (num) history.go(num);

		setTimeout(() => {
			let id = (widget_id !== currenWidget) ? widget_id : 'dashboard';
			var location = toolbox.modifyPath(routeLocation?.pathname, seg, `${id}`, seg);
			history.replace({ pathname: location });
		}, 50);
	}

	const toggleAnalytics = () => {
		let location = toolbox.modifyPathIfEmpty(routeLocation?.pathname, seg+1, "0"); // set employee_id to 0 if not already set
		location = toolbox.modifyPath(location, seg+2, 'analytics');
		history.push({ pathname: location });
	}
	// const updateadmin = () => {
	// 	actions_admin.updateadmin();
	// }

    return (
		<>
			{ numPanels(1) &&
				<button className="btn btn-info btn-lg btn-block my-2" onClick={ toggleAnalytics }>
					<i className="fa-solid fa-chart-simple"></i> &nbsp; View Dashboard Overview
				</button>
			}

			<Authorize permKeys={ [PERMISSIONS.WORK_ORDERS_ASSIGNED_VIEW.key, PERMISSIONS.WORK_ORDERS_ASSIGNED_EDIT.key] }>
				<ResultsMyWorkOrders source="checkedIn" />
				<h2 className="col-12 ml-2">My Dashboard</h2>
				{/* Show Currently Checked In */}
				<TasksCheckin source="checkedIn" />
				<MyTasks handleClick={ handleClick } />
			</Authorize>

			{/* <button onClick={ updateadmin }>UPDATE</button> */}


			<Authorize permKeys={[
				PERMISSIONS.SERVICE_REQUESTS_VIEW.key,
				PERMISSIONS.WORK_ORDERS_VIEW.key,
				PERMISSIONS.QUOTES_VIEW.key,
			]}>
				<div className="middleOfLine text-muted mt-4">
					<span className="fa-stack fa-2x">
						<i className="fas fa-circle fa-stack-2x"></i>
						<i className="fa-solid fa-user-gear fa-stack-1x fa-inverse"></i>
					</span>
				</div>
			</Authorize>


			<Authorize perm={ PERMISSIONS.SERVICE_REQUESTS_VIEW }>
				<h2 className="col-12 ml-2">Service Requests</h2>
				<ServiceRequestsTasks handleClick={ handleClick } />
			</Authorize>

			<Authorize perm={ PERMISSIONS.WORK_ORDERS_VIEW }>
				<h2 className="col-12 ml-2">Work Orders</h2>
				<WorkOrdersTasks handleClick={ handleClick } />
			</Authorize>

			<Authorize perm={ PERMISSIONS.QUOTES_VIEW }>
				<h2 className="col-12 ml-2">Quotes</h2>
				<QuotesTasks handleClick={ handleClick } />
			</Authorize>

			<Authorize perm={ PERMISSIONS.INVOICES_VIEW }>
				<h2 className="col-12 ml-2">Invoices</h2>
				<InvoicesTasks handleClick={ handleClick } />
			</Authorize>

			<div className="mb-5"></div>
		</>
	);
};

import 'App.css';
import "react-datepicker/dist/react-datepicker.css";
import 'react-bootstrap-typeahead/css/Typeahead.css';

import * as actions_admin from 'actions/admin-actions';
import * as actions_authentication from 'actions/authentication-actions';
import * as actions_company from 'actions/company-actions';
import * as actions_profiles from 'actions/profiles-actions';
import * as actions_quickbooks from 'actions/quickbooks-actions';
import * as actions_service_items from 'actions/service-items-actions';
import * as actions_settings from 'actions/settings-actions';
import * as actions_timeclock from 'actions/timeclock-actions';
import * as actions_users from 'actions/users-actions';
import * as actions_work_orders from 'actions/work-orders-actions';
import * as toolbox from 'components/common/toolbox';
import Archive from './archive/archive';
import Authorize from 'components/common/authorize';
import CatchErrors from 'components/common/catch-errors';
import Dashboard from 'components/dashboard/dashboard';
import Footer from './layout/footer';
import Navbar from './layout/navbar';
import QuickbooksConnectPopup from './quickbooks/quickbooks-connect-popup';
import QuoteOptions from 'components/quotes/quote/quote-options';
import React, { useEffect, useState } from 'react';
import ServiceMap from 'components/service-map/service-map';
import SideMenu from './layout/side-menu';
import Signature from 'components/quotes/quote/signature';
import _ from 'lodash';
import dayjs from 'dayjs';
import firebase from 'firebase/compat/app';
import metadata from '../metadata.json';
import { APPS, ASSETS_TABS, ASSETS_MORE, SERVICE_TABS, SERVICE_MORE, MAIN_NAV, SETTINGS_NAV, ROLES, PERMISSIONS } from 'components/constants';
import { AccountDelete, PasswordReset, Spinner, UserNotFound } from 'enspire-manager-framework';
import { Redirect, Route, Switch } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { numPanels, matchURL } from 'components/common/toolbox';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams, useRouteMatch } from 'react-router-dom';

// UNTIL APPLE LOGIN IS FIGURED OUT
import { Login } from 'components/authentication/login';
import { Register } from 'components/authentication/register';

const Assets = React.lazy(() => import('./assets/assets'));
const Customers = React.lazy(() => import('./customers/customers'));
const Employees = React.lazy(() => import('./employees/employees'));
const Schedule = React.lazy(() => import('./schedule/schedule'));
const Settings = React.lazy(() => import('./settings/settings'));
const StockParts = React.lazy(() => import('./inventory/inventory'));
const Vendors = React.lazy(() => import('./vendors/vendors'));

const utc = require("dayjs/plugin/utc");
const timezone = require("dayjs/plugin/timezone"); // dependent on utc plugin
dayjs.extend(utc);
dayjs.extend(timezone);

export default function App(props) {
	
	const match = useRouteMatch();
	const params = useParams();
	const history = useHistory();
	const dispatch = useDispatch();
	const routeLocation = useLocation();

	const admin = useSelector((store) => store.admin);
	const company = useSelector((store) => store.company.company);
	const settings = useSelector((store) => store.settings);
	const user = useSelector((store) => store.users.user);
	const user_permissions = useSelector(store => store.users?.user_permissions);
	const users = useSelector((store) => store.users);

	const [hideMenu, setHideMenu] = useState(false);	
    	
	/* Listeners -----------------------------------------------------------------------------------------------------------------------*/
	
	useEffect(() => {
		handleResize();
		window.addEventListener("resize", _.debounce(handleResize, 250));
	}, []);

	/* Listeners on events from Flutter ------------------------------------------------------------------------------------------------*/

	useEffect(() => {
		window.addEventListener("platform", (event) => {
			dispatch(actions_admin.flutterPlatform(event.detail.platform));
		});
	}, []);

	useEffect(() => {
		window.addEventListener("messaging_token", (event) => {
			if (params.handle != 'handle') dispatch(actions_users.saveToken(params.handle, event.detail.email, event.detail.token));
		});
	}, []);

	useEffect(() => {
		if (user) window.addEventListener("drawerAction", (event) => {
			if (event.detail.appId !== undefined) toolbox.selectApp(event.detail.appId, routeLocation, history);
			if (event.detail.handle !== undefined) toolbox.selectLocation(event.detail.handle, routeLocation, history);
			if (event.detail.notifyViaEmail !== undefined) dispatch(actions_profiles.saveContact(params.handle, user.profileId, { notifyViaEmail: event.detail.notifyViaEmail, id: user.contact.id }, 'employee'));
			if (event.detail.notifyViaSms !== undefined) dispatch(actions_profiles.saveContact(params.handle, user.profileId, { notifyViaSms: event.detail.notifyViaSms, id: user.contact.id }, 'employee'));
		});
	}, [user]);

	useEffect(() => {
		window.addEventListener("updateLocation", (event) => {
			toolbox.selectLocation(event.detail.handle, routeLocation, history);
		});
	}, []);

	useEffect(() => {
		window.addEventListener("closeBottomSheet", (event) => {
			dispatch(actions_admin.bottomSheetShow(false));
		});
	}, []);
	useEffect(() => {
		window.addEventListener("fabBarcodeScanned", (event) => {
			dispatch(actions_admin.barcodeToUrl(params.handle, params.appId, event.detail.barcode, (url) => {
				if (url) history.push(url);
				else window.toastr.error('The barcode did not match any Assets or Stock Parts', 'Match not Found!');
			}));
		});
	}, []);

	useEffect(() => {
		handleResize();
		window.addEventListener("virtualKeyboard", (event) => {
			dispatch(actions_admin.virtualKeyboard(event.detail.keyboard));
		});
	}, []);
	
	// useEffect(() => {
	// 	handleResize();
	// 	window.addEventListener("googleIdToken", async (event) => {
	// 		let idToken = event.detail.idToken;
	// 		const credential = firebase.auth.GoogleAuthProvider.credential(idToken);
	// 		await firebase.auth().signInWithCredential(credential);
	// 	});
	// }, []);

	// useEffect(() => {
	// 	handleResize();
	// 	window.addEventListener("appleIdToken", async (event) => {
	// 		let idToken = event.detail.idToken;
	// 		const credential = firebase.auth.GoogleAuthProvider.credential(idToken);
	// 		await firebase.auth().signInWithCredential(credential);
	// 	});
	// }, []);

	/* Send Settings to Flutter ------------------------------------------------------------------------------------------------*/

	useEffect(() => {

		let assets_tabs = ASSETS_TABS;
		let assets_more = ASSETS_MORE;
		let services_tabs = SERVICE_TABS;
		let services_more = SERVICE_MORE;
		
		// alter navs based on permission here
		if (!user_permissions[PERMISSIONS.EMPLOYEES_VIEW.key]) {
			assets_more = _.filter(assets_more, (o) => o.link != 'link-employees');
			services_more = _.filter(services_more, (o) => o.link != 'link-employees');
		}
		if (!user_permissions[PERMISSIONS.CUSTOMERS_VIEW.key]) {
			assets_more = _.filter(assets_more, (o) => o.link != 'link-customers');
			services_tabs = _.filter(services_tabs, (o) => o.link != 'link-customers');
		}
		if (!user_permissions[PERMISSIONS.INVENTORY_VIEW.key]) {
			assets_tabs = _.filter(assets_tabs, (o) => o.link != 'link-inventory');
			services_tabs = _.filter(services_tabs, (o) => o.link != 'link-inventory');
		}
		if (!user_permissions[PERMISSIONS.VENDORS_VIEW.key]) {
			assets_tabs = _.filter(assets_tabs, (o) => o.link != 'link-vendors');
			services_tabs = _.filter(services_tabs, (o) => o.link != 'link-vendors');
		}
		if (!user_permissions[PERMISSIONS.ASSETS_VIEW.key]) assets_tabs = _.filter(assets_tabs, (o) => o.link != 'link-assets');
		
		let settings_nav = SETTINGS_NAV;
		
		if (!user_permissions[PERMISSIONS.SETTINGS_ASSETS.key]) settings_nav = _.filter(settings_nav, (o) => o.link != 'link-settings-assets');
		if (!user_permissions[PERMISSIONS.SETTINGS_COMPANY.key]) settings_nav = _.filter(settings_nav, (o) => o.link != 'link-settings-company');
		if (!user_permissions[PERMISSIONS.SETTINGS_DEPARTMENTS.key]) settings_nav = _.filter(settings_nav, (o) => o.link != 'link-settings-departments');
		if (!user_permissions[PERMISSIONS.SETTINGS_INVENTORY.key]) settings_nav = _.filter(settings_nav, (o) => o.link != 'link-settings-inventory');
		if (!user_permissions[PERMISSIONS.SETTINGS_INVOICES.key]) settings_nav = _.filter(settings_nav, (o) => o.link != 'link-settings-invoices');
		if (!user_permissions[PERMISSIONS.SETTINGS_QUOTES.key]) settings_nav = _.filter(settings_nav, (o) => o.link != 'link-settings-quotes');
		if (!user_permissions[PERMISSIONS.SETTINGS_SERVICE.key]) settings_nav = _.filter(settings_nav, (o) => o.link != 'link-settings-service');
		if (!user_permissions[PERMISSIONS.SETTINGS_TAXES.key]) settings_nav = _.filter(settings_nav, (o) => o.link != 'link-settings-taxes');
		if (!user_permissions[PERMISSIONS.SETTINGS_TIME_CLOCK.key]) settings_nav = _.filter(settings_nav, (o) => o.link != 'link-settings-timeclock');

		if (window.flutter) {
			window.flutter.callHandler('data', 
				assets_tabs,
				assets_more,
				services_tabs,
				services_more,
				settings_nav,
				users?.user, 
				users?.user_permissions,
				Object.values(ROLES), 
				window.build, 
				settings.settings_global?.app_version,
				company,
				user.locations ?? [],
			);
		}
	}, [users?.user, users?.user_permissions, company]);

	useEffect(() => {
		if (window.flutter) {
			var path = routeLocation.pathname;
			var args = [path, null];

			MAIN_NAV.forEach((nav) => {
				if (matchURL(path, nav, params.appId)) args = [path, nav];
			});
			window.flutter.callHandler('navigation', args); 
		}
		console.log(routeLocation.pathname, history.length, routeLocation.pathname.split('/').length);
	}, [routeLocation.pathname]);
	
	useEffect(() => {
		if (window.flutter) window.flutter.callHandler('clockinStatus', users.time_clock?.clockedIn ?? false); 
	}, [users?.time_clock?.clockedIn]);
	useEffect(() => {
		if (window.flutter) window.flutter.callHandler('bottomSheetOpen', admin?.bottom_sheet_show ?? false); 
	}, [admin?.bottom_sheet_show]);

	/* When company changes, check if company has current appId, if not redirect ------------------------------------------------------*/

	useEffect(() => {
		if (company?.timezone?.value) dayjs.tz.setDefault(company?.timezone?.value); // Set the company default timezone;
		if (company && !company?.appIds.includes(params.appId)) {
			let location = toolbox.modifyPath(routeLocation?.pathname, 2, company?.appIds[0], 4);
			history.replace({ pathname: location });
		}
	}, [company]);

	/* When company changes, check if company has current appId, if not redirect ------------------------------------------------------*/
	
	useEffect(() => {
		if (metadata?.buildRevision < settings.settings_global?.build) {
			window.toastr.info('Please click to UPDATE the software to the latest version.', 'UPDATED VERSION AVAILABLE!', {
				timeOut: 0,
				extendedTimeOut: 0,
				onclick: () => { 
					if (window.flutter) window.flutter.callHandler('refresh'); 
					else window.location.reload();
				}
			});
		}
	}, [settings.settings_global?.build]);

	/* Auth State Listener ------------------------------------------------------------------------------------------------------------*/

	useEffect(() => {
		const unsubscribe = onAuthStateChanged(getAuth(), (user) => {
			if (user) {
				dispatch(actions_authentication.validateLogin(params.handle, user, (result) => {

					if (result === 'not_found') history.push(`/${params.handle}/${params.appId}/user_not_found/${user.email}`);
					if (result === 'success') {
						dispatch(actions_company.subCompany(params.handle));
						dispatch(actions_profiles.subEmployees(params.handle));
						dispatch(actions_quickbooks.subQuickbooksSettings(params.handle));
						dispatch(actions_service_items.subServiceItemCategories(params.handle));
						dispatch(actions_settings.subAssetClassifications(params.handle));
						dispatch(actions_settings.subAssetTypes(params.handle));
						dispatch(actions_settings.subDepartmentTags(params.handle));
						dispatch(actions_settings.subDepartments(params.handle));
						dispatch(actions_settings.subJobCategories(params.handle));
						dispatch(actions_settings.subPartsCategories(params.handle));
						dispatch(actions_settings.subSettings(params.handle));
						dispatch(actions_settings.subTasks(params.handle));
						dispatch(actions_admin.subCounts(params.handle));
						dispatch(actions_timeclock.subTimeClock(params.handle, user.email));
						dispatch(actions_users.subUserPermissions(params.handle, user.email));
						dispatch(actions_users.subUserRecentItems(params.handle, user.email));
						dispatch(actions_users.subUser(params.handle, user.email, (userdata) => {
							dispatch(actions_work_orders.subTechnicianWorkOrders(params.handle, userdata.profileId, params.appId));
							
							if (
								// use window.location.href as routeLocation.pathname may not be updated (except first two)
								routeLocation.pathname == `/${params.handle}` ||
								routeLocation.pathname.includes('/login') ||
								window.location.href.includes('/login') || 
								window.location.href.includes('/register_user') ||
								window.location.href.includes('/register_company')
							) {
								history.push(`/${params.handle}/${params.appId}/dashboard`);
							}	
						}));	
					}	
				}));	
			} else {
				console.log('logged out');
				if (
					!window.location.href.includes('/login') &&
					!window.location.href.includes('/register_user') &&
					!window.location.href.includes('/email_link') &&
					!window.location.href.includes('signature') &&
					!window.location.href.includes('quote_options')
				) {
					history.push(`/${params.handle}/${params.appId}/login`);
				}
			}	
		});	

		return () => {
			if (typeof unsubscribe === 'function') unsubscribe();
		};	
	}, [params.handle]);	

	/* When Appid changes, update my Work Orders ------------------------------------------------------*/
	
	useEffect(() => {
		if (user.profileId) dispatch(actions_work_orders.subTechnicianWorkOrders(params.handle, user.profileId, params.appId));
		if (window.flutter) window.flutter.callHandler('setAppId', params.appId); 
	}, [params.appId]);


	/* Handlers ----------------------------------------------------------------------------------------------------------------------*/

	const handleResize = () => {
		setHideMenu(numPanels(1) || window.flutter);
	}

	// const toggleMenuCollapsed = () => {
	// 	dispatch(actions_settings.toggleMenuCollapsed());
	// }
		
	const handleApp = params.handle + '/' + params.appId;

	return (

		<Switch>

			<Route exact path={'/' + handleApp} render={(route_props) =>
				<Redirect to={'/' + handleApp + '/login'} />
			} />
			<Route path={match.path + '/login/:base64?'} render={(route_props) =>
				<Login landing={'/'} />
			} />
			<Route path={match.path + '/logout'} render={(route_props) => 
				dispatch(actions_authentication.logout())
			} />
			<Route path={match.path + '/register_user/:first?/:last?/:email?'} render={(route_props) =>
				<Register landing={'/'} firebase={firebase} history={history} dispatch={dispatch} {...route_props} />
			} />
			<Route path={match.path + '/account_delete'} render={(route_props) =>
			<>
				{ window.flutter && <Navbar handleApp={handleApp} /> }
				<AccountDelete handle={params.handle} firebase={firebase} params={params} history={history} dispatch={dispatch} />
			</>
			} />
			<Route path={match.path + '/user_not_found/:email'} render={(route_props) =>
				<UserNotFound firebase={firebase} dispatch={dispatch} params={params} />
			} />
			<Route path={match.path + '/password_reset'} render={(route_props) =>
				<PasswordReset firebase={firebase} params={params} history={history} dispatch={dispatch} />
			} />
			<Route path={match.path + '/quickbooks'} render={(route_props) =>
				<QuickbooksConnectPopup firebase={firebase} {...route_props} />
			} />
			<Route path={match.path + '/signature/:item/:id/:option/:email/:validateSignature'} render={(route_props) =>
				<Signature firebase={firebase} {...route_props} />
			} />
			<Route path={match.path + '/quote_options/:quote_id/:email/:validateSignature'} render={(route_props) =>
				<QuoteOptions  {...route_props} />
			} />
			<Route path={match.path} render={(route_props) =>
				<>
					{ !hideMenu &&
						<SideMenu
							handleApp={handleApp}
							location={routeLocation}
							collapsed={settings.collapsed}
						/>
					}
					<Navbar handleApp={handleApp} hideMenu={hideMenu} />

					<div id="wrapper" style={{ height: (hideMenu) ? 'calc(100vh)' : 'calc(100vh - 53px)' }}>

						<div id="page-wrapper" className="gray-bg px-0" style={{ overflow: 'hidden', marginLeft: (hideMenu) ? '0' : '220px' }}>

							<div className="container-fluid">
								{!company || !users.users || users.user_permissions?.OWNER == undefined // only check for these.  Other tables, check on individual pages.
									? < div style={{ position: 'absolute', top: '50%', left: '50%' }}>
										<Spinner />
									</div>
									: <Switch>
										<Route path={match.path + '/home'} render={(route_props) =>
											<div className="row">
												<div className='col-12'>
													<CatchErrors className={'m-t-lg'}>
														<div className="middle-box text-center animated fadeInDown">
															<h1>HOME</h1>
															<h3 className="font-bold">You are Home</h3>
														</div>
													</CatchErrors>
												</div>
											</div>
										} />
										<Route path={match.path + '/customers'} render={(route_props) =>
											<Authorize perm={ PERMISSIONS.CUSTOMERS_VIEW } show403>
												<React.Suspense fallback={<Spinner style={{ marginTop: '20%' }} />}>
													<CatchErrors className={'m-t-lg'}>
														<Customers {...route_props} />
													</CatchErrors>
												</React.Suspense>
											</Authorize>
										} />
										<Route path={match.path + '/assets'} render={(route_props) =>
											<Authorize appId={ APPS.ASSETS.id } perm={ PERMISSIONS.ASSETS_VIEW } show403>
												<React.Suspense fallback={<Spinner style={{ marginTop: '20%' }} />}>
													<CatchErrors className={'m-t-lg'}>
														<Assets {...route_props} />
													</CatchErrors>
												</React.Suspense>
											</Authorize>
										} />
										<Route path={match.path + '/inventory'} render={(route_props) =>
											<Authorize perm={ PERMISSIONS.INVENTORY_VIEW } show403>
												<React.Suspense fallback={<Spinner style={{ marginTop: '20%' }} />}>
													<CatchErrors className={'m-t-lg'}>
														<StockParts {...route_props} />
													</CatchErrors>
												</React.Suspense>
											</Authorize>
										} />
										<Route path={match.path + '/vendors'} render={(route_props) =>
											<Authorize perm={ PERMISSIONS.VENDORS_VIEW } show403>
												<React.Suspense fallback={<Spinner style={{ marginTop: '20%' }} />}>
													<CatchErrors className={'m-t-lg'}>
														<Vendors {...route_props} />
													</CatchErrors>
												</React.Suspense>
											</Authorize>
										} />
										<Route path={match.path + '/employees'} render={(route_props) =>
											<Authorize perm={ PERMISSIONS.EMPLOYEES_VIEW } show403>
												<React.Suspense fallback={<Spinner style={{ marginTop: '20%' }} />}>
													<CatchErrors className={'m-t-lg'}>
														<Employees {...route_props} />
													</CatchErrors>
												</React.Suspense>
											</Authorize>
										} />
										<Route path={match.path + '/map'} render={(route_props) =>
											<Authorize perm={ PERMISSIONS.EMPLOYEES_VIEW } show403>
												<React.Suspense fallback={<Spinner style={{ marginTop: '20%' }} />}>
													<CatchErrors className={'m-t-lg'}>
														<ServiceMap {...route_props} />
													</CatchErrors>
												</React.Suspense>
											</Authorize>
										} />
										<Route path={match.path + '/schedule'} render={(route_props) =>
											<Authorize perm={ PERMISSIONS.SCHEDULE_VIEW } show403>
												<React.Suspense fallback={<Spinner style={{ marginTop: '20%' }} />}>
													<CatchErrors className={'m-t-lg'}>
														<Schedule {...route_props} />
													</CatchErrors>
												</React.Suspense>
											</Authorize>
										} />
										<Route path={match.path + '/archive'} render={(route_props) =>
											<Authorize perm={ PERMISSIONS.ARCHIVES_VIEW } show403>
												<div className="row">
													<CatchErrors className={'m-t-lg'}>
														<Archive {...route_props} />
													</CatchErrors>
												</div>
											</Authorize>
										} />
										<Route path={match.path + '/settings'} render={(route_props) =>
											<React.Suspense fallback={<Spinner style={{ marginTop: '20%' }} />}>
												<CatchErrors className={'m-t-lg'}>
													<Settings {...route_props} />
												</CatchErrors>
											</React.Suspense>
										} />
										<Route path={match.path} render={(route_props) =>
											<React.Suspense fallback={<Spinner style={{ marginTop: '20%' }} />}>
												<CatchErrors className={'m-t-lg'}>
													<Dashboard {...route_props} />
												</CatchErrors>
											</React.Suspense>
										} />
									</Switch>
								}
								{ !hideMenu &&
									<Footer hideMenu={ hideMenu } />
								}
							</div>
						</div>
					</div>
				</>
			} />
		</Switch>
	);
};

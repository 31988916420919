import * as actions_assets from 'actions/assets-actions';
import * as actions_inventory from 'actions/inventory-actions';
import * as actions_service_items from 'actions/service-items-actions';
import * as toolbox from 'components/common/toolbox';
import React, { useEffect, useRef, useState } from 'react';
import StockPartsForm from 'components/inventory/inventory-form/inventory-form';
import _ from 'lodash';
import moment from 'moment';
import { APPS, SERVICE_ITEMS } from 'components/constants';
import { Button, ButtonGroup } from 'react-bootstrap';
import { Table } from 'em-table';
import { confirm } from 'components/common/toolbox';
import { elasticSearch, Input, ModalForm, ModalAlert } from 'enspire-manager-framework';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams, useLocation } from 'react-router-dom';

const isDev = (process.env.REACT_APP_FIREBASE_ENV == 'development');

const LineItemsBuilder = (props) => {
    
    /* Hooks -----------------------------------------------------------------------------------------------------------------------*/
    
	const maxFilters = 6;
    const params = useParams();
    const history = useHistory();
    const tableRef = useRef();
    const dispatch = useDispatch();
	const routeLocation = useLocation();
    const seg = 14;

    const admin = useSelector((store) => store.admin);
    const asset = useSelector((store) => store.assets.assets)?.find((asset) => asset.id == params.asset_id);
    const assets = useSelector((store) => store.assets);
    const assetTypes = useSelector((store) => store.settings.settings_asset_types);
    const inventory = useSelector((store) => store.inventory);
	const inventoryIndex = useSelector((store) => store.settings.settings?.values?.inventoryIndex);
    const invoice = useSelector((store) => store.invoices.invoice);
    const partsCategories = useSelector((store) => store.settings.settings_parts_categories);
    const purchaseOrder = useSelector((store) => store.inventory.purchase_order);
    const recentItems = useSelector((store) => store.users.user_recent_items);
    const settings = useSelector((store) => store.settings.settings);
    const settings_work_orders = useSelector((store) => store.settings.settings_work_orders);
    const user = useSelector((store) => store.users.user);
    const workOrders = useSelector((store) => store.workOrders);

    const [category, setCategory] = useState(null);
    const [search, setSearch] = useState((admin?.service_item_search?.search) ? admin?.service_item_search?.search : '');
    const [typeId, setTypeId] = useState((admin?.service_item_search?.type) ? admin?.service_item_search?.type : SERVICE_ITEMS.LABOR.id);
    const [cartItems, setCartItems] = useState([]);
    const [changes, setChanges] = useState(false);
    const [test, setTest] = useState('0');
    const [state, setState] = useState({
        hits: [],
        search: '',

        lineItems: [],
        openIndex: null,
        container_height: 0,
        show_non_stock_form: false,
    });

    const assetType = _.find(assetTypes, { id: assets.selected_asset_type_id });
    const categoriesByType = (params.appId == APPS.SERVICE.id)
        ?	_.filter(partsCategories, { type: 'service' })
        :	_.filter(partsCategories, { type: 'stock' });

	const categoriesFiltered = (parseInt(assets.selected_asset_type_id))
		?	[ ..._.filter(categoriesByType, (o) => assetType?.categoryIds?.includes(o.id))]
		:	[ ...categoriesByType];
    const uncategorizedOption = (categoriesFiltered.length < maxFilters)
		?	{ name: <i title="Uncategorized" className="fas fa-ban"></i>, id: '-' }
		:	{ name: 'Uncategorized', id: '-' };

	const categoryFilters = [ ...categoriesFiltered, uncategorizedOption];
    const segments = routeLocation.pathname.split('/');
    const isAssets = (params.appId == APPS.ASSETS.id);
    const isPurchaseOrder = props.source == 'vendors';
    // const isWorkOrders = (props.source == 'work-orders');
    // const isQuotes = (props.source == 'quotes');
    // const isInvoice = props.source == 'invoice';
    // const isWorkOrder = props.source == 'work-order';

    /* Effects ---------------------------------------------------------------------------------------------------------------------*/

    useEffect(() => {
        if (typeId == SERVICE_ITEMS.LABOR.id) addLabor();
    }, []);
    
    useEffect(() => {
        setCategory(null);
        tableRef.current?.setFilterButton(null);
        tableRef.current?.stopNewItem();
        if (typeId != SERVICE_ITEMS.LABOR.id && typeId != SERVICE_ITEMS.TRAVEL.id) updateSearch(search, null);
    }, [typeId]);    
    
    useEffect(() => {
        setTimeout(() => {
            if (typeId != SERVICE_ITEMS.LABOR.id && typeId != SERVICE_ITEMS.TRAVEL.id) updateSearch(search, category);
		}, 500);
	}, [inventoryIndex, inventory]);
    
    // useEffect(() => {
    //     updateSearch(search, category);
    // }, [recentItems]);    
    
    /* Handlers ------------------------ --------------------------------------------------------------------------------------------*/

    const handleSearch = (search) => {
        setSearch(search);
        updateSearch(search, category);
        dispatch(actions_service_items.serviceItemsType(typeId, search));
    };
    const confirmTypeId = (typeId) => {
        if (cartItems.length && ![SERVICE_ITEMS.LABOR.id, SERVICE_ITEMS.TRAVEL.id].includes(cartItems[0].type)) {
            confirm(`Are you sure to want to discard your currently selected Items?`, () => handleSetTypeId(typeId));
        } else {
            handleSetTypeId(typeId);
        }
    }
    const handleSetTypeId = (typeId) => {
        setTypeId(typeId);
        dispatch(actions_service_items.serviceItemsType(typeId, search));
        if (typeId == SERVICE_ITEMS.LABOR.id) addLabor()
        else if (typeId == SERVICE_ITEMS.TRAVEL.id) addTravel()
        else setCartItems([]);
    }
    const handleFilter = (filter) => {
        updateSearch(search, filter);
        setCategory(filter);
    };
    const handleEditHit = (field, updatedItem, newValue) => {
        let newHits = state.hits.map((item, index) => {
            if (item.id == updatedItem.id) {
                if (!isNaN(parseFloat(newValue))) {
                    // add to Cart Items array
                    let newCartItems = _.filter(cartItems, (o) => o.id != item.id);
                    setCartItems([...newCartItems, ...(parseFloat(newValue)) 
                        ? [{ ...item, count: parseFloat(newValue), ...(!item.description) ? { description: '' } : {}, }] : []
                    ]);
                }
                return { ...item, [field]: newValue };
            } else {
                return item;
            }
        });
        setState(prev => ({ ...prev, hits: newHits }));
        setChanges(true);
    };

    /* Actions ---------------------------------------------------------------------------------------------------------------------*/

    const addLabor = () => {
        let newItem = {
            id: "labor",
            type: SERVICE_ITEMS.LABOR.id,
            name: 'Labor',
            description: (settings_work_orders?.labor_travel_timestamp) ? moment().format('ddd, MMM Do YYYY') : '',
            count: 1,
            price: settings.invoices?.defaultLaborRate ?? 0,
            calculatedPrice: (settings.invoices?.defaultLaborRate) ? parseFloat(settings.invoices?.defaultLaborRate) : 0,
        };
        setCartItems([newItem]); 
        setState(prev => ({ ...prev, hits: [newItem] }));
    };  
    const addTravel = () => {
        let newItem = {
            id: "travel",
            type: SERVICE_ITEMS.TRAVEL.id,
            name: 'Travel',
            description: (settings_work_orders?.labor_travel_timestamp) ? moment().format('ddd, MMM Do YYYY') : '',
            count: 1,
            taxable: false,
            price: settings.invoices?.defaultTravelRate ?? 0,
            calculatedPrice: (settings.invoices?.defaultTravelRate) ? parseFloat(settings.invoices?.defaultTravelRate) : 0,
        };    
        setCartItems([newItem]); 
        setState(prev => ({ ...prev, hits: [newItem] }));
    };  
    const addItems = () => {
        props.addItems(cartItems);
    }
    const openInventoryForm = (id) => {
        let location = toolbox.modifyPath(routeLocation?.pathname, seg, `add_parts${(id) ? `/${id}` : ''}`);
        history.push({ pathname: location });
    };
    // const linkToAsset = (id) => {
	// 	dispatch(actions_assets.saveStockPartToAsset(params.handle, params.asset_id, id, false));
    // }    
    const movePart = (itemId, typeId) => {
        dispatch(actions_inventory.saveInventory({
            handle: params.handle,
            inventoryItem: { type: typeId },
            itemId, 
            callback: (stockPartId) => {
                // let action = (parseInt(partId)) ? ACTION.MODIFIED.key : ACTION.CREATED.key;
                // dispatch(actions_admin.saveActivity(params.handle, LOG.STOCK_PART.key, action, stockPartId, state.stockPart.name, state.stockPart ));
            }
        }));
    };
    const updateSearch = async (search, category) => {

        if (typeId) {
            try {
                // const assetHasStockparts = type == SERVICE_ITEMS.STOCK_PARTS.id && !search && !category && asset?.parts?.length;
                // const isRecentlyUsed = type == SERVICE_ITEMS.NON_STOCK_PARTS.id && !search && !category && Object.keys(recentItems?.items?.recent ?? []).length;

                const indexHandle = 'mt-' + params.handle + ((isDev) ? '-dev' : '');
                let config = {
                    table: indexHandle + '-stock-parts',
                    fields: ['name', 'partNumber', 'manufacturer', 'description', 'type'],
                    filter: [
                        ...(parseInt(assets.selected_asset_type_id)) ? [{ field: 'assetTypeIds', value: assets.selected_asset_type_id }] : [],
                        { field: 'type', value: typeId },
                        { field: 'categoryIds', value: category },
                        { field: 'vendorIds', value: (isPurchaseOrder) ? params.profile_id : null },
                    ],
                };

                // Sort recentItems and trim list over 50
                // if (assetHasStockparts) config.ids = asset.parts;
                // if (isRecentlyUsed) {
                //     var recentIds = sortByField(Object.keys(recentItems.items.recent)?.map((key) => ({ id: key, date: recentItems.items.recent[key] })), 'date', true);
                //     if (recentIds.length > 50) {
                //         dispatch(actions_users.trimRecent(params.handle, recentIds, type, user.id));
                //     }
                //     recentIds = recentIds.slice(0, 50);
                //     config.ids = recentIds.map((o) => o.id);
                // }

                let hits = await elasticSearch(search, config);
                hits = hits?.map((hit) => {
                    let alteredItem = _.find(cartItems, { id: hit.id })
                    let count = (alteredItem) ? alteredItem.count : '0';

                    return ({ ...hit, 
                        count: count,
                        categoryList: hit?.categoryIds?.map((id) => ({ value: partsCategories.find((o) => o.id == id)?.name }))
                    })
                });

                // Mark existing items to shift to top
                setCartItems(cartItems.map((item) => ({ ...item, shifted: true })));


                // Add group headings
                // if (isPurchaseOrder) {
                //     hits = hits?.map((hit) => ({ ...hit, groupHeading: 'Stock Parts for this Vendor' }));
                // } else if (assetHasStockparts) {
                //     hits = hits?.map((hit) => ({ ...hit, groupHeading: 'linked to this Asset' }));
                // } else if (isRecentlyUsed) {
                //     hits = hits?.map((hit) => ({ ...hit, groupHeading: 'Recently Used Items' })).sort((a, b) => recentItems.items?.recent[b.id] - recentItems.items.recent[a.id]);
                // }

                setState(prev => ({ ...prev, hits, search }));
                return false; // prevent <Enter> key from reloading
            } catch (error) {
                window.toastr.error(error.message);
            }
        }
    };

    /* Table Data -------------------------------------------------------------------------------------------------------------------*/
    
    // Combine Hits with previous line items with count values
    var combined = [];
    cartItems.forEach((item) => {
        if (item.type == typeId && item.shifted) combined.push({ ...item, _accent: { backgroundColor: 'lightsteelblue' }});
    });;
    state.hits.forEach((hit) => {
        let existing = _.find(cartItems, (o) => o.id == hit.id && !o.shifted);
        combined.push({ ...hit,
            ...(existing) ? { _accent: { backgroundColor: 'lightsteelblue' }} : {}
        });
    });
    
    // Add data for second line
    const data = combined.map((hit) => {
        let price = (hit?.priceOverride) ? hit.priceOverride : (hit?.averageCost) ? hit.averageCost : 0;
        let inventory = hit.currentInventory ?? '' + ((parseInt(hit.pendingInventory)) ? ` (${hit.pendingInventory})` : '')
        let count = _.filter(props.lineItems, { id: hit.id })?.reduce((total, item) => total + parseInt(item.count), 0);
        let categories = hit?.categoryList?.map((item) => <span key={ item.value } className="badge mr-1">{ item.value }</span>) ?? [];
        if (!categories.length) categories = <span className="badge mr-1"><i title="Uncategorized" className="fas fa-ban" aria-hidden="true"></i></span>

        return ({ ...hit,
            number_inc: <Input name="count" noLabel={ true } type="number-inc" value={ hit.count }
                onChange={ (e, value) => {
                    let updatedValue = (value || value == 0) ? value : e.target.value;
                    handleEditHit('count', hit, updatedValue) 
                }}
            />,
            secondLine: <span>
                {categories}
                { inventory && <span className="badge badge-info ml-2">{ `Inv: ${inventory}` }</span> }
                <span className="badge badge-default ml-2">{ toolbox.formatNumber(price, 2, true) }</span>
                { !!count && <span className="badge badge-primary ml-2 float-right">{ `Total: ${count}` }</span> }
            </span>, // '$' + price],
            ...(asset?.parts?.includes(hit.id)) ? { _hide_actions: [1] } : {},
        })
    });
    
    /* Constants -------------------------------------------------------------------------------------------------------------------*/

    var filterButtons = [
        <Button key={SERVICE_ITEMS.LABOR.id} variant={(typeId == SERVICE_ITEMS.LABOR.id) ? 'info' : "default"} onClick={() => { confirmTypeId(SERVICE_ITEMS.LABOR.id);}}>Labor</Button>,
        <Button key={SERVICE_ITEMS.TRAVEL.id} variant={(typeId == SERVICE_ITEMS.TRAVEL.id) ? 'info' : "default"} onClick={() => { confirmTypeId(SERVICE_ITEMS.TRAVEL.id);}}>Travel</Button>,
        ...(isAssets)
        ? [<Button key={SERVICE_ITEMS.STOCK_PARTS.id} variant={(typeId == SERVICE_ITEMS.STOCK_PARTS.id) ? 'primary' : "default"} onClick={() => { confirmTypeId(SERVICE_ITEMS.STOCK_PARTS.id); }} >Stock Parts</Button>]
        : [<Button key={SERVICE_ITEMS.SERVICE_ITEMS.id} variant={(typeId == SERVICE_ITEMS.SERVICE_ITEMS.id) ? 'primary' : "default"} onClick={() => { confirmTypeId(SERVICE_ITEMS.SERVICE_ITEMS.id); }} >Service Items</Button>],
        <Button key={SERVICE_ITEMS.NON_STOCK_PARTS.id} variant={(typeId == SERVICE_ITEMS.NON_STOCK_PARTS.id) ? 'primary' : "default"} onClick={() => { confirmTypeId(SERVICE_ITEMS.NON_STOCK_PARTS.id); }} >Non-Stock Parts</Button>,
    ];

    var columns =[];

    if (typeId == SERVICE_ITEMS.LABOR.id) columns = [
        { name: 'Name', field: 'name', width: 60 },
        { name: 'Hours', field: 'count', width: 20, edit: { type: 'text', callback: handleEditHit }},
        { name: 'Rate', field: 'price', type: 'number', format: 'usd', text_align: 'right', width: 20, edit: { type: 'usd', inputMode: 'decimal', callback: handleEditHit }},
    ];

    if (typeId == SERVICE_ITEMS.TRAVEL.id) columns = [
        { name: 'Name', field: 'name', width: 60 },
        { name: 'Hours', field: 'count', width: 20, edit: { type: 'text', callback: handleEditHit }},
        { name: 'Rate', field: 'price', type: 'number', format: 'usd', text_align: 'right', width: 20, edit: { type: 'usd', inputMode: 'decimal', callback: handleEditHit }},
    ];

    if (typeId == SERVICE_ITEMS.SERVICE_ITEMS.id) columns = [
        { name: 'Service Item', field: 'name', width: 60 },
        { name: 'Count', field: 'number_inc', type: 'jsx', width: 40 },
        { name: 'SecondLine', field: 'secondLine', type: 'jsx' },
    ];

    if (typeId == SERVICE_ITEMS.STOCK_PARTS.id) columns = [
        { name: 'Stock Part', field: 'name', width: 60 },
        { name: 'Count', field: 'number_inc', type: 'jsx', width: 40 },
        { name: 'SecondLine', field: 'secondLine', type: 'jsx' },
    ];

    if (typeId == SERVICE_ITEMS.NON_STOCK_PARTS.id) columns = [
        { name: 'Name', field: 'name', width: 60 },
        { name: 'Count', field: 'number_inc', type: 'jsx', width: 40 },
        { name: '', field: 'id', type: 'actions', multiple: true, width: 5, button: {
            title: 'More Options',
            name: <span className="material-icons">more_vert</span>,
            className: 'p-0 text-muted',
            links: [
                { name: 'Edit Non-Stock part', callback: (item) => openInventoryForm(item.id)},
                ...(params.appId == APPS.SERVICE.id) ? [{ name: 'Move to Service Items', callback: (item) => movePart(item.id, SERVICE_ITEMS.SERVICE_ITEMS.id)}] : [],
                ...(params.appId == APPS.ASSETS.id) ? [{ name: 'Move to Stock Parts', callback: (item) => movePart(item.id, SERVICE_ITEMS.STOCK_PARTS.id)}] : [],
            ]
        }},
    ];

    /* Render ----------------------------------------------------------------------------------------------------------------------*/

    return (
        <>
            <ModalForm {...props}
                bodyClassName={'py-3'}
                history={history}
                modal_header={'Add Line Items'}
                no_fade={true}
                no_padding={ true }
                save_button_title={'Add Item(s)'}
                confirm_button_callback={ addItems }
                show_spinner={inventory.inventory_save_pending}
                size={'md'}
                style={1}
                visible={ segments[seg-2] != 'add_parts' && segments[seg-2] != 'add_item' }
            >
                <div className="col-12" style={{ height: '100%' }}>

                    { typeId != SERVICE_ITEMS.LABOR.id && typeId != SERVICE_ITEMS.TRAVEL.id &&
                        <button className={`btn btn-primary btn-sm float-right ml-2 mb-2`} onClick={ () => openInventoryForm(null) }>
                            { (typeId == SERVICE_ITEMS.SERVICE_ITEMS.id) && `+ ${SERVICE_ITEMS.SERVICE_ITEMS.singular}` }
                            { (typeId == SERVICE_ITEMS.STOCK_PARTS.id) && `+ ${SERVICE_ITEMS.STOCK_PARTS.singular}` }
                            { (typeId == SERVICE_ITEMS.NON_STOCK_PARTS.id) && `+ ${SERVICE_ITEMS.NON_STOCK_PARTS.singular}` }
                        </button>
                    }
                    <div style={{ clear: 'both' }}></div>
                    { !isPurchaseOrder &&
                        <div className="mb-3" style={{ padding: '12px 20px 5px', backgroundColor: '#eeeeee' }}>
                            <ButtonGroup size="sm" className={'mb-2 d-flex'} >
                                {filterButtons}
                            </ButtonGroup>
                        </div>
                    }

                    <Table id={'line-items-builder'}
                        ref={tableRef}
                        data={data}
                        show_limit={false}
                        show_search={true}
                        highlight_search={true}
                        active_id={params.purchase_order_id}
                        active_field={'id'}
                        search_query={state.search}
                        search_callback={handleSearch}
                        default_search={search}
                        second_line={'secondLine'}
                        save_new_callback={props.addItem}
                        columns={columns}
                        filters={((typeId == SERVICE_ITEMS.SERVICE_ITEMS.id || typeId == SERVICE_ITEMS.STOCK_PARTS.id) && !isPurchaseOrder) && {
                            name: 'Category',
                            field: 'categoryIds',
                            limit: 6,
                            buttons: categoryFilters?.map((category) => ({ name: category.name, value: category.id })) ?? [],
                            filter_callback: handleFilter
                        }}
                        group_by={{ fields: ['groupHeading'], direction: ['asc'] }}
                    />
                </div>
            </ModalForm>

            {/* Integrate Routes under this component -------------------------------------------------- */}

            { params.action == 'add_parts' &&
                <StockPartsForm addItems={ props.addItems } typeId={typeId} source="line-items" appId={ params.appId } />
            }
        </>
    
    );
};

export default LineItemsBuilder;

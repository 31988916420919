import * as actions_admin from 'actions/admin-actions';
import * as actions_profiles from 'actions/profiles-actions';
import * as actions_quickbooks from 'actions/quickbooks-actions';
import React, { useEffect, useState } from 'react';
import { FormBuilder, ModalForm, ValidateForm } from 'enspire-manager-framework';
import { LOG, ACTION, CUSTOMERS, PERMISSIONS } from 'components/constants';
import { confirmArchive } from 'components/common/toolbox';
import { customer_fields, residential_layout, commercial_layout } from './customer-form-layout';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

var _ = require('lodash');

export default function CustomerForm(props) {
	
	/* Hooks --------------------------------------------------------------------------------------------------------------------------*/
	
	const params = useParams();
	const history = useHistory();
	const dispatch = useDispatch();
	
	const admin = useSelector((store) => store.admin);
	const profiles = useSelector((store) => store.profiles);
	const quickbooksSettings = useSelector((store) => store.quickbooks.quickbooks);
	const user_permissions = useSelector(store => store.users?.user_permissions);
    const company = useSelector((store) => store.company.company);

	const [focusedInput, setFocused] = useState(null);
	const [formError, setFormError] = useState(null);
	const [original, setOriginal] = useState({});
	const [profile, setProfile] = useState(customer_fields(params.appId));
	const [copyDisplay, setCopyDisplay] = useState(false);
	const [contacts, setContacts] = useState(0);

	const customerType = _.find(Object.values(CUSTOMERS), { type: props.type });
	const customer_form_layout = (props.type == 'residential') ? residential_layout : commercial_layout;
	
	/* Effects ------------------------------------------------------------------------------------------------------------------------*/

	useEffect(() => {
		setProfile(customer_fields(params.appId, customerType?.id));
	}, []);

	useEffect(() => {
		if (parseInt(params.profile_form_id) && profiles?.profile) populateForm();
		if (props.displayName) {
			setProfile(prev => ({ ...prev, displayName: props.displayName }));
		}
		setCopyDisplay(profile.displayName == `${profile.firstName} ${profile.lastName}` || !profile.displayName);
	}, [params.profile_form_id, profiles?.profile]);

	// Set Display Name to firstName + lastName if not otherwise edited
	useEffect(() => {
		if ((profile?.firstName || profile?.lastName) && (copyDisplay || !profile.displayName)) setProfile(prev => ({ ...prev, displayName: `${profile?.firstName} ${profile?.lastName}` }));
	}, [profile.firstName, profile.lastName, copyDisplay]);

	/* Handlers -----------------------------------------------------------------------------------------------------------------------*/

	const handleChange = (e) => {
		let form_error = _.filter(formError, (o) => { return o.field !== e.target.name; });
		setFormError(form_error);
		setProfile(prev => ({ ...prev, [e.target.name]: e.target.value }));

		if (e.target.name == 'displayName') setCopyDisplay(false);
	};
	const handleCheckbox = (field) => {
		setProfile(prev => ({ ...prev, [field]: !profile[field] }));
	};
	const handleAutocomplete = (place) => {
		const number = place.address_components.find((component) => component.types.includes('street_number'))?.long_name;
		const route = place.address_components.find((component) => component.types.includes('route'))?.long_name;
		const address = ((number) ? number : '') + ' ' + ((route) ? route : '');
		const city = place.address_components.find((component) => component.types.includes('locality'))?.long_name;
		const stateLocation = place.address_components.find((component) => component.types.includes('administrative_area_level_1'))?.short_name;
		const zip = place.address_components.find((component) => component.types.includes('postal_code'))?.long_name;

		setProfile(prev => ({ ...prev, addressLine1: address, city, state: stateLocation, zip }));
	};
	const handleContacts = (type) => {
		if (type == 'add') setContacts(contacts + 1);
		if (type == 'remove' && !!contacts) setContacts(contacts - 1);
	}
	const populateForm = async () => {
		let customer = {
			...profile,
			...profiles.profile,
		};

		if (profiles.profile) {
			setProfile(customer);
			setOriginal(customer);
		}
	};

	/* Actions ------------------------------------------------------------------------------------------------------------------------*/

	const archiveCustomer = () => {
		dispatch(actions_profiles.deleteProfile(params.handle, params.profile_form_id, 'CUSTOMER', () => {
			dispatch(actions_admin.saveActivity(params.handle, LOG.CUSTOMER.key, ACTION.ARCHIVED.key, params.profile_form_id, profile.displayName ));
			dispatch(actions_quickbooks.updateQuickbooks({ handle: params.handle, type: 'customer' }, quickbooksSettings));
			history.replace(`/${params.handle}/${params.appId}/customers`);
		}));
	};
	const submitForm = () => {
		var form_error = ValidateForm(profile, form_layout);
		setFormError(form_error);

		if (!form_error?.length) {
		
			dispatch(actions_profiles.saveProfile({
				handle: params.handle, 
				profileId: params.profile_form_id, 
				profileForm: profile,
				profileType: 'CUSTOMER',
				callback: (profileId) => {
					let action = (parseInt(params.profile_form_id)) ? ACTION.MODIFIED.key : ACTION.CREATED.key;
					dispatch(actions_admin.saveActivity(params.handle, LOG.CUSTOMER.key, action, profileId, profile.displayName, profile, original ));
					dispatch(actions_quickbooks.updateQuickbooks({ handle: params.handle, type: 'customer' }, quickbooksSettings));

					if (props.source == 'service_request') {
						history.goBack();
						setTimeout(() => {
							history.replace(`/${params.handle}/${params.appId}/customers/${profileId}/request-form/0/tab`);
						}, 500);
					} else if (profileId != profile.id) {
						history.replace(`/${params.handle}/${params.appId}/customers/${profileId}/form/0/tab`);
					} else {
						history.goBack();
					}
				}
			}));
		}
	};

	/* Constants ----------------------------------------------------------------------------------------------------------------------*/

	var existing = (parseInt(params.profile_form_id) > 0);
	var form_layout = customer_form_layout(existing, company, contacts, handleContacts);

	/* Render -------------------------------------------------------------------------------------------------------------------------*/

	return (

		<ModalForm {...props}
			history={history}
			modal_header={`Customer Details`}
			cancel_button_title="Cancel"
			save_button_title={'Save Details'}
			submitFormHandler={submitForm}
			delete_button_title={'Archive'}
			delete_button_callback={(!existing) ? null : () => confirmArchive('Customer', archiveCustomer)}
			delete_button_disabled={ !user_permissions[PERMISSIONS.CUSTOMERS_DELETE.key] }
			show_spinner={profiles?.profile_save_pending}
			focusedInput={(window.flutter) ? focusedInput : null}
			platform={admin.flutter_platform}
			keyboardPadding={admin.flutter_keyboard}
		>
			{profile &&
				<FormBuilder
					callbacks={{
						focus: (event) => setFocused(event.target),
						text: handleChange,
						select: handleChange,
						checkbox: handleCheckbox,
						autocomplete: handleAutocomplete,
					}}
					form_error={formError}
					form={form_layout}
					record={profile}
				/>
			}
		</ModalForm>
	);
}
import React from 'react';
import ResultsMyWorkOrders from 'components/dashboard/components/results-my-work-orders';
import _ from 'lodash';
import { Expanding } from 'components/common/expanding';
import { WORKORDERS } from 'components/constants';
import { WidgetTask } from 'components/dashboard/components/widget-task';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

export default function MyTasks(props) {

	const params = useParams();

    const counts = useSelector((store) => store.admin.dashboard_counts);
    const workOrders = useSelector((store) => store.workOrders.work_orders_technician);
	const workOrdersActive = _.filter(workOrders, (workOrder) => [WORKORDERS.PAUSED.id, WORKORDERS.ONSITE.id, WORKORDERS.ENROUTE.id, WORKORDERS.INPROGRESS.id].includes(workOrder.statusId));
	const workOrdersIncomplete = _.filter(workOrders, (workOrder) => [WORKORDERS.INCOMPLETE.id].includes(workOrder.statusId));
	const workOrdersAssigned = _.filter(workOrders, (workOrder) => [WORKORDERS.ASSIGNED.id].includes(workOrder.statusId));

    return (
		<div className="row mb-2 no-gutters" >

			{/* TASKS ------------------------------------------------------------------------------------- */}

			<div className="col-4 p-1">
				<WidgetTask 
					active={ params.widget_id == 'my-workorders-assigned' }
					backgroundColor={ 'gainsboro' } 
					badge={ workOrdersAssigned?.length }
					icon={ <i className="fa-duotone fa-solid fa-file-circle-check fa-3x"></i> }
					text={ 'Assigned Work Orders' }
					value="my-workorders-assigned"
					onClick={ props.handleClick }
				/>
			</div>
			<div className="col-4 p-1">
				<WidgetTask 
					active={ params.widget_id == 'my-workorders' }
					backgroundColor={ 'powderblue' } 
					badge={ workOrdersActive?.length }
					icon={ <i className="fa-duotone fa-solid fa-file-circle-exclamation fa-3x"></i> }
					text={ 'Active Work Orders' }
					value="my-workorders"
					onClick={ props.handleClick }
				/>
			</div>
			<div className="col-4 p-1">
				<WidgetTask 
					active={ params.widget_id == 'my-workorders-incomplete' }
					backgroundColor={ 'palegoldenrod' } 
					badge={ workOrdersIncomplete?.length }
					icon={ <i className="fa-duotone fa-solid fa-file-circle-xmark fa-3x"></i> }
					text={ 'Incomplete Work Orders' }
					value="my-workorders-incomplete"
					onClick={ props.handleClick }
				/>
			</div>

			{/* RESULTS TABLES ---------------------------------------------------------------------------- */}

			<Expanding open={ params.widget_id == 'my-workorders-assigned' }>
				<ResultsMyWorkOrders source="dashboard" type="assigned" />
			</Expanding>
			<Expanding open={ params.widget_id == 'my-workorders' }>
				<ResultsMyWorkOrders source="dashboard" type="active" />
			</Expanding>
			<Expanding open={ params.widget_id == 'my-workorders-incomplete' }>
				<ResultsMyWorkOrders source="dashboard" type="incomplete" />
			</Expanding>

		</div>
	);
};

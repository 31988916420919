import * as toolbox from 'components/common/toolbox';
import * as types from './action-types';
import dayjs from 'dayjs';
import firebase from 'firebase/compat/app';
import { SCHEDULE_EVENT_TYPE } from 'components/constants';

const firestore = firebase.firestore();
const objectSupport = require("dayjs/plugin/objectSupport");
const isBetween = require("dayjs/plugin/isBetween");
dayjs.extend(objectSupport);
dayjs.extend(isBetween);

/*-----------------------------------------------*/
/*  SCHEDULE
/*-----------------------------------------------*/

export function reserveTimeline(handle, userId, new_service_dragging, type, callback) {

	return async dispatch => {

		if (type == SCHEDULE_EVENT_TYPE.RESERVED_TIMELINE.id) dispatch({ type: types.SERVICE_REQUEST + '_SAVE_PENDING' });
		else dispatch({ type: types['REQUEST_OFF'] + '_SAVE_PENDING' });

		const batch = firestore.batch();

		var workOrder = {};
		var workOrderId = await nextWorkOrderId(handle, batch);

		workOrder.startDate = dayjs.unix(new_service_dragging.startDate.seconds).toDate();
		workOrder.endDate = dayjs.unix(new_service_dragging.endDate.seconds).toDate();
		workOrder.technicianId = new_service_dragging.technicianId;
		if (type == SCHEDULE_EVENT_TYPE.RESERVED_TIMELINE.id) workOrder.holdingId = userId;
		workOrder.type = type;
		workOrder.deleted = false;
		workOrder.created = new Date();
		workOrder.modified = new Date();
		workOrder.dates = scheduleDateArray(dayjs.unix(new_service_dragging.startDate.seconds), dayjs.unix(new_service_dragging.endDate.seconds));

		if (type == SCHEDULE_EVENT_TYPE.RESERVED_TIMELINE.id) {
			const existingFound = await firestore.collection(handle + '/work-orders/work-orders').where('holdingId', '==', userId).get();
			if (existingFound.docs?.[0]) batch.delete(firestore.collection(handle + '/work-orders/work-orders').doc(existingFound.docs[0].id));
		}

		batch.set(firestore.collection(handle + '/work-orders/work-orders').doc(workOrderId), { ...workOrder });

		batch.commit().then(() => {
			if (type == SCHEDULE_EVENT_TYPE.RESERVED_TIMELINE.id) dispatch({ type: types.SERVICE_REQUEST + '_FULFILLED' });
			else dispatch({ type: types['REQUEST_OFF'] + '_FULFILLED' });
			window.toastr.success('The Reserved Timeline has been successfully saved/updated', 'Reserve Timeline!');
			if (typeof callback === 'function') callback(workOrderId);

		}).catch((error) => {
			toolbox.process_error(error, 'Record NOT Saved!');
		});
	};
}
export function deleteReserveTimeline(handle, workOrderId, callback) {

	return async dispatch => {

		dispatch({ type: types.SERVICE_REQUEST + '_SAVE_PENDING' });

		firestore.collection(handle + '/work-orders/work-orders').doc(workOrderId).delete().then(() => {
			dispatch({ type: types.SERVICE_REQUEST + '_FULFILLED' });
			window.toastr.success('The Reserved Timeline has been successfully deleted', 'Delete Reserve Timeline!');
			if (typeof callback === 'function') callback();
		}).catch((error) => {
			toolbox.process_error(error, 'Record NOT Deleted!');
		});
	};
}

export function draggingStart() {

	return dispatch => {
		dispatch({ type: types['WORK_ORDER_DRAG_START'] });
	};
}
export function draggingEnd() {

	return dispatch => {
		dispatch({ type: types['WORK_ORDER_DRAG_END'] });
	};
}
export function newEvent(technicianId, startDate) {

	return dispatch => {
		dispatch({ type: types['NEW_WORK_ORDER'] + '_PENDING', data: { technicianId, startDate, endDate: startDate?.clone().add('2', 'hours') } });

		setTimeout(() => {
			dispatch({ type: types['NEW_WORK_ORDER'] + '_FULFILLED' });
		}, 1000);
	};
}
export function cancelNewEvent() {

	return dispatch => {
		dispatch({ type: types['NEW_WORK_ORDER'] + '_CANCEL' });
	};
}
export function workOrderPopup() {

	return dispatch => {
		dispatch({ type: types['WORK_ORDER_POPUP'] + '_PENDING' });

		setTimeout(() => {
			dispatch({ type: types['WORK_ORDER_POPUP'] + '_FULFILLED' });
		}, 1000);
	};
}
export function newDraggingStart(technicianId, startDate) {

	return dispatch => {
		dispatch({ type: types['NEW_WORK_ORDER_DRAG_START'], data: { technicianId, startDate } });
	};
}
export function newDraggingMove(endDate) {

	return dispatch => {
		dispatch({ type: types['NEW_WORK_ORDER_DRAG_MOVE'], data: { endDate } });
	};
}
export function newDraggingEnd(endDate) {

	return dispatch => {
		dispatch({ type: types['NEW_WORK_ORDER_DRAG_END'], data: { endDate } });
	};
}

/*-----------------------------------------------*/
/*  FUNCTIONS
/*-----------------------------------------------*/

async function nextWorkOrderId(handle, batch) {
	const table = 'work-orders';
	const field = 'nextWorkOrderId';
	const startingId = 1000;

	return toolbox.nextId(handle, batch, table, field, startingId);
}
function scheduleDateArray(starting, ending) {

	// DATE ARRAY
	var low = starting.startOf('day');
	var high = ending.endOf('day');
	var current = low.clone().hour(12);

	var i = 0;
	var dates = [];
	while (dayjs(current).isBetween(low, high) && i < 90) {
		dates.push(current.format('MMDDYY'));
		current = current.add(1, 'day');
		i++;
	}
	return dates;
}


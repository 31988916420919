import * as actions_admin from 'actions/admin-actions';
import * as actions_quotes from 'actions/quotes-actions';
import * as toolbox from 'components/common/toolbox';
import React, { useEffect, useState } from 'react';
import { FormBuilder, ValidateForm, ModalAlert, ModalForm } from 'enspire-manager-framework';
import { ACTION, LOG, ROLES } from 'components/constants';
import { confirmArchive } from 'components/common/toolbox';
import { quote_fields, quote_form_layout } from './quote-form-layout';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom/cjs/react-router-dom.min';

const moment = require('moment');
var _ = require('lodash');

const QuoteForm = (props) => {

    /* Hooks ------------------------------------------------------------------------------------------------------------------------------------*/

    const history = useHistory();
    const params = useParams();
    const dispatch = useDispatch();
    const routeLocation = useLocation();

	const settings = useSelector((store) => store.settings.settings);
	const employees = useSelector((store) => store.employees.employees);
    const quotes = useSelector((store) => store.quotes);
    const quote = useSelector((store) => store.quotes.quote);
    const serviceRequest = useSelector((store) => store.serviceRequests.service_request);
    const user = useSelector((store) => store.users.user);
    const user_permissions = useSelector(store => store.users?.user_permissions);

	const [original, setOriginal] = useState({});
    const [state, setState] = useState({
        form_error: null,
        quote: quote_fields(),
    });

    /* Effects ------------------------------------------------------------------------------------------------------------------------------------*/

    useEffect(() => {
        populateForm();
    }, [quote]);

    /* Handlers ------------------------------------------------------------------------------------------------------------------------------------*/

    const handleDate = (field, date) => {
        var form_error = _.filter(state.form_error, (o) => { return o.field !== field; });
        var quote = { ...state.quote, [field]: date };
        setState(prev => ({ ...prev, quote, form_error }));
    };
    const handleChange = (event) => {
        let form_error = _.filter(state.form_error, (o) => { return o.field !== event.target.name; });
        setState(prev => ({ ...prev, form_error, quote: { ...prev.quote, [event.target.name]: event.target.value } }));
    };

    /* Actions ------------------------------------------------------------------------------------------------------------------------------------*/

    const archiveQuote = () => {
        dispatch(actions_quotes.deleteQuote(params.handle, params.quote_id, () => {
			dispatch(actions_admin.saveActivity(params.handle, LOG.QUOTE.key, ACTION.ARCHIVED.key, params.quote_id, quote.description ));
            history.go(-2);
        }));
    };
    const populateForm = () => {
        if (parseInt(params.quote_id)) {
            let newQuote = { ...quotes.quote,
                effective: (quotes.quote?.effective) ? moment(quotes.quote.effective.seconds, 'X').toDate() : null,
            };
            setState(prev => ({ ...prev, quote: newQuote}));
            setOriginal(newQuote);
        } else {
            setState(prev => ({ ...prev, quote: { ...state.quote,
                effective: moment().toDate(),
                days_to_expiration: settings?.invoices?.quote_days_to_expiration ?? 30,
                description: serviceRequest?.description,
                estimatorId: user.profileId,
            }}));
        }
    };

    const submitForm = (quote_form_layout) => {
        var form_error = ValidateForm(state.quote, quote_form_layout);
        setState(prev => ({ ...prev, form_error }));

        // ----------------------------------------------------------
        // if new, saveNewQuote - send SR for customer and SR Id
        // ----------------------------------------------------------

        if (!form_error.length) {
            if (!parseInt(params.quote_id)) {
                dispatch(actions_quotes.saveNewQuote(params.handle, state.quote, serviceRequest, settings, user, (quoteId) => {
					dispatch(actions_admin.saveActivity(params.handle, LOG.QUOTE.key, ACTION.CREATED.key, quoteId, state.quote.description, state.quote ));
                    history.goBack();
                    setTimeout(() => {
                        let location = toolbox.modifyPath(routeLocation.pathname, 5, `form/0`);
                        location = toolbox.modifyPath(location, 10, `${quoteId}/items`);
                        history.push({ pathname: location });
                    }, 250);
                }));
            } else {
                dispatch(actions_quotes.updateQuote(params.handle, params.quote_id, state.quote, () => {
					dispatch(actions_admin.saveActivity(params.handle, LOG.QUOTE.key, ACTION.MODIFIED.key, quote.id, state.quote.description, state.quote, original ));
                    history.goBack();
                }));
            }
        }
    };

    /* Constants ------------------------------------------------------------------------------------------------------------------------------------*/

    var estimators = _.filter(employees, (o) => { return o.roles?.includes(ROLES.LEAD.id) || o.roles?.includes(ROLES.ADMINISTRATOR.id) });
    var quote_layout = quote_form_layout(estimators, user_permissions);
	var isNew = (parseInt(params.quote_id)) ? false : true;

    /* Render ------------------------------------------------------------------------------------------------------------------------------------*/
  
    return (

        <ModalForm {...props}
            history={history}
            modal_header={'Quote'}
            cancel_button_title="Cancel"
            save_button_title={'SAVE CHANGES'}
            submitFormHandler={() => submitForm(quote_layout)}
			delete_button_title={'Archive'}
			delete_button_disabled={ !user_permissions.QUOTES_DELETE }
            delete_button_callback={(isNew) ? null : () => confirmArchive('Quote', archiveQuote)}
            show_spinner={quotes.quote_save_pending}
        >
            <FormBuilder
                callbacks={{
                    text: handleChange,
                    select: handleChange,
                    date: handleDate,
                }}
                form_error={state.form_error}
                form={quote_layout}
                record={state.quote}
            />
        </ModalForm>
    );
};

export default QuoteForm;

import React from 'react';
import ResultsInvoices from '../components/results-invoices';
import { APPS, COUNTS } from 'components/constants';
import { Expanding } from 'components/common/expanding';
import { Widget } from 'components/dashboard/components/widget';
import { WidgetTask } from 'components/dashboard/components/widget-task';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

export default function QuotesTasks(props) {

	const params = useParams();
    const counts = useSelector((store) => store.admin.dashboard_counts);

    return (
		<div className="row mb-2 no-gutters" >

			{/* TASKS ------------------------------------------------------------------------------------- */}

			<div className="col-3 p-1">
				<WidgetTask 
					active={ params.widget_id == 'invoice-drafts' }
					badge={ (params.appId == APPS.ASSETS.id) ? counts?.[COUNTS.ASSETS_INVOICES_DRAFTS.id] ?? 0 : counts?.[COUNTS.SERVICE_INVOICES_DRAFTS.id] ?? 0 }
					icon={ <i className="fa-duotone fa-solid fa-file-pen fa-3x"></i> }
					text={ 'Invoice Drafts' }
					value="invoice-drafts"
					onClick={ props.handleClick }
				/>
			</div>
			<div className="col-3 p-1">
				<WidgetTask 
					active={ params.widget_id == 'invoice-pending' }
					badge={ (params.appId == APPS.ASSETS.id) ? counts?.[COUNTS.ASSETS_INVOICES_PENDING.id] ?? 0 : counts?.[COUNTS.SERVICE_INVOICES_PENDING.id] ?? 0 }
					icon={ <i className="fa-duotone fa-solid fa-file-circle-question fa-3x"></i> }
					text={ 'Pending Invoices' }
					value="invoice-pending"
					onClick={ props.handleClick }
				/>
			</div>
			<div className="col-3 p-1">
				<WidgetTask 
					active={ params.widget_id == 'invoice-partial' }
					badge={ (params.appId == APPS.ASSETS.id) ? counts?.[COUNTS.ASSETS_INVOICES_PARTIAL.id] ?? 0 : counts?.[COUNTS.SERVICE_INVOICES_PARTIAL.id] ?? 0 }
					icon={ <i className="fa-duotone fa-solid fa-file-circle-minus fa-3x"></i> }
					text={ 'Partial Payment' }
					value="invoice-partial"
					onClick={ props.handleClick }
				/>
			</div>
			<div className="col-3 p-1">
				<WidgetTask 
					active={ params.widget_id == 'invoice-overdue' }
					badge={ (params.appId == APPS.ASSETS.id) ? counts?.[COUNTS.ASSETS_INVOICES_OVERDUE.id] ?? 0 : counts?.[COUNTS.SERVICE_INVOICES_OVERDUE.id] ?? 0 }
					icon={ <i className="fa-duotone fa-solid fa-file-circle-exclamation fa-3x"></i> }
					text={ 'Overdue Invoices' }
					value="invoice-overdue"
					onClick={ props.handleClick }
				/>
			</div>

			{/* RESULTS TABLES ---------------------------------------------------------------------------- */}

			<Expanding open={ params.widget_id == 'invoice-drafts' }>
				<ResultsInvoices />
			</Expanding>
			<Expanding open={ params.widget_id == 'invoice-pending' }>
				<ResultsInvoices />
			</Expanding>
			<Expanding open={ params.widget_id == 'invoice-partial' }>
				<ResultsInvoices />
			</Expanding>
			<Expanding open={ params.widget_id == 'invoice-overdue' }>
				<ResultsInvoices />
			</Expanding>
		</div>
	);
};

import * as toolbox from 'components/common/toolbox';
import * as types from './action-types';
import dayjs from 'dayjs';
import firebase from 'firebase/compat/app';
import { APPS, CUSTOMERS, SERVICE_ITEMS, SCHEDULE_EVENT_TYPE, WORKORDERS } from 'components/constants';

const firestore = firebase.firestore();
const _ = require('lodash');
var moment = require('moment');
const duration = require("dayjs/plugin/duration");
const timezone = require("dayjs/plugin/timezone"); // dependent on utc plugin
const utc = require("dayjs/plugin/utc");
dayjs.extend(duration);
dayjs.extend(timezone);
dayjs.extend(utc);

/*-----------------------------------------------*/
/*  WORK ORDERS
/*-----------------------------------------------*/

export function subWorkOrderById(handle, id) {

	return dispatch => {

		dispatch({ type: types.WORK_ORDER + '_PENDING' });

		var unsubscribe = firestore.collection(handle + '/work-orders/work-orders').doc(id).onSnapshot((doc) => {
			var order = { ...doc.data(), id: doc.id };

			dispatch({ type: types.WORK_ORDER + '_FULFILLED', data: order, unsubscribe });
		});
	};
}
export function subWorkOrdersByRequestId(handle, requestId) {

	return dispatch => {

		dispatch({ type: types.WORK_ORDERS + '_CLEAR' });
		dispatch({ type: types.WORK_ORDERS + '_PENDING' });

		var unsubscribe = firestore.collection(handle + '/work-orders/work-orders')
			.where('serviceRequestId', '==', requestId)
			.where('deleted', '==', false).onSnapshot((querySnapshot) => {

				var orders = [];
				querySnapshot.forEach((doc) => {
					orders.push({ ...doc.data(), id: doc.id, });
				});
				orders = _.orderBy(orders, ['modified'], ['desc']);

				dispatch({ type: types.WORK_ORDERS + '_FULFILLED', data: orders, unsubscribe });
			});
	};
}
export function subWorkOrdersByDate(handle, appId, date) {

	return dispatch => {

		dispatch({ type: types.WORK_ORDERS + '_CLEAR' });
		dispatch({ type: types.WORK_ORDERS + '_PENDING' });

		let type = _.find(Object.values(APPS), { id: appId }).requestType;

		var unsubscribe = firestore.collection(handle + '/work-orders/work-orders').where('dates', 'array-contains', date.format('MMDDYY'))
		.where('type', 'in', [ type, 
			SCHEDULE_EVENT_TYPE.RESERVED_TIMELINE.id,
			SCHEDULE_EVENT_TYPE.REQUESTED_TIME_OFF.id,
			SCHEDULE_EVENT_TYPE.APPROVED_TIME_OFF.id,
			SCHEDULE_EVENT_TYPE.INTERNAL_WORKORDER.id,
		]).onSnapshot((querySnapshot) => {
			var orders = [];
			querySnapshot.forEach((doc) => {
				if (!doc.data().deleted) orders.push({ ...doc.data(), id: doc.id });
			});

			dispatch({ type: types.WORK_ORDERS + '_FULFILLED', data: orders, unsubscribe });
		});
	};
}
export function subDashboardWorkOrders(handle, appId, widget_id) {

	return dispatch => {

		dispatch({ type: types.WORK_ORDERS_DASHBOARD + '_CLEAR' });
		dispatch({ type: types.WORK_ORDERS_DASHBOARD + '_PENDING' });

		let type = _.find(Object.values(APPS), { id: appId }).requestType;

		var query = firestore.collection(handle + '/work-orders/work-orders').where('type', '==', type).where('deleted', '==', false);

		if (widget_id == 'unassigned-wo') query = query.where('statusId', '==', WORKORDERS.UNASSIGNED.id);
		if (widget_id == 'incomplete-wo') query = query.where('statusId', '==', WORKORDERS.INCOMPLETE.id);
		if (widget_id == 'inprogress-wo') query = query.where('statusId', 'in', [
			WORKORDERS.PAUSED.id, 
			WORKORDERS.ASSIGNED.id, 
			WORKORDERS.ENROUTE.id,
			WORKORDERS.ONSITE.id,
			WORKORDERS.INPROGRESS.id,
		]);

		var unsubscribe = query.onSnapshot((querySnapshot) => {

			var orders = [];
			querySnapshot.forEach((doc) => {
				if (!doc.data().deleted) orders.push({ ...doc.data(), id: doc.id });
			});

			dispatch({ type: types.WORK_ORDERS_DASHBOARD + '_FULFILLED', data: orders, unsubscribe });
		});
	};
}
export function clearDashboardWorkOrders() {

	return dispatch => {
		dispatch({ type: types.WORK_ORDERS_DASHBOARD + '_CLEAR' });
	};
}
export function subOpenWorkOrders(handle, appId) {

	return async dispatch => {

		dispatch({ type: types.WORK_ORDERS_OPEN + '_PENDING' });

		let type = _.find(Object.values(APPS), { id: appId }).requestType;

		var unsubscribe = firestore.collection(handle + '/work-orders/work-orders').where('deleted', '==', false).where('statusId', 'in', [
			WORKORDERS.UNASSIGNED.id,
			WORKORDERS.ASSIGNED.id, 
			WORKORDERS.PAUSED.id,
			WORKORDERS.ENROUTE.id,
			WORKORDERS.ONSITE.id,
			WORKORDERS.INPROGRESS.id,
			WORKORDERS.INCOMPLETE.id
		]).where('type', '==', type).onSnapshot((querySnapshot) => {
			var orders = [];
			querySnapshot.forEach((doc) => {
				orders.push({ ...doc.data(), id: doc.id });
			});
			// var sorted_orders = _.orderBy(orders, ['endDate'], ['desc']);

			dispatch({ type: types.WORK_ORDERS_OPEN + '_FULFILLED', data: orders, unsubscribe });
		});
	};
}
export function subTechnicianWorkOrders(handle, employeeId, appId) {

	return async dispatch => {

		if (employeeId) {
			
			let type = _.find(Object.values(APPS), { id: appId })?.requestType;
			
			var unsubscribe = firestore.collection(handle + '/work-orders/work-orders')
			.where('deleted', '==', false).where('technicianId', '==', employeeId).where('statusId', 'in', [ // not-in subscription not updating
				WORKORDERS.ASSIGNED.id, 
				WORKORDERS.PAUSED.id,
				WORKORDERS.ENROUTE.id,
				WORKORDERS.ONSITE.id,
				WORKORDERS.INPROGRESS.id,
				WORKORDERS.INCOMPLETE.id,
				WORKORDERS.COMPLETE.id,
				WORKORDERS.INVOICED.id,
			]).where('type', '==', type).onSnapshot((querySnapshot) => {
				dispatch({ type: types.WORK_ORDERS_TECHNICIAN + '_PENDING' });

				var orders = [];
				querySnapshot.forEach((doc) => {
					orders.push({ ...doc.data(), id: doc.id });
				});

				dispatch({ type: types.WORK_ORDERS_TECHNICIAN + '_FULFILLED', data: orders, unsubscribe });
			});
		}
	};
}
export function subIncompleteWorkOrders(handle, date, limit) {

	if (!limit) limit = 100;

	return async dispatch => {

		dispatch({ type: types.WORK_ORDERS_INCOMPLETE + '_PENDING' });

		firestore.collection(handle + '/work-orders/work-orders').where('completed', '==', false).where('endDate', '<', date.toDate()).where('statusId', 'in', ['0', '1', '2', '3', '5']).orderBy('endDate', 'desc').limit(limit).get().then((querySnapshot) => {
			var orders = [];
			querySnapshot.forEach((doc) => {
				orders.push({ ...doc.data(), description: '<strong>' + doc.data()['_displayName'] + '</strong><br/>' + doc.data()['description'], id: doc.id });
			});
			var sorted_orders = _.orderBy(orders, ['endDate'], ['desc']);


			dispatch({ type: types.WORK_ORDERS_INCOMPLETE + '_FULFILLED', data: sorted_orders, unsubscribe: null });
		});
	};
}
export function getTopLevelWorkOrder(handle, request_id, callback) {

	return async dispatch => {

		if (request_id) {
			const workOrdersSnapshot = await firestore.collection(handle + '/work-orders/work-orders').where('serviceRequestId', '==', request_id).get();
			var workOrder = null;
			workOrdersSnapshot.forEach((doc) => {
				// check for absence of "-"
				if (doc.data()['customId'].indexOf("-") === -1) workOrder = { ...doc.data(), id: doc.id };
			});

			if (typeof callback === 'function') callback(workOrder);
		}
	};
}
export function saveNewWorkOrder(handle, serviceRequestId, workOrder, user, callback, { lineItems } = {}) {

	return async dispatch => {

		dispatch({ type: types.WORK_ORDER + '_SAVE_PENDING' });

		const batch = firestore.batch();
		const workOrderId = await nextWorkOrderId(handle, batch);

		const requestDoc = await firestore.collection(handle + '/service-requests/service-requests/').doc(serviceRequestId).get();
		const request = { ...requestDoc.data(), id: requestDoc.id };

		const workOrdersSnapshot = await firestore.collection(handle + '/work-orders/work-orders').where('serviceRequestId', '==', serviceRequestId).get();
		var workOrderIndex = 0;
		workOrdersSnapshot.forEach((doc) => {
			workOrderIndex++;
		});

		if (request._displayName) workOrder._displayName = request._displayName;
		if (request._imageUrl) workOrder._imageUrl = request._imageUrl;
		if (request._name) workOrder._name = request._name;
		if (request._unitNumber) workOrder._unitNumber = request._unitNumber;
		if (request._address) workOrder._address = request._address;
		if (request.type == APPS.ASSETS.requestType) workOrder.assetId = request.assetId;
		if (request.type == APPS.SERVICE.requestType) workOrder.customerId = request.customerId;
		workOrder.serviceRequestId = serviceRequestId;
		workOrder.customId = (workOrderIndex) ? request.customId + '-' + workOrderIndex : request.customId;
		workOrder.technicianId = (workOrder.technicianId) ? workOrder.technicianId : 'unassigned';
		workOrder.statusId = (workOrder.technicianId != 'unassigned') ? '1' : '0';
		workOrder.type = request.type;
		workOrder.deleted = false;
		workOrder.created = new Date();
		workOrder.modified = new Date();
		workOrder.ownerId = user.contact?.email;
		workOrder.dates = scheduleDateArray(moment(workOrder.startDate), moment(workOrder.endDate));
		workOrder.monthYear = scheduleMonthYearArray(moment(workOrder.startDate), moment(workOrder.endDate));

		delete workOrder.id;

		batch.set(firestore.collection(handle + '/work-orders/work-orders').doc(workOrderId), { ...workOrder });

		if (lineItems?.length) {
			batch.set(firestore.collection(handle + '/work-orders/work-orders/' + workOrderId + '/serviceItems').doc(workOrderId), { serviceItems: lineItems });
		}

		batch.commit().then(() => {
			dispatch({ type: types.WORK_ORDER + '_FULFILLED' });
			window.toastr.success('The Work Order has been successfully saved/updated', 'Work Order Saved!');
			if (typeof callback === 'function') callback(workOrderId);
		}).catch((error) => {
			toolbox.process_error(error, 'Record NOT Saved!');
		});
	};
}
export function saveNewInternalWorkOrder(handle, workOrder, user, callback) {

	return async dispatch => {

		dispatch({ type: types['WORK_ORDER'] + '_SAVE_PENDING' });

		const batch = firestore.batch();
		const workOrderId = await nextWorkOrderId(handle, batch);

		workOrder.technicianId = workOrder.technicianId;
		workOrder.ownerId = user.contact?.email;
		workOrder.deleted = false;
		workOrder.created = new Date();
		workOrder.modified = new Date();
		workOrder.type = SCHEDULE_EVENT_TYPE.INTERNAL_WORKORDER.id;
		workOrder.dates = scheduleDateArray(moment(workOrder.startDate), moment(workOrder.endDate));
		workOrder.monthYear = scheduleMonthYearArray(moment(workOrder.startDate), moment(workOrder.endDate));

		batch.set(firestore.collection(handle + '/work-orders/work-orders').doc(workOrderId), { ...workOrder });

		batch.commit().then(() => {
			dispatch({ type: types['WORK_ORDER'] + '_SAVE_FULFILLED' });
			window.toastr.success('The Work Order has been successfully saved/updated', 'Work Order Saved!');
			if (typeof callback === 'function') callback(workOrderId);
		}).catch((error) => {
			toolbox.process_error(error, 'Record NOT Saved!');
		});
	};
}
export function updateWorkOrder(handle, id, workOrder, callback) {

	return dispatch => {

		dispatch({ type: types.WORK_ORDER + '_SAVE_PENDING' });

		let newWorkOrder = { ...workOrder, modified: new Date }
		if (workOrder.startDate && workOrder.endDate) {
			newWorkOrder.dates = scheduleDateArray(moment(workOrder.startDate), moment(workOrder.endDate));
			newWorkOrder.monthYear = scheduleMonthYearArray(moment(workOrder.startDate), moment(workOrder.endDate));
		}

		firestore.collection(handle + '/work-orders/work-orders').doc(id).update(newWorkOrder).then(async () => {
			// Work Order statusId based on technicianId
			const workOrderdoc = await firestore.collection(handle + '/work-orders/work-orders').doc(id).get();
			const technicianId = workOrderdoc.data()['technicianId'];
			const statusId = workOrderdoc.data()['statusId'];
			var newStatusId = null;

			if (technicianId != 'unassigned') {
				if (statusId == WORKORDERS.UNASSIGNED.id) newStatusId = WORKORDERS.ASSIGNED.id;
			} else {
				if (parseInt(statusId) < WORKORDERS.INCOMPLETE.id) newStatusId = WORKORDERS.UNASSIGNED.id;
			}
			if (newStatusId) {

				var batch = firestore.batch();
				batch.update(firestore.collection(handle + '/work-orders/work-orders').doc(id), { statusId: newStatusId });
				batch.commit().then(() => {
					dispatch({ type: types.WORK_ORDER + '_SAVE_FULFILLED' });
					if (typeof callback === 'function') callback();
				}).catch((error) => {
					console.error("Error updating document: ", error);
				});

			} else {
				dispatch({ type: types.WORK_ORDER + '_SAVE_FULFILLED' });
				if (typeof callback === 'function') callback();
			}
			window.toastr.success('The Work Order has been successfully saved/updated', 'Work Order Saved!');

		}).catch((error) => {
			// The document probably doesn't exist.
			console.error("Error updating document: ", error);
		});
	};
}
export function deleteWorkOrder(handle, workOrder, callback) {

	return async dispatch => {

		dispatch({ type: types.WORK_ORDERS + '_SAVE_PENDING' });

		var batch = firestore.batch();

		const workOrderRef = firestore.collection(handle + '/work-orders/work-orders');
		batch.set(workOrderRef.doc(workOrder.id), { deleted: true, modified: new Date() }, { merge: true });

		batch.commit().then(() => {
			dispatch({ type: types.WORK_ORDERS + '_SAVE_FULFILLED' });
			window.toastr.success('The Work Order record has been Archived', 'Work Order Archived!');
			if (typeof callback === 'function') callback();
		}).catch((error) => {
			toolbox.process_error(error, 'Work Order NOT Archived!');
		});
	};
}

/*-----------------------------------------------*/
/*  CHECKIN
/*-----------------------------------------------*/

export function getUserCheckIn(handle, userId, startdate, enddate) {

	return async dispatch => {

		dispatch({ type: types.CHECKIN + '_PENDING' });

		var unsubscribe =  firestore.collectionGroup('check-in')
			.where('date', '>=', moment(startdate, 'X').toDate())
			.where('date', '<=', moment(enddate, 'X').toDate())
			.where('handle', '==', handle)
			.where('userId', '==', userId)
			.orderBy('date', 'desc')
			.onSnapshot(async(querySnapshot) => {
				var entries = [];
				querySnapshot.forEach((doc) => { 
					entries.push({ ...doc.data(), id: doc.ref.parent.parent.id }); 
				});
				
				var workOrders = [];
				var tasks = [];
				var i = 0;
				for (let entry of entries) {
					if (entry.type == 'task') {
						let task = _.find(tasks, { id: entry.id });
						if (!task) {
							const doc = await firestore.collection(handle + '/settings/tasks').doc(entry.id).get();
							task = { ...doc.data(), id: doc.id, type: entry.type };
							tasks.push(task);
						}
						entries[i] = { ...task, ...entries[i] };
						i++;
					} else {
						let workOrder = _.find(workOrders, { id: entry.id });
						if (!workOrder) {
							const doc = await firestore.collection(handle + '/work-orders/work-orders').doc(entry.id).get();
							workOrder = { ...doc.data(), id: doc.id, type: entry.type };
							workOrders.push(workOrder);
						}
						entries[i] = { ...workOrder, ...entries[i] };
						i++;
					}
				}
				const checkin = Object.groupBy(entries, ({ id }) => id);

				dispatch({ type: types.CHECKIN + '_FULFILLED', data: checkin, unsubscribe });
			}
		);
	};
}
export function getWorkOrderCheckInTimes(handle, workOrderId) {

	return async dispatch => {

		dispatch({ type: types.CHECKIN_TIME + '_PENDING' });

		var unsubscribe =  firestore.collection(`${handle}/work-orders/work-orders/${workOrderId}/check-in`)
			.orderBy('date', 'desc')
			.onSnapshot(async(querySnapshot) => {
				var checkedIn = [];
				querySnapshot.forEach((doc) => { 
					checkedIn.push({ ...doc.data(), id: doc.ref.parent.parent.id }); 
				});

				var accrue = null;
				var prevTime = null;
				var secondsCheckedIn = {
					enroute: 0,
					inprogress: 0,
					onsite: 0,
				};

				checkedIn?.forEach((checkin) => {
					var entries = _.orderBy(checkin.entries, ['time'], ['asc']);
					entries.forEach((entry) => {
					
						const momentIn = (moment(prevTime?.seconds, 'X').isValid()) ? moment(prevTime?.seconds, 'X') : null;
						const momentOut = (moment(entry.time?.seconds, 'X').isValid()) ? moment(entry.time?.seconds, 'X') : null;
						const secondsEntry = (momentIn && momentOut) ? momentOut.diff(momentIn, 'seconds') : 0;
		
						if (entry.type == 'checkout') {
							if (accrue) secondsCheckedIn[accrue] += secondsEntry;
							prevTime = null;
							accrue = null;
						} else {
							if (accrue) secondsCheckedIn[accrue] += secondsEntry;
							prevTime = entry.time;
							accrue = entry.type;
						}
					});
				});
	
				dispatch({ type: types.CHECKIN_TIME + '_FULFILLED', data: secondsCheckedIn, unsubscribe });
			}
		);
	};
}

export function saveNewCheckinEntry(handle, newEntry) {

	return dispatch => {

		dispatch({ type: types.CHECKIN + '_SAVE_PENDING' });

		const dateId = moment(newEntry.date.seconds, 'X').format('YY-MM-DD');
		const docRef = firestore.collection(handle + '/work-orders/work-orders/' + newEntry.workOrderId + '/check-in').doc(dateId);

		docRef.get().then(async(doc) => {
			var entries = (doc.exists) ? doc.data().entries : [];
			entries.push({ 
				time: (newEntry.time) ? moment(dateId + ' ' + newEntry.time, 'YY-MM-DD h:mm a').toDate() : '',
				type: newEntry.type
			});

			let record = { 
				handle,
				userId: newEntry.userId,
				date: moment(newEntry.date.seconds, 'X').toDate(),
				entries,
				type: 'work_order',
			};
			await firestore.collection(handle + '/work-orders/work-orders/' + newEntry.workOrderId + '/check-in').doc(dateId).set(record, { merge: true });
	
			dispatch({ type: types.CHECKIN + '_SAVE_FULFILLED', data: null });
		});
	};
}
export function deleteCheckinEntry(handle, entry, callback) {

	return async dispatch => {

		dispatch({ type: types.CHECKIN + '_SAVE_PENDING' });

		const doc = await firestore.collection(handle + '/work-orders/work-orders/' + entry.workOrderId + '/check-in').doc(entry.dateId).get();
		var day = { ...doc.data(), id: doc.id };

		// remove existing day entry
		var entries = [];
		day.entries.forEach((db_entry) => {

			let dbTime = (moment(db_entry.time?.seconds, 'X').isValid()) ? moment(db_entry.time?.seconds, 'X').format('h:mm a') : '';

			if ((entry.time != dbTime) || (entry.type != db_entry.type)) {
				entries.push({ time: db_entry.time, type: db_entry.type })
			}
		});
		await firestore.collection(handle + '/work-orders/work-orders/' + entry.workOrderId + '/check-in').doc(entry.dateId).set({ entries }, { merge: true });

		dispatch({ type: types.CHECKIN + '_SAVE_FULFILLED' });
	};
}
export function updateCheckinEntry(handle, field, entry, newValue, callback) {

	return async dispatch => {

		dispatch({ type: types.CHECKIN + '_SAVE_PENDING' });

		if (field == 'date') {		
			const doc = await firestore.collection(handle + '/work-orders/work-orders/' + entry.workOrderId + '/check-in').doc(entry.dateId).get();
			var day = { ...doc.data(), id: doc.id };
	
			// remove existing day entry
			var entries = [];
			day.entries.forEach((db_entry) => {

				let dbTime = (moment(db_entry.time?.seconds, 'X').isValid()) ? moment(db_entry.time?.seconds, 'X').format('h:mm a') : '';

				if ((entry.time != dbTime) || (entry.type != db_entry.type)) {
					entries.push({ time: db_entry.time, type: db_entry.type })
				}
			});
			await firestore.collection(handle + '/work-orders/work-orders/' + entry.workOrderId + '/check-in').doc(entry.dateId).set({ entries }, { merge: true });
			
			// add new day entry
			const dateId = moment(newValue).format('YY-MM-DD');
			const doc2 = await firestore.collection(handle + '/work-orders/work-orders/' + entry.workOrderId + '/check-in').doc(dateId).get();
			if (doc2.exists) {
				var day2 = { ...doc2.data(), id: doc2.id };
				let entries = [ ...day2.entries, { 
					time: (entry.time) ? moment(dateId + ' ' + entry.time, 'YY-MM-DD h:mm a').toDate() : '',
					type: entry.type
				}];

				await firestore.collection(handle + '/work-orders/work-orders/' + entry.workOrderId + '/check-in').doc(dateId).set({ entries }, { merge: true });

			} else {
				let record = { 
					handle,
					userId: entry.userId,
					date: moment(newValue).toDate(),
					entries: [{ 
						time: (entry.time) ? moment(dateId + ' ' + entry.time, 'YY-MM-DD h:mm a').toDate() : '',
						type: entry.type
					}],
				};
				console.log(record);
				await firestore.collection(handle + '/work-orders/work-orders/' + entry.workOrderId + '/check-in').doc(dateId).set(record, { merge: true });
			}

		} else {
			const doc = await firestore.collection(handle + '/work-orders/work-orders/' + entry.workOrderId + '/check-in').doc(entry.dateId).get();
			var day = { ...doc.data(), id: doc.id };
	
			// Cycle through day entries and update changed
			var entries = [];
			day.entries.forEach((db_entry) => {
	
				let dbTime = (moment(db_entry.time?.seconds, 'X').isValid()) ? moment(db_entry.time?.seconds, 'X').format('h:mm a') : '';
	
				if ((entry.time == dbTime) && (entry.type == db_entry.type)) {
	
					let time = (field == 'time') ? newValue : entry.time;
					let type = (field == 'type') ? newValue : entry.type;
					
					entries.push({ 
						time: (time) ? moment(entry.dateId + ' ' + ((field == 'time') ? moment(newValue).format('h:mm a') : entry.time), 'YY-MM-DD h:mm a').toDate() : '',
						type: type
					});
				} else {
					entries.push({ time: db_entry.time, type: db_entry.type })
				}
			});
			await firestore.collection(handle + '/work-orders/work-orders/' + entry.workOrderId + '/check-in').doc(entry.dateId).set({ entries }, { merge: true });
		}
		window.toastr.success('Your entries have been successfully Modified', 'Check In/Out Updated!');

		dispatch({ type: types.CHECKIN + '_SAVE_FULFILLED' });
	};
}
export function updateWorkOrderCheckin(handle, workOrder, statusId, reason, user, callback) {

	return async dispatch => {

		dispatch({ type: types.WORK_ORDER + '_SAVE_PENDING' });

		const batch = firestore.batch();
		const currentDate = dayjs.tz().format('YY-MM-DD');

		if (user.lastCheckInWorkOrderId != workOrder.id) await checkOutPreviousWorkOrder(handle, batch, user, currentDate);
		await checkOutPreviousTask(handle, batch, user, currentDate);
		
		var type;
		switch(statusId) {
			case WORKORDERS.ENROUTE.id : type = 'enroute'; break;
			case WORKORDERS.ONSITE.id :	type = 'onsite'; break;
			case WORKORDERS.INPROGRESS.id :	type = 'inprogress'; break;
			default : type = 'checkout'; break;
		}

		batch.update(firestore.collection(`${handle}/work-orders/work-orders`).doc(workOrder.id), { statusId: statusId, reason: (reason) ? reason : '' });
		batch.update(firestore.collection(`${handle}/users/users`).doc(user.id), { lastCheckInWorkOrderId: workOrder.id });
		batch.set(firestore.collection(`${handle}/work-orders/work-orders/${workOrder.id}/check-in`).doc(currentDate), { 
			handle,
			userId: user.id,
			date: moment().startOf('day').toDate(),
			entries: firebase.firestore.FieldValue.arrayUnion({'time': new Date(), 'type': type})
		}, { merge: true });
		
		batch.commit().then(() => {
			dispatch({ type: types.WORK_ORDER + '_SAVE_FULFILLED' });
			dispatch({ type: types.WORK_ORDER_CHECKED_IN, data: (type == 'checkout') ? null : workOrder });
			window.toastr.info('Your "Check In" status has been updated', 'Check-In Updated!');
			if (typeof callback === 'function') callback();
		}).catch((error) => {
			console.error("Error updating document: ", error);
		});
	};
}
export function updateTaskCheckin(handle, task, user, type, callback) {

	return async dispatch => {

		dispatch({ type: types.WORK_ORDER + '_SAVE_PENDING' });

		const batch = firestore.batch();
		const currentDate = dayjs.tz().format('YY-MM-DD');

		if (user.lastCheckInTaskId != task.id) await checkOutPreviousTask(handle, batch, user, currentDate);
		await checkOutPreviousWorkOrder(handle, batch, user, currentDate);

		let update = (type == 'checkin')
		? firebase.firestore.FieldValue.arrayUnion(user.email)
		: firebase.firestore.FieldValue.arrayRemove(user.email);

		batch.update(firestore.collection(`${handle}/settings/tasks`).doc(task.id), { checkedIn: update });
		batch.update(firestore.collection(`${handle}/users/users`).doc(user.id), { lastCheckInTaskId: task.id });
		batch.set(firestore.collection(`${handle}/settings/tasks/${task.id}/check-in`).doc(currentDate), { 
			handle,
			userId: user.id,
			date: moment().startOf('day').toDate(),
			entries: firebase.firestore.FieldValue.arrayUnion({'time': new Date(), 'type': type}),
			type: 'task',
		}, { merge: true });
		
		batch.commit().then(() => {
			dispatch({ type: types.WORK_ORDER + '_SAVE_FULFILLED' });
			dispatch({ type: types.TASK_CHECKED_IN, data: (type == 'checkout') ? null : task });
			window.toastr.info('Your "Check In" status has been updated', 'Check-In Updated!');
			if (typeof callback === 'function') callback();
		}).catch((error) => {
			console.error("Error updating document: ", error);
		});
	};
}

/*-----------------------------------------------*/
/*  SERVICE ITEMS
/*-----------------------------------------------*/

export function updateWorkOrderServiceItems(handle, id, serviceItems, callback) {

	return dispatch => {

		dispatch({ type: types.INVENTORY + '_SAVE_PENDING' });

		var batch = firestore.batch();

		batch.set(firestore.collection(handle + '/work-orders/work-orders/' + id + '/serviceItems').doc(id), { serviceItems, updated: new Date() }, { merge: true });
		batch.update(firestore.collection(handle + '/work-orders/work-orders').doc(id), { modified: new Date() });
		batch.commit().then(() => {
			dispatch({ type: types.INVENTORY + '_SAVE_FULFILLED' });
			window.toastr.success('The Line Items have been successfully saved/updated', 'Line Items Saved!');
			if (typeof callback === 'function') callback();

		}).catch((error) => {
			console.error("Error updating document: ", error);
		});
	};
}

export function subServiceItemsByWorkOrderID(handle, work_order_id, callback) {

	return dispatch => {

		dispatch({ type: types['WORK_ORDER_ITEMS'] + '_CLEAR' });
		dispatch({ type: types['WORK_ORDER_ITEMS'] + '_PENDING' });

		let unsubscribe = firestore.collection(handle + '/work-orders/work-orders/' + work_order_id + '/serviceItems').doc(work_order_id).onSnapshot(async querySnapshot => {

			let serviceItems = (querySnapshot.exists) ? querySnapshot.data().serviceItems : [];

			const attachDoc = await firestore.collection(handle + '/work-orders/work-orders/' + work_order_id + '/attachments').doc(work_order_id).get();
			var attachments = (attachDoc.exists && attachDoc.data().external_invoices) ? { ...attachDoc.data().external_invoices } : [];

			var externalInvoices = _.filter(attachments, (o) => o.price);
			externalInvoices.forEach((attach) => {
				if (!attach.deleted) { 
					serviceItems.push({
						id: 'external',
						name: attach.name,
						option: 0,
						price: attach.price,
						taxable: false,
						type: 'external',
						count: 1,
						calculatedPrice: attach.price,
						_no_delete: true,
						_noEdit: true,
					});
				}
			});

			dispatch({ type: types['WORK_ORDER_ITEMS'] + '_FULFILLED', data: serviceItems, unsubscribe });
			if (typeof callback === 'function') callback(serviceItems);
		});
	};
}
export function addItemsToLineItems(items, selectedOption, serviceItems, profile, settings) {
	let exists = false;
	let newLineItems = Object.assign([], serviceItems);

	items.forEach((item) => {
		let count = item?.count;
		delete newLineItems.count;
	
		newLineItems.forEach((lineItem, index) => {
			if (
				lineItem.id == item.id && 
				lineItem.type == item.type &&
				lineItem.description == item.description &&
				lineItem.option == selectedOption
			) {
				newLineItems[index] = { ...lineItem, count: parseFloat(newLineItems[index].count) + parseFloat(count) };
				exists = true;
			}
		});

		let price = (item.type == 'labor' || item.type == 'travel') 
			? item?.price ?? 0 
			: (item?.priceOverride) 
				? item.priceOverride
				: (item?.averageCost) ? item.averageCost : 0;

		let taxable = (profile?.customerTypeId == CUSTOMERS.RESIDENTIAL.id)
			?   (item.type == 'labor' || item.type == 'travel') 
				? settings?.invoices?.taxPrefs?.laborRes ?? false 
				: settings?.invoices?.taxPrefs?.materialsRes ?? false
			:   (item.type == 'labor' || item.type == 'travel') 
				? settings?.invoices?.taxPrefs?.laborCom ?? false 
				: settings?.invoices?.taxPrefs?.materialsCom ?? false;

		if (!exists) {
			let newItem = {
				id: item.id,
				option: selectedOption,
				type: item.type,
				name: item.name,
				description: item.description ?? '',
				count: count,
				price: price,
				calculatedPrice: count * price,
				taxable: taxable,
				checkin: (item.checkin) ? item.checkin : null,
			};
			newLineItems.push(newItem);
		}
	});
	return newLineItems;
}
export function createWorkOrderCheckinEntry(type, checkin_time, settings) {

	var item;
	if (type == 'onsite') {
		let hrs = Math.round(checkin_time.onsite / 36) / 100;
		let rate = settings.invoices?.defaultLaborRate ?? 0
		item = {
			id: "labor",
			type: SERVICE_ITEMS.LABOR.id,
			name: 'Labor Onsite',
			description: 'Time Logged Onsite',
			count: hrs,
			price: rate,
			calculatedPrice: parseFloat(rate * hrs),
			checkin: 'onsite',
		};

	} else if (type == 'enroute') { // travel
		let hrs = Math.round(checkin_time.enroute / 36) / 100;
		let rate = settings.invoices?.defaultTravelRate ?? 0
		item = {
			id: "travel",
			type: SERVICE_ITEMS.TRAVEL.id,
			name: 'Travel Enroute',
			description: 'Time Logged Enroute',
			count: hrs,
			price: rate,
			calculatedPrice: parseFloat(rate * hrs),
			checkin: 'enroute',
		};

	} else if (type == 'inprogress') { // travel
		let hrs = Math.round(checkin_time.enroute / 36) / 100;
		let rate = settings.invoices?.defaultTravelRate ?? 0
		item = {
			id: "labor",
			type: SERVICE_ITEMS.LABOR.id,
			name: 'Progress Labor',
			description: 'Time Logged In Progress',
			count: hrs,
			price: rate,
			calculatedPrice: parseFloat(rate * hrs),
			checkin: 'inprogress',
		};
	}
	return item;
}
export function compareServiceItems(item1, item2) {

	return (
		item1.id == item2.id &&
		item1.type == item2.type &&
		item1.description == item2.description &&
		item1.count == item2.count &&
		item1.price == item2.price &&
		item1.taxable == item2.taxable &&
		item1.option == item2.option
	);
}

/*-----------------------------------------------*/
/*  WORK ORDER FUNCTIONS
/*-----------------------------------------------*/

export async function checkOutPreviousWorkOrder(handle, batch, user, currentDate) {

	const previousWorkOrderDoc = await firestore.collection(handle + '/work-orders/work-orders').doc(user.lastCheckInWorkOrderId).get();
	const prevWorkOrder = { ...previousWorkOrderDoc.data(), id: previousWorkOrderDoc.id };	
	
	// if previous still checked-in, check-out!
	if (
		prevWorkOrder['statusId'] == WORKORDERS.ENROUTE.id || 
		prevWorkOrder['statusId'] == WORKORDERS.ONSITE.id ||
		prevWorkOrder['statusId'] == WORKORDERS.INPROGRESS.id
	) {
		batch.update(firestore.collection(`${handle}/work-orders/work-orders`).doc(prevWorkOrder.id), { statusId: WORKORDERS.ASSIGNED.id });
		batch.set(firestore.collection(`${handle}/work-orders/work-orders/${prevWorkOrder.id}/check-in`).doc(currentDate), { 
			handle,
			userId: user.id,
			date: moment().startOf('day').toDate(),
			entries: firebase.firestore.FieldValue.arrayUnion({'time': new Date(), 'type': 'checkout'})
		}, { merge: true });
	}
}
export async function checkOutPreviousTask(handle, batch, user, currentDate) {

	const previousTaskDoc = await firestore.collection(`${handle}/settings/tasks/`).doc(user.lastCheckInTaskId).get();
	const prevTask = { ...previousTaskDoc.data(), id: previousTaskDoc.id };	

	// if previous still checked-in, check-out!
	if (prevTask.checkedIn?.includes(user.email)) {
		let update = firebase.firestore.FieldValue.arrayRemove(user.email);
		batch.update(firestore.collection(`${handle}/settings/tasks`).doc(prevTask.id), { checkedIn: update });
		batch.set(firestore.collection(`${handle}/settings/tasks/${prevTask.id}/check-in`).doc(currentDate), { 
			handle,
			userId: user.id,
			date: moment().startOf('day').toDate(),
			entries: firebase.firestore.FieldValue.arrayUnion({'time': new Date(), 'type': 'checkout'})
		}, { merge: true });
	}
}
async function nextWorkOrderId(handle, batch) {
	const table = 'work-orders';
	const field = 'nextWorkOrderId';
	const startingId = 1000;

	return toolbox.nextId(handle, batch, table, field, startingId);
}
function scheduleDateArray(starting, ending) {

	// DATE ARRAY
	var low = moment(starting).hour(0).minutes(0).seconds(0);
	var high = moment(ending).hour(23).minutes(59).seconds(59);
	var current = low.clone().hour(12);

	var i = 0;
	var dates = [];
	while (current.isBetween(low, high) && i < 90) {
		dates.push(current.format('MMDDYY'));
		current.add(1, 'day');
		i++;
	}
	return dates;
}
function scheduleMonthYearArray(starting, ending) {

	// DATE ARRAY
	var low = moment(starting).startOf('week');
	var high = moment(ending).endOf('week');
	var current = low.clone().hour(12);

	var i = 0;
	var dates = [];
	while (current.isBetween(low, high) && i < 90) {
		let entry = current.format('MMYY');
		if (!dates.includes(entry)) dates.push(current.format('MMYY'));
		current.add(1, 'day');
		i++;
	}
	return dates;
}

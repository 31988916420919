import React, { useEffect, useState } from 'react';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { Input, Select } from 'enspire-manager-framework';
import { numPanels } from 'components/common/toolbox';

var _ = require('lodash');
var moment = require('moment');

export default function DateRange(props) {

    const [state, setState] = useState({
        showOverlay: false,
        startDate: moment().subtract(30, 'days').startOf('day').toDate(),
        endDate: moment().endOf('day').toDate(),
        rangeName: 'last30',
    });

    useEffect(() => {
        handlePreset((props.range) ? props.range : 'last30');
        if (props.onChange) props.onChange({ startDate: state.startDate, endDate: state.endDate });
    }, []);

    const handleDate = (name, date, context) => {
        setState(prev => ({ ...prev, [name]: date, fetched: false, rangeName: props.buttonTitle }));
        setState(prev => ({ ...prev, rangeName: 'Custom' }));
        if (props.onChange) props.onChange({ startDate: state.startDate, endDate: state.endDate, ...{ [name]: date }});
    }
    const handleSubmit = () => {
        if (props.callback) props.callback(state.startDate, state.endDate);
        setState(prev => ({ ...prev, showOverlay: false }));
    }
    const handlePreset = (e) => {
        var value = e.target?.value;

        if (value) {
            var startDate;
            var endDate;
    
            switch (value) {
                case 'thisWeek':
                    startDate = moment().startOf('week').toDate();
                    endDate = moment().endOf('week').toDate();
                    break;
                case 'lastWeek':
                    startDate = moment().subtract(1, 'week').startOf('week').toDate();
                    endDate = moment().subtract(1, 'week').endOf('week').toDate();
                    break;
                case 'thisMonth':
                    startDate = moment().startOf('month').toDate();
                    endDate = moment().endOf('month').toDate();
                    break;
                case 'lastMonth':
                    startDate = moment().subtract(1, 'month').startOf('month').toDate();
                    endDate = moment().subtract(1, 'month').endOf('month').toDate();
                    break;
                case 'last30':
                    startDate = moment().subtract(30, 'days').startOf('day').toDate();
                    endDate = moment().endOf('day').toDate();
                    break;
                case 'last60':
                    startDate = moment().subtract(60, 'days').startOf('day').toDate();
                    endDate = moment().endOf('day').toDate();
                    break;
                case 'last90':
                    startDate = moment().subtract(90, 'days').startOf('day').toDate();
                    endDate = moment().endOf('day').toDate();
                    break;
                case 'last120':
                    startDate = moment().subtract(120, 'days').startOf('day').toDate();
                    endDate = moment().endOf('day').toDate();
                    break;
                case 'last6Months':
                    startDate = moment().subtract(6, 'months').startOf('day').toDate();
                    endDate = moment().endOf('day').toDate();
                    break;
                case 'last12Months':
                    startDate = moment().subtract(12, 'months').startOf('day').toDate();
                    endDate = moment().endOf('day').toDate();
                    break;
                case 'thisYear':
                    startDate = moment().startOf('year').toDate();
                    endDate = moment().endOf('year').toDate();
                    break;
                case 'yeartodate':
                    startDate = moment().startOf('year').toDate();
                    endDate = moment().endOf('day').toDate();
                    break;
            }
            setState(prev => ({ ...prev, startDate, endDate, rangeName: value, showOverlay: false }));
            if (props.onChange) props.onChange({ startDate, endDate });
        }
    }

    return (

        <div className="mt-n3">
            <div className={'row animated fadeInRight animation-delay-4' + props.className}>
                <div className="col-12"><p className="mb-1">Date Range</p></div>
                <div className={'col-md-8 input-group input-group-sm date'}>
                    <Input
                        className={`pr-0 ${numPanels(1)?'col-6 px-0':''}`}
                        dateFormat="MM-DD-YYYY"
                        minDate={moment('2000-01-01').toDate()}
                        maxDate={state.endDate}
                        name={'startDate'}
                        noLabel={true}
                        onChange={handleDate}
                        prepend={ <i className="fa-regular fa-calendar-day"></i> }
                        type="date"
                        value={state.startDate}
                    />
                    <Input
                        className={`pr-0 ${numPanels(1)?'col-6 px-0':''}`}
                        dateFormat="MM-DD-YYYY"
                        minDate={state.startDate}
                        maxDate={moment('2100-01-01').toDate()}
                        name={'endDate'}
                        noLabel={true}
                        onChange={handleDate}
                        type="date"
                        value={state.endDate}
                    />
                </div>

                {props.showPresets

                    ?   <div className={'col-4'}>
                            <Select noLabel onChange={handlePreset} value={ state.rangeName }>
                                <option value={ 'thisWeek' }>This Week</option>
                                <option value={ 'lastWeek' }>Last Week</option>
                                <option value={ 'thisMonth' }>This Month</option>
                                <option value={ 'lastMonth' }>Last Month</option>
                                <option value={ 'last30' }>Last 30 Days</option>
                                <option value={ 'last60' }>Last 60 Days</option>
                                <option value={ 'last90' }>Last 90 Days</option>
                                <option value={ 'last120' }>Last 120 Days</option>
                                <option value={ 'last6Months' }>Last 6 Months</option>
                                <option value={ 'last12Months' }>Last 12 Months</option>
                                <option value={ 'thisYear' }>This Year</option>
                                <option value={ 'yeartodate' }>Year to Date</option>
                            </Select>

                            {/* <DropdownButton 
                                title=<span>
                                    {state.rangeName}
                                    {props.rendering &&
                                        <span className="spinner-border spinner-border-sm ml-2" role="status" aria-hidden="true"></span>
                                    }
                                </span> 
                                variant="primary" size="sm" className="d-inline ml-2 mb-2"
                                onClick={(event) => { if (event?.stopPropagation) event?.stopPropagation(); }}
                            >
                                <Dropdown.Item eventKey="1" onClick={handlePreset.bind(this, 'thisWeek', 'This Week')}>This Week</Dropdown.Item>
                                <Dropdown.Item eventKey="1" onClick={handlePreset.bind(this, 'lastWeek', 'Last Week')}>Last Week</Dropdown.Item>
                                <Dropdown.Item eventKey="1" onClick={handlePreset.bind(this, 'thisMonth', 'This Month')}>This Month</Dropdown.Item>
                                <Dropdown.Item eventKey="1" onClick={handlePreset.bind(this, 'lastMonth', 'Last Month')}>Last Month</Dropdown.Item>
                                <Dropdown.Item eventKey="1" onClick={handlePreset.bind(this, 'last60', 'Last 60 Days')}>Last 60 Days</Dropdown.Item>
                                <Dropdown.Item eventKey="1" onClick={handlePreset.bind(this, 'last90', 'Last 90 Days')}>Last 90 Days</Dropdown.Item>
                                <Dropdown.Item eventKey="1" onClick={handlePreset.bind(this, 'last120', 'Last 120 Days')}>Last 120 Days</Dropdown.Item>
                                <Dropdown.Item eventKey="1" onClick={handlePreset.bind(this, 'last6Months', 'Last 6 Months')}>Last 6 Months</Dropdown.Item>
                                <Dropdown.Item eventKey="1" onClick={handlePreset.bind(this, 'last12Months', 'Last 12 Months')}>Last 12 Months</Dropdown.Item>
                                <Dropdown.Item eventKey="1" onClick={handlePreset.bind(this, 'thisYear', 'This Year')}>This Year</Dropdown.Item>
                                <Dropdown.Item eventKey="1" onClick={handlePreset.bind(this, 'yeartodate', 'Year to Date')}>Year to Date</Dropdown.Item>
                            </DropdownButton> */}
                        </div>
                    :   <div className="col-lg-4 pt-1">
                            <button className={'btn btn-primary btn-xs'} onClick={handleSubmit}>
                                {props.buttonTitle}
                                {props.rendering &&
                                    <span className="spinner-border spinner-border-sm ml-2" role="status" aria-hidden="true"></span>
                                }
                            </button>
                        </div>
                }
                {/* </div> */}
            </div>

            {props.children &&
                <div>
                    {(!Array.isArray(props.children)) ? React.cloneElement(props.children, { startDate: state.startDate, endDate: state.endDate }) : <></>}
                    {(props.children?.[0]) ? React.cloneElement(props.children?.[0], { startDate: state.startDate, endDate: state.endDate }) : <></>}
                    {(props.children?.[1]) ? React.cloneElement(props.children?.[1], { startDate: state.startDate, endDate: state.endDate }) : <></>}
                    {(props.children?.[2]) ? React.cloneElement(props.children?.[2], { startDate: state.startDate, endDate: state.endDate }) : <></>}
                    {(props.children?.[3]) ? React.cloneElement(props.children?.[3], { startDate: state.startDate, endDate: state.endDate }) : <></>}
                </div>
            }
        </div>
    );
};
import * as actions_profiles from 'actions/profiles-actions';
import * as toolbox from 'components/common/toolbox';
import React, { useEffect, useState } from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';
import { Avatar, Checkbox, ModalForm, Textarea } from 'enspire-manager-framework';
import { Table } from 'em-table';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { CONTACTS } from 'components/constants';

const moment = require('moment');
const _ = require('lodash');

const ResultsActionModal = (props) => {
	
	/* Hooks ------------------------------------------------------------------------------------------------------------------------------------*/
	
	const params = useParams();
    const history = useHistory();
	const dispatch = useDispatch();
	const routeLocation = useLocation();

	const company = useSelector((store) => store.company.company);
	const employees = useSelector((store) => store.employees.employees);
	const invoices = useSelector((store) => store.invoices);
	const profiles = useSelector((store) => store.profiles);
	const settings_work_orders = useSelector((store) => store.settings.settings.work_orders);
	const user = useSelector((store) => store.users.user);
	const serviceRequests = useSelector((store) => store.serviceRequests.service_requests_dashboard);
	const workOrders = useSelector((store) => store.workOrders.work_orders_dashboard);

	const [contacts, setContacts] = useState([]);
	const [hideSameNumbers, setHideSameNumbers] = useState(false);
	const [selected, setSelected] = useState([]);
	const [message, setMessage] = useState('');
	const [includePhoto, setIncludePhoto] = useState(true);
	
	const entity = (params.entity == 'sr')
		?   _.find(serviceRequests, { id: params.entity_id })
		:   _.find(workOrders, { id: params.entity_id });
	
	const technician = _.find(employees, { id: entity?.technicianId })
    const photoUrl = (includePhoto) ? technician?.contact?.photoUrl : null;
    const name = technician?.contact?.firstName + ' ' + technician?.contact?.lastName;
	const profile = profiles.profile;
	const isPhone = params.action_id == 'phone';

	// Subscribe to Profile to complete address and contact information
	useEffect(() => {
		if (parseInt(entity?.customerId)) dispatch(actions_profiles.subProfile(params.handle, entity?.customerId, 'PROFILE'));
		return () => {
			let unsubscribe = profiles.profile_unsubscribe;
			if (typeof unsubscribe === "function") unsubscribe();
			dispatch(actions_profiles.clearProfile());
		};
	}, [entity?.customerId]);

	useEffect(() => {
		if (contacts[0]) {
			let number = contacts[0].number
			setSelected([number]);
		}
	}, [contacts.length]);
	
	useEffect(() => {
		let message = settings_work_orders?.default_sms_message ?? '';
		message = message.replaceAll("${name}", name);
		message = message.replaceAll("${company}", company.companyName);
		message = message.replaceAll("${phone}", company.phone);
		message = message.replaceAll("${address}", entity?._address);
		message = message.replaceAll("${bio}", technician?.bio);

		setMessage(message);
	}, [settings_work_orders?.default_sms_message]);
	
	useEffect(() => {

		var contacts = [];
		var uniqueNumbers = [];  // Only add unique numbers
	
		// Add Contact Phone and Mobile numbers
		profile?.contacts?.forEach((contact) => {
			if (contact.mobile) {
				if (!uniqueNumbers.includes(contact.mobile)) {
					contacts.push({
						type: _.find(Object.values(CONTACTS, { id: contact.contactTypeId }))?.name,
						_name: profile.displayName,
						phone: <span><i className="fas fa-mobile mr-2"></i> {contact.mobile}</span>,
						number: contact.mobile,
						checked: !!selected?.find((n) => n == contact.mobile),
					});
					uniqueNumbers.push(contact.mobile);
				} else setHideSameNumbers(true);
			}
			if (isPhone && contact.contactPhone) {
				if (!uniqueNumbers.includes(contact.contactPhone)) {
					contacts.push({
						type: _.find(Object.values(CONTACTS, { id: contact.contactTypeId }))?.name,
						_name: profile.displayName,
						phone: <span><i className="fa-solid fa-phone mr-2"></i> {contact.contactPhone}</span>,
						number: contact.contactPhone,
						checked: !!selected?.find((n) => n == contact.contactPhone),
					})
					uniqueNumbers.push(contact.contactPhone);
				} else setHideSameNumbers(true);
			}
		});
	
		// Add Customer number for phone
		if (isPhone && profile?.phone) {
			if (!uniqueNumbers.includes(profile?.phone)) {
				contacts.unshift({
					type: 'Customer',
					_name: profile.displayName,
					phone: <span><i className="fa-solid fa-phone mr-2"></i> {profile.phone}</span>,
					number: profile.phone,
				});
			} else setHideSameNumbers(true);
		}
		setContacts(contacts);

	}, [profile?.contacts, selected]);
	
	/* Handlers ------------------------------------------------------------------------------------------------------------------------------------*/

	const handleChange = (event) => {
        setMessage(event.target.value);
    };
	const handleCheckbox = () => {
		setIncludePhoto((prev) => !prev);
	};
	const handleSelect = (item) => {
		let number = item.number;
		let selectedNumbers = [...selected];
		let checked = !!selected?.find((n) => n == number);

		if (!checked) selectedNumbers.push(number);
		else {
			let index = selectedNumbers.findIndex((n) => n == number);
			if (index > -1) { selectedNumbers.splice(index, 1); }
		}
		setSelected(selectedNumbers);
	};
	const handleButton = (actionId) => {
		let location = toolbox.modifyPath(routeLocation.pathname, 4, `action-form/${params.entity}/${params.entity_id}/${actionId}`, 7);
		history.replace({ pathname: location });
	}
	const handleClick = (item) => {
		if (isPhone) {
			window.location.href='tel:' + item.number?.replace(/\D/g, "")
		}
	}

	/* Actions ------------------------------------------------------------------------------------------------------------------------------------*/
	
	const submitMessage = () => {
		alert('Coming Soon...');
	}
	
	/* Constants ------------------------------------------------------------------------------------------------------------------------------------*/

	

	const columns = [
		{ name: 'Type', field: 'type', width:35 },
		{ name: 'Name', field: '_name', width: 65 },
		{ name: '', field: 'phone', type: 'jsx' },
		...(!isPhone) ? [{ name: '', field: 'checked', type: 'checkbox', callback: handleSelect, width: 10}] : [],
	];

	/* Render ------------------------------------------------------------------------------------------------------------------------------------*/

	return (

		<ModalForm {...props}
			bodyClassName={'px-1'}
			history={props.history}
			modal_header={'Customer Contact(s)'}
			confirm_button_title={ (isPhone) ? 'Finished' : 'Send Message' }
			confirm_button_disabled={ !isPhone && message?.length < 5 }
			cancel_button_title="Cancel"
            submitFormHandler={ (isPhone) ? () => history.goBack() : submitMessage }
			show_spinner={invoices.invoice_save_pending}
			size={'sm'}
			hide_cancel={isPhone}
		>
			<div className="d-flex justify-content-center align-items-center p-3" style={{ backgroundColor: '#eeeeee' }}>
				{ !isPhone &&
					<Avatar className="mx-2" size={50} fontSize={'20px'} name={name} image={photoUrl} border={'2px solid white'} />
				}
				{ isPhone
					?	<i className="fa-solid fa-phone-arrow-right fa-2x mx-2"></i>
					:	<i className="fa-solid fa-comment-sms fa-2x mx-2"></i>
				}

			</div>
			<ButtonGroup className="d-flex mt-3 mb-4">
                <Button variant={ (isPhone) ? 'primary' : 'default' } onClick={ () => handleButton('phone') }>Phone</Button>
                { params.entity == 'wo' &&
					<Button variant={ (!isPhone) ? 'primary' : 'default' } onClick={ () => handleButton('sms') }>SMS</Button>
				}
            </ButtonGroup>

			{ !isPhone &&
				<>
					<Checkbox
						checked={includePhoto}
						label={'Include Technician Photo'}
						name={'showTechnician'}
						onClick={handleCheckbox}
					/>
				
					<Textarea
						name={'message'}
						onChange={handleChange}
						rows={5}
						value={message}
						placeholder={'Enter Message Here'}
					/>
				</>
			}

			<Table
				data={contacts}
				show_limit={false}
				columns={columns}
				click_callback={handleClick}
                order_by = {{ fields: ['id'], direction: ['desc'] }}
				second_line={'phone'}
				chevron={ isPhone }
			/>
			{ hideSameNumbers &&
				<div className="alert alert-info">
					<strong>Note:</strong> &nbsp; Other contacts were found, but only unique numbers are listed
				</div>
			}

		</ModalForm>
	);
};


export default ResultsActionModal;

import * as toolbox from 'components/common/toolbox';
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { PERMISSIONS, WORKORDERS } from 'components/constants';
import { Ibox } from 'enspire-manager-framework';
import { Table } from 'em-table';
import { numPanels } from 'components/common/toolbox';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

const WorkOrdersTab = (props) => {
    
    /* Hooks --------------------------*/
    
    const seg = 3;
    const params = useParams();
    const history = useHistory();
	const routeLocation = useLocation();

	const user = useSelector(store => store.users?.user);
	const user_permissions = useSelector(store => store.users?.user_permissions);
    const employees = useSelector((store) => store.employees);
    const service_request = useSelector((store) => store.serviceRequests.service_request);
    const workOrders = useSelector((store) => store.workOrders);

    const [data, setData] = useState([]);

	/* Effects --------------------------*/

    useEffect(() => {
        var work_orders = [];
        workOrders?.work_orders?.forEach((workOrder) => {

            // Permissions ----------------------------------------
            const isTeamWO = (service_request?.techIds?.includes(user?.profileId) || service_request?.techIds?.includes(user?.email));
            let canViewMyWO = (
                workOrder?.technicianId == user?.profileId || 
                workOrder?.ownerId == user?.email ||
                (isTeamWO && user_permissions[PERMISSIONS.WORK_ORDERS_TEAM_VIEW.key]) ||
                user_permissions[PERMISSIONS.WORK_ORDERS_VIEW.key]
            );

            let status = _.find(Object.values(WORKORDERS), { id: workOrder.statusId });
            let employee = employees.employees?.find((o) => o.id == workOrder.technicianId);
        
            work_orders.push({...workOrder,
                name: ((employee)
                    ? (employee?.contact?.firstName || employee?.contact?.lastName) 
                        ? employee?.contact?.firstName + ' ' + employee?.contact?.lastName 
                        : 'Unknown'
                    : 'Unassigned'),
                status: status?.name,
                _stripe_color: status?.color,
                _no_click: !canViewMyWO,
            });
        });
        setData(work_orders);
    }, [workOrders?.work_orders, user_permissions]);
        
    // AutoAdvance to the first item
    useEffect(() => {
        if (user_permissions[PERMISSIONS.WORK_ORDERS_VIEW.key]) {
            if (numPanels(2) && params.tab == 'work_orders' && params.tab_id == 'auto') {

                var location = toolbox.modifyPath(props.location.pathname, seg+6, 'work_orders', seg+6);
                history.replace({ pathname: location });

                if (data.length) {
                    setTimeout(() => {
                        var location = toolbox.modifyPath(props.location.pathname, seg+6, `work_orders/${data[0]?.id}/items`);
                        history.push({ pathname: location });
                    }, 50);
                }
            }
        }
    }, [params.tab, params.tab_id, data]);
    
    /* Constants --------------------------*/

    const columns = [
        { name: 'ID', field: 'customId', width: 15 },
        { name: 'Technician', field: 'name', width: 35 },
        { name: "Date", field: "startDate", type: 'date', format: 'ddd, MMM D, YYYY h:mm A', width: 50 },
        { name: 'Summary', field: 'description' },
    ];

    return (
        <Ibox title={"Work Orders"}
            button={" + Work Order "}
            button_callback={() => {
                let location = toolbox.modifyPath(props.location.pathname, seg+2, 'work-order-form/0', seg+9);
                history.push({ pathname: location });
            }}
            button_disabled={ !user_permissions[PERMISSIONS.WORK_ORDERS_CREATE.key] }
        >
            <Table id="work-order-tab"
                active_field={'id'}
                active_id={params.tab_id}
                chevron={true}
                click_callback={(workOrder) => {
                    let location = toolbox.modifyPath(props.location.pathname, seg+7, workOrder.id + '/items', seg+9);
                    let segments = props.location.pathname.split('/').length;
                    
                    if (segments < 12) {
                        history.push({ pathname: location });
                    } else {
                        history.replace({ pathname: location });
                    }
                }}
                columns={columns}
                second_line={'description'}
                data={data}
                highlight_search={true}
                limit={10}
                order_by = {{ fields: ['id'], direction: ['desc'] }}
                show_search={false}
            >
            </Table>
        </Ibox>
    );
};

export default WorkOrdersTab;
import * as toolbox from 'components/common/toolbox';
import * as types from './action-types';
import _ from 'lodash';
import firebase from 'firebase/compat/app';
import { ROLES, WORKORDERS_CHECKEDIN_STATUSES } from 'components/constants';
import { deleteField } from 'firebase/firestore';

const firestore = firebase.firestore();

/*-----------------------------------------------*/
/*  USERS
/*-----------------------------------------------*/

export function subUser(handle, email, callback) {

	return dispatch => {

		dispatch({ type: types.USER + '_PENDING' });

		var unsubscribe = firestore.collection(handle + '/users/users').doc(email).onSnapshot(async(doc) => {
			var user = { ...doc.data(), id: doc.id };

			const snapshot = await firestore.collection(handle + '/contacts/contacts').where('email', '==', email).get();
			let contactDoc = snapshot.docs[0];
			var contact = { ...contactDoc.data(), id: contactDoc.id };
			
			var companies = [];
			const snapshot2 = await firestore.collection('_companies').get();
			snapshot2.forEach((doc) => { 
				companies.push({ ...doc.data(), id: doc.id }); 
			});	

			// Find all accounts for which this user is Authorized
			var locations = [];
			const snapshot3 = await firestore.collectionGroup('users').where('email', '==', email).get();
			snapshot3.forEach((doc) => {
				let handle = doc.ref.parent.parent.parent.id;
				let company = _.find(companies, { id: handle });
				locations.push({ name: company?.companyName ?? handle, handle: handle }); 
			});	

			dispatch({ type: types.USER + '_FULFILLED', data: { ...user, email, contact, locations }, unsubscribe });
			if (typeof callback === 'function') callback(user);

			// Check if previously checked in to Work Order or Task
			if (user.lastCheckInWorkOrderId) {
				firestore.collection(handle + '/work-orders/work-orders').doc(user.lastCheckInWorkOrderId).get().then((doc) => {
					var workOrder = { ...doc.data(), id: doc.id };
					if (WORKORDERS_CHECKEDIN_STATUSES.includes(workOrder?.statusId)) {
						dispatch({ type: types.WORK_ORDER_CHECKED_IN, data: workOrder });
					} else {
						dispatch({ type: types.WORK_ORDER_CHECKED_IN, data: null });
					}
				});
			} else {
				dispatch({ type: types.WORK_ORDER_CHECKED_IN, data: null });
			}
			
			if (user.lastCheckInTaskId) {
				firestore.collection(handle + '/settings/tasks').doc(user.lastCheckInTaskId).get().then((doc) => {
					var task = { ...doc.data(), id: doc.id };
					if (task.checkedIn?.includes(user.email)) {
						dispatch({ type: types.TASK_CHECKED_IN, data: task });
					} else {
						dispatch({ type: types.TASK_CHECKED_IN, data: null });
					}
				});
			} else {
				dispatch({ type: types.TASK_CHECKED_IN, data: null });
			}
		});
	};
}
export function subUserPermissions(handle, email, roleId) {

	return async dispatch => {

		dispatch({ type: types.USER_PERMISSIONS + '_PENDING' });

		if (email) {
			var unsubscribe = firestore.collection(handle + '/users/users').doc(email).onSnapshot(async (doc) => {

				const userRoles = doc.data().roles;
				const userId = doc.data().profileId;
				var settingsDoc = await firestore.collection(handle).doc('settings').get();
				const permissionsData = settingsDoc.data()?.permissions ?? {};

				var permissions = { 
					SUPERADMIN: userRoles.includes('9999'),
					OWNER: userRoles.includes(ROLES.OWNER.id), 
					ADMINISTRATOR: userRoles.includes(ROLES.ADMINISTRATOR.id) 
				};

				Object.keys(permissionsData).forEach((key) => {
					permissions[key] = (permissionsData[key].excluded?.find((exc) => exc.value == userId)) 
						? false
						: (permissionsData[key].included?.find((inc) => inc.value == userId)) 
							? true
							: permissionsData[key].roles?.find((role) => userRoles.includes(role.toString())) != undefined;
				});

				dispatch({ type: types.USER_PERMISSIONS + '_FULFILLED', data: permissions, unsubscribe });
			});

			var unsubscribe = firestore.collection(handle).doc('settings').onSnapshot(async (doc) => {

				var userDoc = await firestore.collection(handle + '/users/users').doc(email).get();
				const userRoles = userDoc.data().roles;
				const userId = userDoc.data().profileId;
				const permissionsData = doc.data()?.permissions ?? {};

				var permissions = { 
					SUPERADMIN: userRoles.includes('9999'),
					OWNER: userRoles.includes(ROLES.OWNER.id), 
					ADMINISTRATOR: userRoles.includes(ROLES.ADMINISTRATOR.id) 
				};

				Object.keys(permissionsData).forEach((key) => {
					permissions[key] = (permissionsData[key].excluded?.find((exc) => exc.value == userId)) 
						? false
						: (permissionsData[key].included?.find((inc) => inc.value == userId)) 
							? true
							: permissionsData[key].roles?.find((role) => userRoles.includes(role.toString())) != undefined;
				});
				

				dispatch({ type: types.USER_PERMISSIONS + '_FULFILLED', data: permissions, unsubscribe });
			});

		} else if (roleId) {
			
			var settingsDoc = await firestore.collection(handle).doc('settings').get();
			const permissionsData = settingsDoc.data().permissions ?? {};

			var permissions = { OWNER: roleId == ROLES.OWNER.id, ADMINISTRATOR: roleId == ROLES.ADMINISTRATOR.id };

			Object.keys(permissionsData).forEach((key) => {
				console.log(permissionsData[key].roles?.find((role) => role == roleId));
				permissions[key] = permissionsData[key].roles?.find((role) => role == roleId) != undefined;
			});

			dispatch({ type: types.USER_PERMISSIONS + '_FULFILLED', data: permissions, unsubscribe });
		}
	};
}
export function updateUser(handle, email, profileId, user, callback) {

	return async dispatch => {

		dispatch({ type: types.USER + '_SAVE_PENDING' });

		var batch = firestore.batch();

		const userRef = firestore.collection(handle + '/users/users');
		const profileRef = firestore.collection(handle + '/profiles/profiles');

		if (email) batch.set(userRef.doc(email), { ...user }, { merge: true });
		if (profileId) batch.set(profileRef.doc(profileId), { modified: new Date(),
			...(user.roles) ? [{ roles: user.roles }] : [{}], 	
		}, { merge: true });

		batch.commit().then(() => {
			window.toastr.success('The User record has been successfully saved/updated', 'User Saved!');
			dispatch({ type: types.USER + '_SAVE_FULFILLED' });
			if (typeof callback === 'function') callback(email);
		}).catch((error) => {
			toolbox.process_error(error, 'User NOT Saved!');
		});
	};
}
export function saveToken(handle, email, token) {

	return async dispatch => {

		dispatch({ type: types.USER + '_SAVE_PENDING' });
		await firestore.collection(handle + '/users/users').doc(email).update({ token });
		dispatch({ type: types.USER + '_SAVE_FULFILLED' });
	};
}
export function viewAs(email) {
	return async dispatch => {
		dispatch({ type: types.VIEW_AS, data: email });
	};
}
export function dismissNotice(handle, email, noticeId) {

	return async dispatch => {

		dispatch({ type: types.USER + '_SAVE_PENDING' });
		await firestore.collection(handle + '/users/users').doc(email).update({ [`dismissed.${noticeId}`]: true });
		dispatch({ type: types.USER + '_SAVE_FULFILLED' });
	};
}
export function deleteUser(handle, email, callback) {

	return async dispatch => {

		dispatch({ type: types.USER + '_SAVE_PENDING' });

		firestore.collection(handle + '/users/users').doc(email).delete().then(() => {
			window.toastr.success('The User record has been permanently deleted', 'User Saved!');
			dispatch({ type: types.USER + '_SAVE_FULFILLED' });
			if (typeof callback === 'function') callback(email);
		}).catch((error) => {
			toolbox.process_error(error, 'User NOT Saved!');
		});
	};
}
export function subUserLocationsByDate(handle, email, date, callback) {

	return dispatch => {

		if (email) {

			dispatch({ type: types.USER_LOCATIONS + '_PENDING' });

			var beginningOfDay = new Date(date.getFullYear(), date.getMonth(), date.getDate());
			var endOfDay = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1);

			var unsubscribe = firestore.collection(handle + '/users/users/' + email + '/locations').where('timestamp', '>=', beginningOfDay).where('timestamp', '<', endOfDay).onSnapshot((querySnapshot) => {

				var locations = [];
				querySnapshot.forEach((doc) => {
					locations.push({ ...doc.data(), id: doc.id });
				});

				dispatch({ type: types['USER_LOCATIONS'] + '_FULFILLED', data: locations, unsubscribe });
				if (typeof callback === 'function') callback();
			});
		} else {
			console.error('Missing Email on Location search');
		}
	};
}

// export function subUserLocations(handle, email, callback) {

// 	return dispatch => {

// 		dispatch({ type: types.USER_LOCATIONS + '_PENDING' });

// 		var unsubscribe = firestore.collection(handle + '/users/users/' + email + '/locations').get().then((querySnapshot) => {

// 			var locations = [];
// 			querySnapshot.forEach((doc) => {
// 				locations.push({ ...doc.data(), id: doc.id });
// 			});

// 			dispatch({ type: types.USER_LOCATIONS + '_FULFILLED', data: locations, unsubscribe });
// 			if (typeof callback === 'function') callback();
// 		});
// 	};
// }

/*-----------------------------------------------*/
/*  SERVICE ITEMS
/*-----------------------------------------------*/

export function addItemToRecent(handle, item, userId) {

	return async dispatch => {

		dispatch({ type: types.USER + '_SAVE_PENDING' });

		firestore.collection(handle + '/users/users/' + userId + '/items').doc('items').set({ recent: { [item.id]: new Date() } }, { merge: true }).then(() => {
			dispatch({ type: types.USER + '_SAVE_FULFILLED' });
		}).catch((error) => {
			toolbox.process_error(error, 'Unable to add item to recents!');
		});
	};
}
export function trimRecent(handle, recentArray, typeId, userId) {

	return async dispatch => {

		dispatch({ type: types.USER + '_SAVE_PENDING' });

		var batch = firestore.batch();
		var deleteValues = recentArray.slice(50);
		deleteValues.forEach((value) => {
			batch.set(firestore.collection(handle + '/users/users/' + userId + '/service-items').doc(typeId.toString()), { recent: { [value.id]: deleteField() } }, { merge: true });
		});

		batch.commit().then(() => {
			dispatch({ type: types.USER + '_SAVE_FULFILLED' });
		}).catch((error) => {
			toolbox.process_error(error, 'Unable to add item to recents!');
		});
	};
}
export function subUserRecentItems(handle, email) {

	return dispatch => {

		dispatch({ type: types.USER_RECENT_ITEMS + '_PENDING' });

		var unsubscribe = firestore.collection(handle + '/users/users/' + email + '/items').onSnapshot((querySnapshot) => {

			var recentItems = {};
			querySnapshot.forEach((doc) => {
				recentItems[doc.id] = doc.data();
			});

			dispatch({ type: types.USER_RECENT_ITEMS + '_FULFILLED', data: recentItems, unsubscribe });
		});
	};
}
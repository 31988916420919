import QRCode from "react-qr-code";
import React from 'react';
import { ModalForm } from 'enspire-manager-framework';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

const PaymentLinkModal = (props) => {
	
    const history = useHistory();
	const invoice = useSelector((store) => store.invoices.invoice);

	return (

		<ModalForm {...props}
			bodyClassName={'px-1'}
			history={props.history}
			modal_header={'Invoice Payment Link'}
			confirm_button_title={ 'Finished' }
			cancel_button_title="Cancel"
            submitFormHandler={ () => history.goBack() }
			size={'sm'}
			hide_cancel={true}
		>
			<h1 className="text-center mb-4">Payment Link</h1>
			<div style={{ height: "auto", margin: "0 auto", width: "100%" }}>
				<QRCode
					size={256}
					style={{ height: "auto", maxWidth: "100%", width: "100%" }}
					value={invoice.paymentLink}
					viewBox={`0 0 256 256`}
				/>
			</div>
		</ModalForm>
	);
};


export default PaymentLinkModal;

import * as types from './action-types';
import _ from 'lodash';
import dayjs from 'dayjs';
import firebase from 'firebase/compat/app';
import { Accordion, Card } from 'react-bootstrap';
import { checkOutPreviousWorkOrder, checkOutPreviousTask } from 'actions/work-orders-actions';
import { formatHoursMinutes } from 'components/common/toolbox';

const firestore = firebase.firestore();
const duration = require("dayjs/plugin/duration");
const timezone = require("dayjs/plugin/timezone"); // dependent on utc plugin
const utc = require("dayjs/plugin/utc");
dayjs.extend(duration);
dayjs.extend(timezone);
dayjs.extend(utc);

/*-----------------------------------------------*/
/*  TIME-CLOCK
/*-----------------------------------------------*/

export function subTimeSheets(handle) {

	return async dispatch => {
	
		dispatch({ type: types.TIME_SHEETS + '_PENDING' });
		var unsubscribe = firestore.collection(handle + '/timesheets/timesheets').orderBy('startDate', 'desc').limit(12).onSnapshot((querySnapshot) => {
			var time_sheets = [];
			querySnapshot.forEach((doc) => {
				time_sheets.push({ ...doc.data(), id: doc.id });
			});
			
			dispatch({ type: types.TIME_SHEETS + '_FULFILLED', data: time_sheets, unsubscribe });
		});

	};
}
export function subTimeCards(handle, userId) {

	return async dispatch => {
	
		if (userId) {
			dispatch({ type: types.TIME_CARDS + '_PENDING' });
			var unsubscribe = firestore.collection(handle + '/timesheets/timecards').where('userId', '==', userId).orderBy('startDate', 'desc').limit(12).onSnapshot((querySnapshot) => {
				var time_cards = [];
				querySnapshot.forEach((doc) => {
					time_cards.push({ ...doc.data(), id: doc.id });
				});
	
				dispatch({ type: types.TIME_CARDS + '_FULFILLED', data: time_cards, unsubscribe });
			});
		}
	};
}

export function subTimeClock(handle, userId) {

	return async dispatch => {

		dispatch({ type: types.TIME_CLOCK + '_PENDING' });

		const id = dayjs().format('YY-MM-DD'); // '24-19-24'
		var unsubscribe = firestore.collection(handle + '/users/users/' + userId + '/time-clock').doc(id).onSnapshot((doc) => {
			if (doc.exists) {
				var time_clock_data = { ...doc.data(), id: doc.id }

				var clockedIn = false;
				var secondsAccumulated = 1;
				var lastTimeIn = null;
				var lastTimeOut = null;

				time_clock_data.entries.forEach((entry, index) => {
					if (entry['time-out']) {
						secondsAccumulated += dayjs(entry['time-out'].toDate()).diff(dayjs(entry['time-in'].toDate()), 'seconds');
						lastTimeOut = entry['time-out'];
					} else {
						clockedIn = true;
						lastTimeIn = entry['time-in'];
					}
				});
			}

			var time_clock = { clockedIn, secondsAccumulated, lastTimeIn, lastTimeOut }

			dispatch({ type: types.TIME_CLOCK + '_FULFILLED', data: time_clock, unsubscribe });
		});
	};
}
export function getTimeClockRaw(handle, userId, startdate, enddate) {

	return async dispatch => {

		dispatch({ type: types.TIME_ENTRIES + '_PENDING' });

		var unsubscribe = firestore.collection(handle + '/users/users/' + userId + '/time-clock')
			.where('date', '>=', dayjs.unix(startdate).toDate())
			.where('date', '<=', dayjs.unix(enddate).toDate())
			.orderBy('date', 'desc')
			.onSnapshot((querySnapshot) => {
				var time_clock = [];
				querySnapshot.forEach((doc) => {
					time_clock.push({ ...doc.data(), id: doc.id });
				});

				dispatch({ type: types.TIME_ENTRIES + '_FULFILLED', data: time_clock, unsubscribe });
			}
		);
	};
}
export function getTimeSheetData(handle, dateRange) {

	return async dispatch => {

		dispatch({ type: types.TIME_SHEET + '_PENDING' });

		// Time Clock
		const timeSnapshot = await firestore.collectionGroup('time-clock').where('handle', '==', handle).where('date', '>=', dateRange[0]).where('date', '<', dateRange[1]).get();	
		var entries = [];
		timeSnapshot.forEach((doc) => { entries.push({ ...doc.data(), id: doc.ref.parent.parent.id }); });
		const timeClock = Object.groupBy(entries, ({ id }) => id);

		// Checked In
		const checkedInSnapshot = await firestore.collectionGroup('check-in').where('handle', '==', handle).where('date', '>=', dateRange[0]).where('date', '<', dateRange[1]).get();
		var entries = [];
		checkedInSnapshot.forEach((doc) => { entries.push({ ...doc.data(), id: doc.ref.parent.parent.id }); });
		const checkedIn = Object.groupBy(entries, ({ userId }) => userId);

		const values = [timeClock, checkedIn]
		dispatch({ type: types.TIME_SHEET + '_FULFILLED', data: values });
	};
}
export function createTimesheetPdf(options, isTimecards, callback) {
	return async dispatch => {
		let report = (isTimecards) ? 'createTimecardsPdf' : 'createTimesheetPdf';
		try {
			// firebase.functions().useEmulator("127.0.0.1", 5001);
			firebase.functions().httpsCallable(report)(options).then((result) => { // change for timesheet
				if (typeof callback === 'function') callback(result);
			}).catch((error) => {
				console.log(error);
			});
		} catch (error) {
			window.toastr.error(error, "Error Creating Timesheet/Timecard");
		}
	};
}
export function clockIn(handle, users) {

	return dispatch => {

		if (!users.time_clock?.clockedIn) {
			dispatch({ type: types.TIME_CLOCK + '_SAVE_PENDING' });

			const id = dayjs.tz().format('YY-MM-DD'); // saved in Company timezone
			const docRef = firestore.collection(handle + '/users/users/' + users.user.id + '/time-clock').doc(id);

			docRef.get().then(async(doc) => {
				var entries = (doc.exists) ? doc.data().entries : [];
				if (!entries.length || entries[entries.length-1]['time-out']) {
					entries.push({ 'time-in': dayjs.tz().toDate() });
					await docRef.set({ handle, date: dayjs().startOf('day').toDate(), entries });
					window.toastr.info('You have been successfully Clocked In', 'Clocked In!');
				} else {
					// already Clocked in
				}
				dispatch({ type: types.TIME_CLOCK + '_SAVE_FULFILLED', data: null });
			});
		}
	};
}
export function clockOut(handle, users) {

	return async dispatch => {

		if (users.time_clock?.clockedIn) {
			dispatch({ type: types.TIME_CLOCK + '_SAVE_PENDING' });

			const id = dayjs.tz().format('YY-MM-DD'); // saved in Company timezone
			const timeClockRef = firestore.collection(`${handle}/users/users/${users.user.id}/time-clock`).doc(id);
			const timeClockDoc = await timeClockRef.get();

			if (timeClockDoc.exists) {
				const batch = firestore.batch();
				const currentDate = dayjs.tz().format('YY-MM-DD');

				var entries = timeClockDoc.data().entries;
				entries[entries.length - 1]['time-out'] = dayjs.tz().toDate();
				batch.set(timeClockRef, { entries }, { merge: true });

				await checkOutPreviousWorkOrder(handle, batch, users.user, currentDate);
				await checkOutPreviousTask(handle, batch, users.user, currentDate);

				batch.commit().then(() => {
					dispatch({ type: types.TIME_CLOCK + '_SAVE_FULFILLED', data: null });
					dispatch({ type: types.WORK_ORDER_CHECKED_IN, data: null });
					window.toastr.info('You have been successfully Clocked Out', 'Clocked Out!');
				}).catch((error) => {
					console.error("Error updating document: ", error);
				});	
			}
		}
	};
}
export function saveNewClockEntry(handle, userId, newEntry) {

	return dispatch => {

		dispatch({ type: types.TIME_CLOCK + '_SAVE_PENDING' });

		const id = dayjs(newEntry.date).format('YY-MM-DD');
		const docRef = firestore.collection(handle + '/users/users/' + userId + '/time-clock').doc(id);

		docRef.get().then(async(doc) => {
			var entries = (doc.exists) ? doc.data().entries : [];
			entries.push({ 
				['time-in']: (newEntry.timeIn) ? dayjs(id + ' ' + dayjs(newEntry.timeIn).format('h:mm a'), 'YY-MM-DD h:mm a').toDate() : '',
				['time-out']: (newEntry.timeOut) ? dayjs(id + ' ' + dayjs(newEntry.timeOut).format('h:mm a'), 'YY-MM-DD h:mm a').toDate() : '',
			});

			let record = { 
				date: dayjs(newEntry.date).toDate(),
				entries,
				handle: handle,
			};
			await firestore.collection(handle + '/users/users/' + userId + '/time-clock').doc(id).set(record, { merge: true });
	
			dispatch({ type: types.TIME_CLOCK + '_SAVE_FULFILLED', data: null });
		});
	};
}
export function deleteClockEntry(handle, userId, entry, callback) {

	return async dispatch => {

		dispatch({ type: types.TIME_CLOCK + '_SAVE_PENDING' });

		const id = dayjs.unix(entry.date.seconds).format('YY-MM-DD');
		const doc = await firestore.collection(handle + '/users/users/' + userId + '/time-clock').doc(id).get();
		var day = { ...doc.data(), id: doc.id };

		// remove existing day entry
		var entries = [];
		day.entries.forEach((db_entry) => {

			let dbIn = (dayjs.unix(db_entry['time-in']?.seconds).isValid()) ? dayjs.unix(db_entry['time-in']?.seconds).format('h:mm a') : '';
			let dbOut = (dayjs.unix(db_entry['time-out']?.seconds).isValid()) ? dayjs.unix(db_entry['time-out']?.seconds).format('h:mm a') : '';

			if ((entry.timeIn != dbIn) || (entry.timeOut != dbOut)) {
				entries.push({ ['time-in']: db_entry['time-in'], ['time-out']: db_entry['time-out'] })
			}
		});
		await firestore.collection(handle + '/users/users/' + userId + '/time-clock').doc(id).set({ entries }, { merge: true });

		dispatch({ type: types.TIME_CLOCK + '_SAVE_FULFILLED' });
	};
}
export function updateClockEntry(handle, userId, field, entry, newValue, callback) {

	return async dispatch => {

		dispatch({ type: types.TIME_CLOCK + '_SAVE_PENDING' });

		if (field == 'date') {		
			const id = dayjs(entry.date).format('YY-MM-DD');
			const doc = await firestore.collection(handle + '/users/users/' + userId + '/time-clock').doc(id).get();
			var day = { ...doc.data(), id: doc.id };
	
			// remove existing day entry
			var entries = [];
			day.entries.forEach((db_entry) => {
	
				let dbIn = (dayjs.unix(db_entry['time-in']?.seconds).isValid()) ? dayjs.unix(db_entry['time-in']?.seconds).format('h:mm a') : '';
				let dbOut = (dayjs.unix(db_entry['time-out']?.seconds).isValid()) ? dayjs.unix(db_entry['time-out']?.seconds).format('h:mm a') : '';
	
				if ((entry.timeIn != dbIn) || (entry.timeOut != dbOut)) {
					entries.push({ ['time-in']: db_entry['time-in'], ['time-out']: db_entry['time-out'] })
				}
			});
			await firestore.collection(handle + '/users/users/' + userId + '/time-clock').doc(id).set({ entries }, { merge: true });
			
			// add new day entry
			const id2 = dayjs(newValue).format('YY-MM-DD');
			const doc2 = await firestore.collection(handle + '/users/users/' + userId + '/time-clock').doc(id2).get();
			if (doc2.exists) {
				var day2 = { ...doc2.data(), id: doc2.id };
				let entries2 = [ ...day2.entries, { 
					['time-in']: (entry.timeIn) ? dayjs(id2 + ' ' + entry.timeIn, 'YY-MM-DD h:mm a').toDate() : '',
					['time-out']: (entry.timeOut) ? dayjs(id2 + ' ' + entry.timeOut, 'YY-MM-DD h:mm a').toDate() : '',
				}];

				await firestore.collection(handle + '/users/users/' + userId + '/time-clock').doc(id2).set({ entries: entries2 }, { merge: true });

			} else {
				let record = { 
					date: dayjs(newValue).toDate(),
					entries: [{ 
						['time-in']: (entry.timeIn) ? dayjs(id2 + ' ' + entry.timeIn, 'YY-MM-DD h:mm a').toDate() : '',
						['time-out']: (entry.timeOut) ? dayjs(id2 + ' ' + entry.timeOut, 'YY-MM-DD h:mm a').toDate() : '',
					}],
					handle: handle,
				};
				await firestore.collection(handle + '/users/users/' + userId + '/time-clock').doc(id2).set(record, { merge: true });
			}

		} else {
			const id = dayjs.unix(entry.date.seconds).format('YY-MM-DD');
			const doc = await firestore.collection(handle + '/users/users/' + userId + '/time-clock').doc(id).get();
			var day = { ...doc.data(), id: doc.id };
	
			// Cycle through day entries and update changed
			var entries = [];
			day.entries.forEach((db_entry) => {
	
				let dbIn = (dayjs.unix(db_entry['time-in']?.seconds).isValid()) ? dayjs.unix(db_entry['time-in']?.seconds).format('h:mm a') : '';
				let dbOut = (dayjs.unix(db_entry['time-out']?.seconds).isValid()) ? dayjs.unix(db_entry['time-out']?.seconds).format('h:mm a') : '';
	
				if ((entry.timeIn == dbIn) && (entry.timeOut == dbOut)) {
	
					let timeIn = (field == 'timeIn') ? newValue : entry.timeIn;
					let timeOut = (field == 'timeOut') ? newValue : entry.timeOut;
					
					entries.push({
						['time-in']: (timeIn) ? dayjs(id + ' ' + ((field == 'timeIn') ? dayjs(newValue).format('h:mm a') : entry.timeIn), 'YY-MM-DD h:mm a').toDate() : '',
						['time-out']: (timeOut) ? dayjs(id + ' ' + ((field == 'timeOut') ? dayjs(newValue).format('h:mm a') : entry.timeOut), 'YY-MM-DD h:mm a').toDate() : '',
					});
				} else {
					entries.push({ ['time-in']: db_entry['time-in'], ['time-out']: db_entry['time-out'] })
				}
			});
			await firestore.collection(handle + '/users/users/' + userId + '/time-clock').doc(id).set({ entries }, { merge: true });
		}
		window.toastr.success('Your time entries have been successfully Modified', 'Time Clock Updated!');

		dispatch({ type: types.TIME_CLOCK + '_SAVE_FULFILLED' });
	};
}

export function returnTimecardComponents({
	checkedIn,
	employees,
	handleOpenAccordian,
	isTimecards,
	isTimeClock,
	open,
	openEditCheckin,
	openEditTimeClock,
	timeSheet,
	user_id,
	user_permissions, 
}) {

	var totalTime = 0;
	var timecards = [];

	Object.entries(timeSheet).forEach(([userId, entriesArray], index) => {

		if (isTimecards && userId != user_id) return;

		const employee = _.find(employees, (o) => { return o.contact?.email == userId });
		if (!employee) return null;

		var entries = [];
		entriesArray.forEach((date) => entries = entries.concat(date.entries));
		entries = _.orderBy(entries, ['time-in'], ['asc']);

		var secondsAccumulated = 0;
		var daily = {};
		var missingClockout = [];
		var currentlyClockedIn = false;

		if (!entries.length) return null;

		// calculate totals
		entries.forEach((entry, index) => {
			if (entry?.['time-out'] && entry?.['time-in']) {
				const momentIn = dayjs(entry['time-in'].toDate());
				const momentOut = dayjs(entry['time-out'].toDate());
				const secondsEntry = momentOut.diff(momentIn, 'seconds');

				var current = daily[momentIn.format('YYYY-MM-DD')] ?? 0;
				
				if (secondsEntry > 59) {
					daily[momentIn.format('YYYY-MM-DD')] = current + secondsEntry;
					secondsAccumulated += secondsEntry;
					totalTime += secondsEntry;
				}
			} else if (!entry?.['time-out'] && entry?.['time-in']) {
				missingClockout.push(dayjs(entry['time-in'].toDate()).toDate());
				if (index == entries.length-1) currentlyClockedIn = true;
			}
		});
		
		var secondsTasks = 0;
		var secondsCheckedIn = {
			inprogress: 0,
			enroute: 0,
			onsite: 0,
		};
		var accrue = null;
		var prevTime = null;
		var missingCheckout = [];
		
		checkedIn[userId]?.forEach((checkin) => {
			
			if (checkin.type == 'task') {
				let entries = _.orderBy(checkin.entries, ['time'], ['asc']);
				entries.forEach((entry) => {
					if (entry.type == 'checkin') {
						prevTime = entry.time;
					} else {
						const momentIn = (dayjs.unix(prevTime?.seconds).isValid()) ? dayjs.unix(prevTime?.seconds) : null;
						const momentOut = (dayjs.unix(entry.time?.seconds).isValid()) ? dayjs.unix(entry.time?.seconds) : null;
						const secondsEntry = (momentIn && momentOut) ? momentOut.diff(momentIn, 'seconds') : 0;
						secondsTasks += secondsEntry;
					}
				});
				// if (accrue) missingCheckout.push(moment(prevTime?.seconds, 'X').toDate());
				
			} else {
				let entries = _.orderBy(checkin.entries, ['time'], ['asc']);
				entries.forEach((entry) => {
				
					const momentIn = (dayjs(prevTime?.seconds).isValid()) ? dayjs(prevTime?.seconds) : null;
					const momentOut = (dayjs(entry.time?.seconds).isValid()) ? dayjs(entry.time?.seconds) : null;
					const secondsEntry = (momentIn && momentOut) ? momentOut.diff(momentIn, 'seconds') : 0;

					if (entry.type == 'checkout') {
						if (accrue) secondsCheckedIn[accrue] += secondsEntry;
						prevTime = null;
						accrue = null;
					} else {
						if (accrue) secondsCheckedIn[accrue] += secondsEntry;
						prevTime = entry.time;
						accrue = entry.type;
					}
				});
				if (accrue) missingCheckout.push(dayjs.unix(prevTime?.seconds).toDate());
			}
		});
		
		const percentInprogress = secondsCheckedIn.inprogress / secondsAccumulated * 100;
		const percentEnroute = secondsCheckedIn.enroute / secondsAccumulated * 100;
		const percentOnsite = secondsCheckedIn.onsite / secondsAccumulated * 100;
		const percentTasks = secondsTasks / secondsAccumulated * 100;

		const missingClockoutAlert = (missingClockout.length > 1 || (missingClockout.length == 1 && !currentlyClockedIn));
		const missingCheckoutAlert = (missingCheckout.length > 1 || (missingCheckout.length == 1 && !currentlyClockedIn));
		const showClockedIn = (missingClockout.length == 1 && currentlyClockedIn);

		let hours = Math.floor(secondsAccumulated / 3600);
		let minutes = Math.floor(secondsAccumulated % 3600 / 60);
		if (minutes < 10) minutes = `0${minutes}`;
		else if (minutes == 0) minutes = `00`;

		if (secondsAccumulated > 59) timecards.push(

			<Card className="card-table" key={'card-' + userId}>
				<Accordion.Toggle as={Card.Header} eventKey={index.toString()} style={{ cursor: 'pointer' }} onClick={() => handleOpenAccordian(userId)}>
					<span className='float-right ml-4 mt-2'>
						<i className={`fa fa-chevron-${(open == userId) ? 'up' : 'down'}`}></i>
					</span>
					<span className="float-right" style={{ fontSize: '28px' }}>
						{ formatHoursMinutes(secondsAccumulated) } <small>hrs</small>
					</span>
					<p className="font-weight-bold m-0">{`${employee.contact.firstName} ${employee.contact.lastName}`}</p>
					<p className="m-0">{ userId }</p>
				</Accordion.Toggle>
				<Accordion.Collapse eventKey={index.toString()}>
					<Card.Body>
						<div className="progress">
							<div className="progress-bar" role="progressbar" style={{ width: `${percentOnsite}%` }}></div>
							<div className="progress-bar bg-warning" role="progressbar" style={{ width: `${percentInprogress}%` }}></div>
							<div className="progress-bar bg-info" role="progressbar" style={{ width: `${percentEnroute}%` }}></div>
							<div className="progress-bar bg-success" role="progressbar" style={{ width: `${percentTasks}%` }}></div>
						</div>
						{
							Object.entries(daily)?.map((line, index) => {
								return (
									<div key={index} className="d-flex justify-content-center mt-2">
										<span className="pr-2">{ dayjs(line[0]).format('dddd, MMM D, YYYY') }</span>
										<div style={{ height: '1.2rem', borderBottom: '2px dotted #aaaaaa', flexGrow: 2 }}></div>
										<span className="pl-2">{ dayjs.duration(line[1], 'seconds')?.format('HH:mm', { trim: false }) }</span>
									</div>
								);
							})
						}
						{ missingClockoutAlert &&
							<>
								{ missingClockout.map((clockout) => 
									<p className="btn btn-danger btn-sm mt-2" onClick={ openEditTimeClock.bind(this, employee.id) } disabled={!user_permissions.EMPLOYEES_CREATE && !user_permissions.EMPLOYEES_EDIT}>
										Resolve Missing "Clock Out": &nbsp; { dayjs(clockout).format('dddd, MMM D, YYYY') }
									</p>) 
								}
							</>
						}
						{ missingCheckoutAlert &&
							<>
								{ missingCheckout.map((checkout) => 
									<p className="btn btn-danger btn-sm mt-2" onClick={ openEditCheckin.bind(this, employee.id) } disabled={!user_permissions.EMPLOYEES_CREATE && !user_permissions.EMPLOYEES_EDIT}>
										Missing Work Order "Check Out": { dayjs(checkout).format('dddd, MMM D, YYYY') }
									</p>) 
								}
							</>
						}
						<p className="text-right mt-3 mb-0">
							<i className="fa-solid fa-circle text-primary"></i> <span className="font-weight-bold">Onsite:</span> { dayjs.duration((secondsCheckedIn.onsite + 1) * 1000)?.format('H:mm') } |&nbsp; 
							<i className="fa-solid fa-circle text-info"></i> <span className="font-weight-bold">Enroute:</span> { dayjs.duration((secondsCheckedIn.enroute + 1) * 1000)?.format('H:mm') } |&nbsp;
							<i className="fa-solid fa-circle text-warning"></i> <span className="font-weight-bold">Inprogress:</span> { dayjs.duration((secondsCheckedIn.inprogress + 1) * 1000)?.format('H:mm') } |&nbsp;
							<i className="fa-solid fa-circle text-success"></i> <span className="font-weight-bold">Tasks:</span> { dayjs.duration((secondsTasks + 1) * 1000)?.format('H:mm') }
						</p>
						<p className="text-right mt-2 mb-0">Period: 7 days</p>

						{ !isTimeClock &&
							<div className="float-right">
								<button type="button" className={'btn btn-default btn-sm mt-3 mr-2' } onClick={openEditCheckin.bind(this, employee.id)}>
									Edit Checkin
								</button>
								<button type="button" className={'btn btn-default btn-sm mt-3 mr-2' } onClick={openEditTimeClock.bind(this, employee.id)}>
									Edit Timecard
								</button>
							</div>
						}

						<div style={{ clear: 'both' }}></div>
					</Card.Body>
				</Accordion.Collapse>
			</Card>
		);
	});

	return [ timecards, totalTime ];
}

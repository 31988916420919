import * as actions_admin from 'actions/admin-actions';
import * as actions_profiles from 'actions/profiles-actions';
import * as actions_quotes from 'actions/quotes-actions';
import * as actions_settings from 'actions/settings-actions';
import * as actions_timeclock from 'actions/timeclock-actions';
import * as actions_users from 'actions/users-actions';
import * as actions_work_orders from 'actions/work-orders-actions';
import * as toolbox from 'components/common/toolbox';
import * as types from 'actions/action-types';
import Authorize from 'components/common/authorize';
import ExpandDetails from 'components/common/expand-details';
import React, { useEffect, useState } from 'react';
import SpeedDialButton from 'components/common/speed-dial-button'; 
import StatusSelect from 'components/common/status-select';
import TooltipOverlay from 'components/common/tooltip-overlay';
import { APPS, ACTION, LOG, REQUESTS, ROLES, WORKORDERS } from 'components/constants';
import { Avatar, DashboardDetail, ModalAlert, Select, Spinner } from 'enspire-manager-framework';
import { PERMISSIONS } from 'components/constants';
import { Sheet } from 'react-modal-sheet';
import { confirmChangeStatus } from 'components/common/toolbox';
import { imageSize, numPanels } from 'components/common/toolbox';
import { navMultipleUrls } from 'components/common/toolbox';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

const _ = require('lodash');
const moment = require('moment');

export default function WorkOrderDashboard(props) {
    
    /* Hooks -------------------------------------------------------------------------------------------------------------------------*/
    
    const seg = 3;
    const params = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
    const routeLocation = useLocation();
    
	const admin = useSelector((store) => store.admin);
    const assets = useSelector((store) => store.assets.assets);
	const departments = useSelector((store) => store.settings.departments);
    const employees = useSelector((store) => store.employees.employees);
    const service_request = useSelector((state) => state.serviceRequests.service_request);
    const settings = useSelector((store) => store.settings.settings);
	const user = useSelector(store => store.users?.user);
	const user_permissions = useSelector(store => store.users?.user_permissions);
	const users = useSelector(store => store.users);
    const workOrders = useSelector((store) => store.workOrders);
    const work_order = useSelector((store) => store.workOrders.work_order);
    
	const [editTech, setEditTech] = useState(false);
	const [technicianId, setTechnicianId] = useState(null);
    
    // const asset = assets.find((asset) => asset.id == work_order?.assetId);
    const technician = employees.find((employee) => employee.id == work_order?.technicianId);
    const pathname = routeLocation.pathname;
	const segments = pathname.split('/').length;
    const department = departments.find((o) => o.id == work_order?.departmentId);
    const isCompleted = (parseInt(work_order?.statusId) >= parseInt(WORKORDERS.COMPLETE.id));
    const owner = _.find(employees, (o) => o.contact?.email == work_order?.ownerId);

    // Permissions ----------------------------------------

    const isComplete = parseInt(work_order?.statusId) >= parseInt(WORKORDERS.COMPLETE.id);
    const isTeamWO = (service_request?.techIds?.includes(user?.profileId) || service_request?.techIds?.includes(user?.email));
    const canViewMyWO = (
        work_order?.technicianId == user?.profileId || 
        work_order?.ownerId == user?.email ||
        (isTeamWO && user_permissions[PERMISSIONS.WORK_ORDERS_TEAM_VIEW.key])
    );
    const canEditWO = (
        user_permissions[PERMISSIONS.WORK_ORDERS_EDIT.key] || 
        work_order?.ownerId == user?.email ||
        (isTeamWO && user_permissions[PERMISSIONS.WORK_ORDERS_ASSIGNED_EDIT.key])
    );

    /* Effects -----------------------------------------------------------------------------------------------------------------------*/
    
    useEffect(() => {
        dispatch(actions_work_orders.subWorkOrderById(params.handle, params.work_order_id));
        dispatch(actions_work_orders.getWorkOrderCheckInTimes(params.handle, params.work_order_id));
        dispatch(actions_work_orders.subServiceItemsByWorkOrderID(params.handle, params.work_order_id));
        return () => {
            var unsubscribe = workOrders.work_order_unsubscribe;
            if (typeof unsubscribe === 'function') unsubscribe();
            var unsubscribe = workOrders.checkin_unsubscribe;
			if (typeof unsubscribe == 'function') unsubscribe();
            var unsubscribe = workOrders.work_order_items_unsubscribe;
            if (typeof unsubscribe === 'function') unsubscribe();
        };
    }, [params.work_order_id]);

    useEffect(() => {
		dispatch(actions_profiles.subProfile(params.handle, work_order?.technicianId, types.EMPLOYEE));
		return () => {
			let unsubscribe = employees.employee_unsubscribe;
			if (typeof unsubscribe === "function") unsubscribe();
		};
	}, [work_order?.technicianId]);

    useEffect(() => {
        setTechnicianId(work_order?.technicianId);
    }, [work_order]);

    useEffect(() => {
        if (work_order?.statusId == WORKORDERS.UNASSIGNED.id) {
            setEditTech(true);
        }
    }, [work_order?.statusId]);
    
    /* Handlers ----------------------------------------------------------------------------------------------------------------------*/

    const handleSpeedDial = (value, action) => {
        if (value == 'more') setShowBottomSheet(true);
        if (value == 'close') handleCheckin(WORKORDERS.CLOSED.id);
        if (value == 'convert') handleConvertQuote(WORKORDERS.CLOSED.id);
        if (value == 'complete') handleCheckin(WORKORDERS.COMPLETE.id);
        if (value == 'incomplete') handleIncomplete(WORKORDERS.INCOMPLETE.id);
        if (value == 'split') handleSplitWorkOrder();
        if (value == 'invoice') handleGenerateInvoice();
        if (value == 'inprogress') handleCheckin(WORKORDERS.INPROGRESS.id);
        if (value == 'onsite') handleCheckin(WORKORDERS.ONSITE.id);
        if (value == 'enroute') handleCheckin(WORKORDERS.ENROUTE.id);
        if (value == 'checkout') handleCheckin(WORKORDERS.PAUSED.id);
    }
    const toggleEditTech = () => {
        if (work_order?.statusId != WORKORDERS.UNASSIGNED.id) setEditTech(!editTech);
    }
    const handleTechUpdate = (event) => {
        dispatch(actions_work_orders.updateWorkOrder(params.handle, params.work_order_id, { technicianId: event.target.value}));
        setEditTech(false);
        
        const oldTech = employees.find((employee) => employee.id == work_order?.technicianId);
        const oldTechName = (oldTech) ? `${oldTech?.contact?.firstName} ${oldTech?.contact?.lastName}` : 'UNASSIGNED';
        const newTech = employees.find((employee) => employee.id == event.target.value);
        const newTechName = (newTech) ? `${newTech?.contact?.firstName} ${newTech?.contact?.lastName}` : 'UNASSIGNED';
        
        dispatch(actions_admin.saveActivity(params.handle, LOG.WORK_ORDER.key, ACTION.MODIFIED.key, params.work_order_id, 
            `${oldTechName} to ${newTechName}`, work_order));
    }
    const handleIncomplete = (statusId) => {
        ModalAlert({
            title: 'Mark as Incomplete',
            html: `What is the reason for the "Incomplete" status?`,
            icon: 'warning',
            label: 'Reason',
            input: 'text',
            validator: (value) => { if (!value) return 'Reason Required' },
            confirm_color: 'orange',
            confirm_text: 'Mark Incomplete',
            callback_success: (reason) => handleCheckin(statusId, reason),
        });
    };
    const handleGenerateInvoice = () => {
        let newLocation = toolbox.modifyPath(props.location.pathname, seg+2, 'invoice-form/0');
        history.push({ pathname: newLocation });
    };
    const handleSplitWorkOrder = () => {
        let newLocation = toolbox.modifyPath(props.location.pathname, seg+2, `split-work-order/${params.work_order_id}`);
        history.push({ pathname: newLocation });
    };
    const handleUndoComplete = () => {
        confirmChangeStatus('Work Order', WORKORDERS.ASSIGNED.name, () => {
            dispatch(actions_work_orders.updateWorkOrder(params.handle, params.work_order_id, { statusId: WORKORDERS.ASSIGNED.id, reason: '' }));
        });
    }
    const handleConvertQuote = () => {
        ModalAlert({
            title: 'Convert Work Order to Quote?',
            text: "The Work Order will be converted and you will be redirected to the new Quote.",
            icon: 'question',
            confirm_text: 'Okay',
            show_cancel: true,
            callback_success: () => { 
                var newQuote = {
                    days_to_expiration: 30,
                    description: work_order.description,
                    effective: new Date(),
                    ownerId: work_order.ownerId,
                    fromWorkOrderId: work_order.id,
                };
        
                dispatch(actions_quotes.saveNewQuote(params.handle, newQuote, service_request, null, user, (quoteId) => {
                    dispatch(actions_admin.saveActivity(params.handle, LOG.QUOTE.key, ACTION.CREATED.key, quoteId, newQuote.description, newQuote ));
                    dispatch(actions_work_orders.updateWorkOrder(params.handle, work_order.id, { statusId: WORKORDERS.CLOSED.id, isConverted: true }));
        
                    history.goBack();
                    navMultipleUrls({ actions_users, dispatch, history, numPanels, params, replace: true, user, urlArray: [
                        toolbox.modifyPath(routeLocation.pathname, seg+6, `quotes`, seg+6),
                        toolbox.modifyPath(routeLocation.pathname, seg+6, `quotes/${quoteId}/items`, seg+8),
                    ]});
                            
                }, { lineItems: work_order.serviceItems}));
            },
        });
    }
 
    /* Actions ------------------------------------------------------------------------------------------------------------------------------------*/
    
    const handleCheckin = (statusId, reason) => {

        if ([WORKORDERS.COMPLETE.id, WORKORDERS.CLOSED.id].includes(statusId) && checkedIn) {
            ModalAlert({
                title: 'Please Check Out',
                html: `You must first Check Out of this Work Order before it may be marked as Completed or Closed`,
                icon: 'warning',
                confirm_color: '#8FBC8B',
                confirm_text: 'Okay',
            });
        } else {
            if ([WORKORDERS.COMPLETE.id, WORKORDERS.CLOSED.id].includes(statusId)) {
                confirmChangeStatus('Work Order', (statusId == WORKORDERS.COMPLETE.id) ? 'Completed' : 'Closed', () => {
                    dispatch(actions_work_orders.updateWorkOrder(params.handle, work_order.id, { statusId }));
                });
            } else {
                dispatch(actions_work_orders.updateWorkOrderCheckin(params.handle, work_order, statusId, reason, user, () => {
                    if (statusId != WORKORDERS.PAUSED.id && statusId != WORKORDERS.INCOMPLETE.id) {
                        dispatch(actions_timeclock.clockIn(params.handle, users));
                    } else if (settings?.timeclock?.checkinTargetRequired) {
                        dispatch(actions_timeclock.clockOut(params.handle, users));
                    }
                }));
            }
        }    
    };

    // const openHelpPanel = () => {
    //     if (window.flutter) {
    //         window.flutter.callHandler('helpPanel', '/servicing-assets.md/work-orders#add-a-locale-dropdown'); 
    //     } else {
    //         dispatch(actions_admin.showHelp(true, '/servicing-assets.md/work-orders#add-a-locale-dropdown'));
    //     }
	// }
	// const toggleLightbox = () => {
	// 	let imagesAlt = work_order.imagesAlt?.map((imageUrl, index) => {
	// 		return ({ src: imageSize(imageUrl, 'lg') });
	// 	});
	// 	if (!admin.lightbox_show) dispatch(actions_admin.lightboxSources([
	// 		{ src: imageSize(work_order.imageUrl, 'lg') },
	// 		...(work_order.imagesAlt) ? imagesAlt : []
	// 	]));
	// 	dispatch(actions_admin.lightboxShow(!admin.lightbox_show));
	// }
    
    const setShowBottomSheet = (value) => {
        dispatch(actions_admin.bottomSheetShow(value));
    }
    const openForm = () => {
        let location = toolbox.modifyPath(pathname, seg+2, 'work-order-form/' + params.work_order_id);
        history.push({ pathname: location });
    };
    const openEntity = () => {
        const entity = (params.appId == APPS.SERVICE.id) ? 'customers' : 'assets';
        const entityId = (params.appId == APPS.SERVICE.id) ? service_request.customerId : service_request.assetId;
        const tab = (params.appId == APPS.SERVICE.id) ? 'tab' : 'service_requests';

        navMultipleUrls({ actions_users, dispatch, history, numPanels, params, user, urlArray: [
            toolbox.modifyPath( routeLocation?.pathname, 3, `${entity}`, 3),
            toolbox.modifyPath( routeLocation?.pathname, 3, `${entity}/${entityId}/form/0/${tab}`, 7),
        ]});    
    }
    const viewSchedule = () => {
        let newLocation = toolbox.modifyPath(routeLocation.pathname, 3, 'schedule/' + moment(work_order.startDate.seconds, 'X').format('YYYY-MM-DD') + '/' + work_order.technicianId, 5);
        history.push({ pathname: newLocation });
    };

    /* Constants ------------------------------------------------------------------------------------------------------------------------------------*/

    const name = (technician) ? technician.contact?.firstName + ' ' + technician.contact?.lastName : 'Unassigned';
    const checkedIn = (users.work_order_checked_in?.id == params.work_order_id)
    const canCheckIn = (
        technician?.contact?.email == user.id && 
        users.work_order_checked_in?.id != params.work_order_id && 
        [WORKORDERS.ASSIGNED.id, WORKORDERS.PAUSED.id, WORKORDERS.INCOMPLETE.id].includes(work_order?.statusId)
    );
    const offsiteEnabled = (params.appId == APPS.ASSETS.id) ? work_order?.offsite : true;
    const converted = (work_order?.isConverted) ? 'CONVERTED TO QUOTE' 
        : (work_order?.fromQuoteId) ? 'CONVERTED FROM QUOTE' : '';

    var technicians = [];
	for (let employee of Object.values(employees)) {
		if (employee.roles?.includes(ROLES.TECHNICIAN.id)) technicians.push({ ...employee });
	}

    const technician_options = _.orderBy(technicians, ['lastName'], ['asc']).map((tech, index) => {
		return (
			<option key={index} value={tech.id}>{tech.contact?.firstName + ' ' + tech.contact?.lastName}</option>
		);
	});

    const custAvatar = 
        <div role="button" onClick={openEntity}>
            <Avatar
                className="float-right"
                color="white"
                bgColor="saddleBrown"
                size={45}
                fontSize={'10px'}
                name={(params.appId == APPS.SERVICE.id) ? service_request?._displayName : service_request?._name }
                image={imageSize(service_request?._imageUrl, 'sm')}
                border="3px solid white"
            />
        </div>

    /* Render ------------------------------------------------------------------------------------------------------------------------------------*/
   
    return (

		<div className="row position-relative">
            {work_order
                ?   <Authorize perm={ PERMISSIONS.WORK_ORDERS_VIEW } or={ canViewMyWO } show403>
                        {/* <div className="position-absolute animated fadeIn animation-delay-3" style={{ top: '62px', left: '18px', zIndex: 100 }}>
                            { custAvatar }
                        </div>
                        <div className="position-absolute animated fadeIn animation-delay-4" style={{ top: '62px', left: '60px', zIndex: 100 }}>
                            { serviceAvatar }
                        </div> */}
                        <div id="workorder-avatar" className={ 'col-12 mb-3' }>
                            <div className="profile-image">
                                <div className="mb-2">
                                    <Avatar
                                        color="white"
                                        bgColor="steelblue"
                                        size={90}
                                        name={name}
                                        image={technician?.contact.photoUrl ? imageSize(technician?.contact.photoUrl, 'sm') : null}
                                        border='4px solid white'
                                    />
                                </div>
                            </div>
                            <div className="profile-info pt-1 pr-2">
                                <div className="d-flex">
                                    <h1 className="main-heading heading-work-order align-self-start">#{work_order.customId} Work Order</h1>
                                    {segments < seg+10 && !window.flutter &&
                                        <div className={ 'animated fadeIn mt-n2 ml-3' } style={{ cursor: 'pointer', height: '10px' }} onClick={() => { history.goBack(); }}>
                                            <i className="fas fa-times" style={{ fontSize: '38px', color: '#bbbbbb' }}></i>
                                        </div>
                                    }
                                </div>
                                { !editTech
                                    ?   <div className="ml-2">
                                            <h1 className="mt-1 mb-0">{name}</h1>
                                            <h4>{technician?.contact?.email}</h4>
                                            <span className={ 'badge' }>{department?.value}</span>
                                            { work_order?.offsite && <span className="badge badge-primary">Asset is OFFSITE</span> }
                                        </div>
                                    :   <Select
                                            className="col-12 pl-0 mt-3"
                                            name={technicianId}
                                            noLabel={true}
                                            onChange={handleTechUpdate}
                                            onBlur={toggleEditTech}
                                            value={technicianId}
                                        >
                                            <option value="unassigned">UNASSIGNED</option>
                                            { technician_options }
                                        </Select>
                                }
                            </div>
                        </div>

                        {/* BUTTON ROWN ------------------------------------------------------------- */}

                        <div className="col-12">
                            <button type="button" 
                                className={'btn btn-default btn-sm mr-2' }
                                disabled={!canEditWO}
                                onClick={openForm}><i className="fa-regular fa-gear fa-lg text-muted mr-1"></i>Edit Work Order
                            </button>
                            <StatusSelect className="mr-2"
                                selectedStatusId={work_order.statusId}
                                statusTable={'WORKORDERS'}
                                statusTypes={Object.values(WORKORDERS)}
                                static={true}
                            />
                            { !numPanels(1) && <button className="btn btn-info btn-sm" onClick={viewSchedule}>
                                <i className="fa-solid fa-calendar-days mr-1"></i> View on Schedule</button> }
                        </div>

                        {/* EXPANDABLE DETAILS ------------------------------------------------------ */}

                        <div className="col-12">
                            <ExpandDetails more="Show More">
                                <div id="work-order-details" className="mt-3 mb-2">
                                    { params.appId == APPS.SERVICE.id
                                        ?   <DashboardDetail field={ custAvatar } value={ <span>{service_request._displayName}<br/>{work_order._address}</span> } 
                                                type={ (props.source == 'dashboard') ? 'jsx' : 'text' }
                                                jsx={ <TooltipOverlay tooltip={ "View Customer" }>
                                                        <i className="fas fa-external-link-square fa-2x mr-2 text-muted" role="button" onClick={openEntity}></i> 
                                                    </TooltipOverlay>}
                                            />
                                        :   <DashboardDetail field={ custAvatar } value={ `#${service_request._unitNumber} ${service_request._name}` }
                                                type={ (props.source == 'dashboard') ? 'jsx' : 'text' }
                                                jsx={ <TooltipOverlay tooltip={ "View Asset" }>
                                                        <i className="fas fa-external-link-square fa-2x mr-2 text-muted" role="button" onClick={openEntity}></i>
                                                    </TooltipOverlay>}
                                            />
                                    }
                                    <DashboardDetail field={ 'Start Date' } value={ moment(work_order.startDate?.seconds, 'X').format('MMM DD, YYYY h:mm A') } />
                                    <DashboardDetail field={ 'Description' } value={ work_order.description } type="text" />
                                    <DashboardDetail field={ 'Suggested' } value={ work_order.suggested } type="text" />
                                    <DashboardDetail field={ 'Converted' } value={ converted } type="text" />
                                    <DashboardDetail field={ 'Created By' } value={ (owner) ? `${owner?.contact.firstName} ${owner?.contact.lastName}` : '' } type="text" />
                                    <div className="clearfix"></div>
                                </div>
                            </ExpandDetails>

                            { work_order.reason &&
                                <div className="alert alert-warning mt-3">
                                    <strong>REASON:</strong>&nbsp; { work_order.reason }
                                </div>
                            }
                            { work_order.deleted &&
                                <div className="alert alert-danger mt-3 mb-0 d-flex align-items-center">
                                    <i className="fa-solid fa-circle-xmark fa-2x mr-3"></i>
                                    <span>
                                        This Work Order has been <strong>ARCHIVED!</strong> &nbsp; You may be able to restore it from the <strong>Archive</strong> page on the main menu
                                    </span>
                                </div>
                            }
                            { canCheckIn &&
                                <div className="alert alert-info mt-3 mb-0 d-flex align-items-center">
                                    <i className="fa-solid fa-circle-info fa-2x mr-3"></i>
                                    <span>
                                        You may <strong>Check In</strong> from the <strong>Actions</strong> button at lower right
                                    </span>
                                </div>
                            }
                            { checkedIn &&
                                <div className="alert alert-success mt-3 mb-0 d-flex align-items-center">
                                    <i className="fa-solid fa-circle-info fa-2x mr-3"></i>
                                    <span>
                                        You are <strong>Checked In</strong> to this Work Order
                                    </span>
                                </div>
                            }
                            { service_request?.statusId == REQUESTS.COMPLETE.id && numPanels(1) &&
                                <div className="alert alert-info mt-3 mb-0 d-flex align-items-center">
                                    <i className="fa-solid fa-circle-info fa-2x mr-3"></i>
                                    <span>
                                        This Work Orders on the Service Request have been Completed!  The next step is to generate an <strong>Invoice</strong> from the <strong>Actions</strong> button at lower right
                                    </span>
                                </div>
                            }
                            { isCompleted && work_order?.statusId != WORKORDERS.CLOSED.id &&
                                <div className="alert alert-info d-flex align-items-center">
                                    <i className="fa-solid fa-lock fa-xl mr-3"></i>
                                    <span>Work Order Complete!</span>
                                    <button className="btn btn-info btn-sm float-right" style={{ marginLeft: 'auto' }} onClick={ handleUndoComplete }>Undo Complete</button>
                                </div>
                            }

                            { canEditWO && !admin.bottom_sheet_show && params.form == 'form' &&
                                <SpeedDialButton 
                                    columnRef={ props.columnRef }
                                    onClick={handleSpeedDial}
                                    actions={[
                                    ...(!isComplete && user_permissions[PERMISSIONS.WORK_ORDERS_CLOSE.key]) ? [{
                                        name: 'Close Work Order', 
                                        value: 'close', 
                                        className: '', 
                                        icon: <i className="fa-solid fa-folder-closed fa-xl"></i>
                                    }] : [],
                                    ...(!isComplete) ? [{
                                        name: 'Convert to Quote', 
                                        value: 'convert', 
                                        className: '', 
                                        icon: <i className="fa-solid fa-message-plus fa-xl"></i> 
                                    }] : [],
                                    ...(work_order.statusId == WORKORDERS.COMPLETE.id) ? [{
                                        name: 'Generate Invoice', 
                                        value: 'invoice', 
                                        className: '', 
                                        icon: <i className="fas fa-dollar-sign fa-xl"></i> 
                                    }] : [],
                                    ...(!isComplete && ![WORKORDERS.INCOMPLETE.id, WORKORDERS.COMPLETE.id].includes(work_order.statusId)) ? [{
                                        name: 'Mark Incomplete', 
                                        value: 'incomplete', 
                                        className: 'bg-warning', 
                                        icon: <i className="fa-solid fa-exclamation fa-xl"></i> 
                                    }] : [],
                                    ...(!isComplete && work_order.statusId != WORKORDERS.COMPLETE.id) ? [{
                                        name: 'Mark Complete', 
                                        value: 'complete', 
                                        className: 'bg-success', 
                                        icon: <i className="fa-regular fa-check fa-xl"></i> 
                                    }] : [],
                                    ...(!isComplete && work_order?.isConvertedQuote && workOrders.work_order_items.length > 1) ? [{
                                        name: 'Split Work Order', 
                                        value: 'split', 
                                        className: '', 
                                        icon: <i className="fa-regular fa-split fa-xl"></i> 
                                    }] : [],
                                    ...(work_order.statusId != WORKORDERS.INPROGRESS.id && ((canCheckIn && !offsiteEnabled) || (checkedIn && !offsiteEnabled))) ? [{
                                        name: `Check In In-Progress`, 
                                        value: 'inprogress', 
                                        className: '', 
                                        icon: <i className="fa-solid fa-arrow-right-to-bracket fa-rotate-90 fa-xl"></i>
                                    }] : [],
                                    ...(work_order.statusId != WORKORDERS.ENROUTE.id && ((canCheckIn && offsiteEnabled) || (checkedIn && offsiteEnabled))) ? [{
                                        name: `Check In Enroute`, 
                                        value: 'enroute', 
                                        className: '', 
                                        icon: <i className="fas fa-route fa-xl"></i>
                                    }] : [],
                                    ...(work_order.statusId != WORKORDERS.ONSITE.id && ((canCheckIn && offsiteEnabled) || (checkedIn && offsiteEnabled))) ? [{
                                        name: `Check In Onsite`, 
                                        value: 'onsite', 
                                        className: '', 
                                        icon: <i className="fas fa-map-marker-alt fa-xl"></i> 
                                    }] : [],
                                    ...(checkedIn) ? [{
                                        name: `Check Out`, 
                                        value: 'checkout', 
                                        className: '', 
                                        icon: <i className="fas fa-arrow-right-from-bracket fa-xl fa-rotate-270"></i> 
                                    }] : [], 
                                    ]}
                                />
                            }

                            <Sheet isOpen={admin.bottom_sheet_show} onClose={() => setShowBottomSheet(false)} detent="content-height">
                                <Sheet.Container >
                                    <Sheet.Header />
                                    <Sheet.Content>
                                        <h2>More Options</h2>
                                        {/* <button className="btn btn-info btn-xl mb-2" onClick={ () => openBuilder(SERVICE_ITEMS.LABOR.id) }>LABOR</button>
                                        <button className="btn btn-primary btn-xl mb-2" onClick={ () => openBuilder(SERVICE_ITEMS.STOCK_PARTS.id) }>STOCK PARTS</button>
                                        <button className="btn btn-primary btn-xl mb-2" onClick={ () => openBuilder(SERVICE_ITEMS.NON_STOCK_PARTS.id) }>NON-STOCK PARTS</button>
                                        <button className="btn btn-primary btn-xl mb-2" onClick={ () => openBuilder(SERVICE_ITEMS.TRAVEL.id) }>TRAVEL</button>
                                        <button className="btn btn-default btn-xl mb-2" onClick={ () => setShowBottomSheet(false) }>CANCEL</button> */}
                                    </Sheet.Content>
                                </Sheet.Container>
                                <Sheet.Backdrop onTap={ () => setShowBottomSheet(false) } />
                            </Sheet>

                        </div>
                    </Authorize>
                : <Spinner center />
            }
        </div>
    );
}

import React from 'react';
import { Spinner } from 'enspire-manager-framework';

const useEmulator = false;

const QuickbooksConnectPopup = (props) => {

    React.useEffect(() => {
        setTimeout(() => {
            var  currentUser = props.firebase.auth().currentUser;
            if ( currentUser  ) currentUser.getIdToken().then((token) => {
                console.log(token);
                window.location = (useEmulator)
                ?   'http://localhost:5001/mobiletrack-enterprise/us-central1/authenticateQuickbooks/requestToken/' + props.match.params.handle + '/' + token
                :   'https://us-central1-mobiletrack-enterprise.cloudfunctions.net/authenticateQuickbooks/requestToken/' + props.match.params.handle + '/' + token;
            });
        }, 500);
    }, []);

    return (
        <div>
            <h2 className="text-center mb-4" style={{ marginTop: '200px' }}>Quickbooks Connect...</h2>
            <Spinner />
        </div>
    );
};

export default QuickbooksConnectPopup;

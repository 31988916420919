import * as actions_profiles from 'actions/profiles-actions';
import * as actions_quotes from 'actions/quotes-actions';
import * as actions_users from 'actions/users-actions';
import * as toolbox from 'components/common/toolbox';
import ExpandDetails from 'components/common/expand-details';
import React, { useEffect, useState } from 'react';
import ResultsActionIcons from './results-actions/results-action-icons';
import _ from 'lodash';
import { APPS, QUOTES } from 'components/constants';
import { Avatar } from 'enspire-manager-framework';
import { DashboardDetail } from 'enspire-manager-framework';
import { Table } from 'em-table';
import { imageSize } from 'components/common/toolbox';
import { navMultipleUrls, numPanels } from 'components/common/toolbox';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

var moment = require('moment');

const ResultsQuotes = (props) => {
    
    /* Hooks -----------------------------*/
    
    const params = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
	const routeLocation = useLocation();

    const employees = useSelector((store) => store.employees.employees);
    const profiles = useSelector((store) => store.profiles);
    const quotes = useSelector((store) => store.quotes);;
    const user = useSelector((store) => store.users.user);

    const [data, setData] = useState([]);

    /* UseEffect --------------------------*/

    useEffect(() => {
        if (params.widget_id == 'dashboard') {
            dispatch(actions_quotes.clearDashboardQuotes());
        } else {
            dispatch(actions_quotes.subDashboardQuotes(params.handle, params.appId, params.widget_id));
        }
        
        return () => {
			const unsubscribe = quotes.quotes_dashboard_unsubscribe;
			if (typeof unsubscribe == 'function') unsubscribe();
		};
    }, [params.handle, params.appId, params.widget_id]);

    useEffect(() => {
		if (parseInt(params.customer_id)) dispatch(actions_profiles.subProfile(params.handle, params.customer_id, 'PROFILE'));
		return () => {
			let unsubscribe = profiles.profile_unsubscribe;
			if (typeof unsubscribe === "function") unsubscribe();
			dispatch(actions_profiles.clearProfile());
		};
	}, [params.customer_id]);

    useEffect(() => {    
        var quotes_array = [];
        var currentEntityId = null;
        quotes.quotes_dashboard?.forEach((quote) => {
            
            const entityId = (params.appId == APPS.SERVICE.id) ? quote.customerId : quote.assetId;
            const status = _.find(Object.values(QUOTES), { id: quote.statusId });
            const owner = _.find(employees, (o) => o.contact?.email == quote?.ownerId);

            // Add grouip heading for customer or asset
            if (entityId != currentEntityId) {
                currentEntityId = entityId;
                quotes_array.push({ ...quote,
                    status: status?.name,
                    _jsx:
                    <div className="d-flex align-items-center justify-content-between p-2" style={{ backgroundColor: '#cccccc' }}>
                        <div role="button" onClick={() => openEntity(quote)}>
                            <Avatar className=""
                                color="white"
                                bgColor="saddleBrown"
                                size={45}
                                fontSize={'20px'}
                                name={(params.appId == APPS.SERVICE.id) ? quote._displayName : quote._name}
                                image={imageSize(quote._imageUrl, 'sm')}
                                imageWidth={60}
                                border="4px solid white"
                                role={ (quote._imageUrl) ? "button" : null }
                                style={{ flexShrink: 0 }}
                            />
                        </div>
                        <div className="flex-grow-1">
                            <h2 className="ml-3 m-0 text-black font-weight-normal">{
                                ((params.appId == APPS.SERVICE.id) ? quote._displayName : quote._name) + 
                                ((quote._unitNumber) ? ' #' + quote._unitNumber : '')
                            }</h2>
                            { quote._address &&
                                <p className="ml-3 m-0">{ quote._address }</p>
                            }
                        </div>
                        <ResultsActionIcons />
                    </div> 
                });
            }
            
            quotes_array.push({ ...quote,
                status: status?.name,
                _stripe_color: status?.color,
                details: <>
                    <h4>{`QT #${quote.customId}`}</h4>
                    <ExpandDetails className='mt-1' default={ 'closed' } top={ -35 } eTop={ 30 } eRight={ 5 } noMore>
                        <div id="quote-details" className="mt-2 mb-2">
                            <DashboardDetail field={ 'Technician Id' } value={ (quote.technicianId != "unassigned") ? quote.technicianId : "UNASSIGNED" } />
                            <DashboardDetail field={ 'Effective Date' } value={ moment(quote.effective?.seconds, 'X') } format='MMM DD, YYYY' type="moment" />
                            <DashboardDetail field={ 'Expiration Date' } value={ moment(quote.expiration?.seconds, 'X') } format='MMM DD, YYYY' type="moment" />
                            <DashboardDetail field={ 'Start Date' } value={ moment(quote.startDate?.seconds, 'X')} format='MMM DD, YYYY h:mm A' type="moment" />
                            <DashboardDetail field={ 'End Date' } value={ moment(quote.endDate?.seconds, 'X')} format='MMM DD, YYYY h:mm A' type="moment" />
                            <DashboardDetail field={ 'Description' } value={ quote.description } type="text" />
                            <DashboardDetail field={ 'Created By' } value={ `${owner?.contact.firstName} ${owner?.contact.lastName}` } type="text" />
                            <div className="clearfix"></div>
                        </div>
                    </ExpandDetails>
                </>
            });
        });
        setData(quotes_array);
    }, [quotes.quotes_dashboard]);

    /* Actions ----------------------------*/

    const openQuote = async (quote) => {
        let entityId = (params.appId == APPS.SERVICE.id) ? quote.customerId : quote.assetId;
        navMultipleUrls({ actions_users, dispatch, history, numPanels, params, user, urlArray: [
            toolbox.modifyPath( routeLocation?.pathname, 4, `${entityId}/form/0/service_requests/${quote.serviceRequestId}/quotes`, 9),
            toolbox.modifyPath( routeLocation?.pathname, 4, `${entityId}/form/0/service_requests/${quote.serviceRequestId}/quotes/${quote.id}/items`, 11),
        ]});
    }
    const openEntity = (quote) => {
        const entity = (params.appId == APPS.SERVICE.id) ? 'customers' : 'assets';
        const entityId = (params.appId == APPS.SERVICE.id) ? quote.customerId : quote.assetId;
        const tab = (params.appId == APPS.SERVICE.id) ? 'tab' : 'service_requests';

        navMultipleUrls({ actions_users, dispatch, history, numPanels, params, user, urlArray: [
            toolbox.modifyPath( routeLocation?.pathname, 3, `${entity}`, 3),
            toolbox.modifyPath( routeLocation?.pathname, 3, `${entity}/${entityId}/form/0/${tab}`, 7),
        ]});    
    }

    /* Constants --------------------------*/

    const columns = [
        { name: 'Details', field: 'details', type: 'jsx', width: 100 },
        { name: 'Summary', field: 'description' },
    ];

    return (

        <Table
            data={data}
            columns={columns}
            active_field={'id'}
            active_id={params.tab2_id}
            second_line={'description'}
            group_by={{ fields: ['statusId'], direction: ['desc'], display: ['status'] }}
            hide_header={true}
            click_callback={ openQuote }
            chevron={ true }
        >
        </Table>
    );
};

export default ResultsQuotes;
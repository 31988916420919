import * as actions_admin from 'actions/admin-actions';
import * as actions_assets from 'actions/assets-actions';
import * as actions_authentication from 'actions/authentication-actions';
import * as actions_timeclock from 'actions/timeclock-actions';
import * as actions_users from 'actions/users-actions';
import * as toolbox from 'components/common/toolbox';
import Authorize from 'components/common/authorize';
import NavbarSearch from './navbar-search';
import React, { useEffect, useState } from 'react';
import StatusModal from 'components/common/status-modal';
import _ from 'lodash';
import store from 'store';
import { APPS, PERMISSIONS, ROLES } from 'components/constants';
import { Link } from "react-router-dom";
import { Overlay, OverlayTrigger, Popover  } from 'react-bootstrap';
import { numPanels } from 'components/common/toolbox';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import Lightbox from "yet-another-react-lightbox";
import { Thumbnails, Zoom } from "yet-another-react-lightbox/plugins";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";

const moment = require('moment');

export default function Navbar(props) {
    
    const seg = 2;
	const params = useParams();
	const history = useHistory();
    const dispatch = useDispatch();
	const routeLocation = useLocation();
    
    const admin = useSelector((store) => store.admin);
    const user = useSelector((store) => store.users.user);
    const users = useSelector((store) => store.users);
    const company = useSelector((store) => store.company.company);
    const statusModal = useSelector((store) => store.settings.statusModal);
    
    const segments = routeLocation.pathname.split('/');
    const clockedIn = users.time_clock.clockedIn;
    const taskCheckedIn = users.task_checked_in;
    const workOrderCheckedIn = users.work_order_checked_in;
    
    const [focusSearch, setFocusSearch] = useState(false);

    const flutter = window.flutter;

    /* Hooks --------------------------*/
    
    useEffect(() => {
        if (company?.appIds?.length && !company?.appIds?.includes(params.appId)) {
            selectApp(company.appIds[0]);
        } else {
            var bodyStyles = document.body.style;
            bodyStyles.setProperty('--theme-primary', `var(--theme${params.appId}-primary)`);
            bodyStyles.setProperty('--theme-primary-hover', `var(--theme${params.appId}-primary-hover)`);
            bodyStyles.setProperty('--theme-secondary', `var(--theme${params.appId}-secondary)`);
            bodyStyles.setProperty('--theme-alternate', `var(--theme${params.appId}-alternate)`);
        }
    }, [params.appId]);
    
    /* Actions --------------------------*/

    const logOut = (event) => {
        event.preventDefault();
        store.dispatch(actions_authentication.logout());
    }
    const selectApp = (appId) => {
        if (!user.dismissed?.appNotice) {
            toolbox.switchAppsNotice((isDismissed) => {
                if (isDismissed) dispatch(actions_users.dismissNotice(params.handle, user.email, 'appNotice'));
            }, () => proceed());
        } else proceed();
        
        function proceed() {
            dispatch(actions_admin.appChanged(appId));
            toolbox.selectApp(appId, routeLocation, history);
        }
    }
    const selectLocation = (handle) => {
        if (!user.dismissed?.locationNotice) {
            toolbox.switchLocationNotice((isDismissed) => {
                if (isDismissed) dispatch(actions_users.dismissNotice(params.handle, user.email, 'locationNotice'));
            }, () => proceed());
        } else proceed();

        function proceed() {
            dispatch(actions_assets.selectCustomer(params.handle, null));
            dispatch(actions_assets.selectAssetType(params.handle, null));
            toolbox.selectLocation(handle, routeLocation, history);
        }
    }
    const openHelp = () => dispatch(actions_admin.showHelp(true, '/getting-started/'));
    const MobileLink = (props) => 
        <li className="nav-item">
            <Link replace={ !props.push } id={props.id} className={props.className} to={props.to} data-toggle={ (flutter)?'':'collapse'} data-target=".navbar-collapse">{props.name} </Link>
        </li>
    const handleClockOut = () => {
        dispatch(actions_timeclock.clockOut(params.handle, users));
    };
    const popover = (
        <Popover id="popover-basic">
            {/* <Popover.Title as="h3">Popover right</Popover.Title> */}
            <Popover.Content className="bg-white">
                <button className="btn btn-danger btn-sm px-3" onClick={ handleClockOut }>CLOCK OUT</button>
            </Popover.Content>
        </Popover>
    );

    return (

        <div className="row border-bottom white-bg" style={{ marginLeft: (props.hideMenu) ? '0':'220px', ...(flutter)?{display: 'none'}:{} }}>
            <nav className="navbar navbar-expand-lg navbar-static-top" 
                style={{ justifyContent: 'normal', ...(props.hideMenu) ? { backgroundColor: '#a68d42' } : {}}} 
                role="navigation"
            >
                <span className="position-relative" style={{ flexWrap: 'nowrap' }}>
                    <button className="navbar-brand" style={{ border: 'none' }}>
                        <a className="navbar-toggler collapsed m-0 p-0 pr-4" type="button" data-toggle="collapse" data-target="#navbar">
                            <i className="fa fa-bars" style={{ fontSize: '18px' }}></i>
                        </a>
                        <img src="images/logo-white-small.png" height="25" />
                        {process.env.REACT_APP_FIREBASE_ENV == 'development' &&
                            <small style={{ color: 'red' }}>{process.env.REACT_APP_FIREBASE_ENV?.toUpperCase()}</small>
                        }
                    </button>
                </span>

                <div className="navbar-collapse collapse" id="navbar">
                    <ul className="navbar-nav mr-auto">
                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" href="#" role="button" data-toggle="dropdown" aria-expanded="false">
                                { _.find(user?.locations, { handle: params.handle })?.name }&nbsp;
                            </a>
                            <div className="dropdown-menu">
                                {
                                    user?.locations?.map((location, index) => {
                                        return (
                                            <a key={ location.handle } className="dropdown-item" onClick={ () => selectLocation(location.handle) }>{ location.name }</a>
                                        );
                                    })
                                }
                            </div>
                        </li>
                        { company?.appIds?.includes(APPS.SERVICE.id) &&
                            <li className={ `nav-item ${(params.appId == APPS.SERVICE.id)?'active':''}` } onClick={ () => selectApp(APPS.SERVICE.id) }>
                                <a className="nav-link">Service Manager</a>
                            </li>
                        }
                        { company?.appIds?.includes(APPS.ASSETS.id) &&
                            <li className={ `nav-item ${(params.appId == APPS.ASSETS.id)?'active':''}` } onClick={ () => selectApp(APPS.ASSETS.id) }>
                                <a className="nav-link">Asset Manager</a>
                            </li>
                        }
                        { company?.appIds?.includes(APPS.LOGISTICS.id) &&
                            <li className={ `nav-item ${(params.appId == APPS.LOGISTICS.id)?'active':''}` } onClick={ () => selectApp(APPS.LOGISTICS.id) }>
                                <a className="nav-link">Logistics Manager</a>
                            </li>
                        }
                        { clockedIn &&
                            <OverlayTrigger trigger="click" placement="bottom" overlay={popover}>
                                <span className="fa-stack animated infinite fa-beat-fade ml-3 mt-2 pt-1" role="button">
                                    <i className="fas fa-circle fa-stack-2x text-success"></i>
                                    { taskCheckedIn && <i className="fas fa-check fa-stack-1x fa-inverse"></i> }
                                    { workOrderCheckedIn && <i className="fa-solid fa-arrow-right-to-bracket fa-rotate-90 fa-stack-1x fa-inverse"></i> }
                                    { !taskCheckedIn && !workOrderCheckedIn && <i className="far fa-clock fa-stack-1x fa-inverse"></i> }
                                </span>
                            </OverlayTrigger>
                        }

                    </ul>

                    <ul className="nav navbar-nav mr-auto bg-white">    
                        { props.hideMenu &&
                            <>
                                {/* invisible link to add slash to URL so that returning to the same link is reloaded */}
                                <Link style={{ display: 'none' }} replace={ false } id={'link-slash'} to={`/${props.handleApp}/dashboard/`}>Slash </Link>

                                <MobileLink name="Dashboard" id="link-dashboard" to={`/${props.handleApp}/dashboard`} />
                                <Authorize appId={ APPS.ASSETS.id } perm={ PERMISSIONS.ASSETS_VIEW }>
                                    <MobileLink name="Assets" id="link-assets" to={`/${props.handleApp}/assets`} />
                                </Authorize>
                                <Authorize perm={ PERMISSIONS.CUSTOMERS_VIEW }>
                                    <MobileLink name="Customers" id="link-customers" to={`/${props.handleApp}/customers`} />
                                </Authorize>
                                <Authorize perm={ PERMISSIONS.INVENTORY_VIEW }>
                                    <MobileLink name="Service" id="link-service" to={`/${props.handleApp}/service`} />
                                </Authorize>
                                <Authorize perm={ PERMISSIONS.INVENTORY_VIEW }>
                                    <MobileLink name="Inventory" id="link-inventory" to={`/${props.handleApp}/inventory`} />
                                </Authorize>
                                <Authorize perm={ PERMISSIONS.VENDORS_VIEW }>
                                    <MobileLink name="Vendors" id="link-vendors" to={`/${props.handleApp}/vendors`} />
                                </Authorize>
                                <Authorize perm={ PERMISSIONS.SCHEDULE_VIEW }>
                                    <MobileLink name="Schedule" id="link-schedule" to={`/${props.handleApp}/schedule/${moment().format('YYYY-MM-DD')}`} />
                                </Authorize>
                                <Authorize perm={ PERMISSIONS.EMPLOYEES_VIEW }>
                                    <MobileLink name="Employees" id="link-employees" to={`/${props.handleApp}/employees`} />
                                </Authorize>
                                <li className="nav-item dropdown">
                                    <a className="nav-link dropdown-toggle" id="settings-submenu" data-toggle="dropdown">Settings</a>
                                    <ul className="dropdown-menu" aria-labelledby="settings-submenu">
                                        <Authorize perm={ PERMISSIONS.SETTINGS_COMPANY }>
                                            <MobileLink push={ segments[2] !== 'settings' } name="Company" id="link-settings-company" to={`/${props.handleApp}/settings/company`} />
                                        </Authorize>
                                        <Authorize perm={ PERMISSIONS.SETTINGS_DEPARTMENTS }>
                                            <MobileLink push={ segments[2] !== 'settings' } name="Departments" id="link-settings-departments" to={`/${props.handleApp}/settings/departments`} />
                                        </Authorize>
                                        <Authorize perm={ PERMISSIONS.SETTINGS_TIME_CLOCK }>
                                            <MobileLink push={ segments[2] !== 'settings' } name="Time Clock" id="link-settings-timeclock" to={`/${props.handleApp}/settings/timeclock`} />
                                        </Authorize>
                                        <Authorize perm={ PERMISSIONS.SETTINGS_ASSETS }>
                                            <MobileLink push={ segments[2] !== 'settings' } name="Assets" id="link-settings-assets" to={`/${props.handleApp}/settings/assets`} />
                                        </Authorize>
                                        <Authorize perm={ PERMISSIONS.SETTINGS_INVENTORY }>
                                            <MobileLink push={ segments[2] !== 'settings' } name="Inventory" id="link-settings-inventory" to={`/${props.handleApp}/settings/inventory`} />
                                        </Authorize>
                                        <Authorize perm={ PERMISSIONS.SETTINGS_SERVICE }>
                                            <MobileLink push={ segments[2] !== 'settings' } name="Service" id="link-settings-service" to={`/${props.handleApp}/settings/service`} />
                                        </Authorize>
                                        <Authorize perm={ PERMISSIONS.SETTINGS_QUOTES }>
                                            <MobileLink push={ segments[2] !== 'settings' } name="Quotes" id="link-settings-quotes" to={`/${props.handleApp}/settings/quotes`} />
                                        </Authorize>
                                        <Authorize perm={ PERMISSIONS.SETTINGS_INVOICES }>
                                            <MobileLink push={ segments[2] !== 'settings' } name="Invoices" id="link-settings-invoices" to={`/${props.handleApp}/settings/invoices`} />
                                        </Authorize>
                                        <Authorize perm={ PERMISSIONS.SETTINGS_INVOICES }>
                                            <MobileLink push={ segments[2] !== 'settings' } name="Taxes" id="link-settings-taxes" to={`/${props.handleApp}/settings/taxes`} />
                                        </Authorize>
                                        <Authorize roleId={ ROLES.OWNER.id }>
                                            <MobileLink push={ segments[2] !== 'settings' } name="Permissions" id="link-settings-permissions" to={`/${props.handleApp}/settings/permissions`} />
                                        </Authorize>
                                        <Authorize perm={ PERMISSIONS.ACTIVITY_LOGS_VIEW }>
                                            <MobileLink push={ segments[2] !== 'settings' } name="Activity Logs" id="link-settings-activity" to={`/${props.handleApp}/settings/activity-log`} />
                                        </Authorize>
                                        <MobileLink push={ segments[2] !== 'settings' } name="User Preferences" id="link-user-prefs" to={`/${props.handleApp}/settings/user_preferences`} />
                                        <MobileLink push={ segments[2] !== 'settings' } name="Delete Account" id="link-delete-account" to={`/${props.handleApp}/account_delete`} className="text-danger" />
                                    </ul>
                                </li>
                                <li className="nav-item"><p className="float-right px-4">{ window.build }</p></li>
                            </> 
                        }
                    </ul>
                    { numPanels(2) &&
                        <div className="navbar-header bg-white" style={{ width: (focusSearch) ? '40%':'20%', transition: 'width .4s' }}>
                            <NavbarSearch onFocus={() => setFocusSearch(true)} onBlur={() => setFocusSearch(false)} />
                        </div>
                    }
                    <ul className="nav navbar-top-links navbar-right bg-white pr-3">
                        <li><a title="help" id="link-help" style={{ padding: '10px' }} role="button" onClick={openHelp}> <i className="fas fa-question-circle fa-2x text-muted"></i></a></li>
                        <li><a title="logout" id="link-logout" style={{ padding: '10px' }} role="button" onClick={logOut}> <i className="fas fa-sign-out-alt fa-2x text-muted"></i></a></li>
                    </ul>
                </div>
            </nav>
            {statusModal &&
                <StatusModal />
            }
            <Lightbox
                open={admin.lightbox_show}
                index={admin.lightbox_index}
                close={() => dispatch(actions_admin.lightboxShow(false))}
                slides={admin.lightbox_sources}
                plugins={[Thumbnails, Zoom]}
                zoom={{
                    maxZoomPixelRatio: 4,
                    pinchZoomDistanceFactor: 200,
                }}
                carousel={{ finite: true }}
                render={{
                  buttonPrev: admin.lightbox_sources.length <= 1 ? () => null : undefined,
                  buttonNext: admin.lightbox_sources.length <= 1 ? () => null : undefined,
                }}
                toolbar={{
                    buttons: [
                    //   <button key="my-button" type="button" className="yarl__button">
                    //     <span className="material-icons">add_a_photo</span>
                    //   </button>,
                      "close",
                    ],
                }}
            />
        </div>
    );
}

import * as actions_users from 'actions/users-actions';
import * as toolbox from 'components/common/toolbox';
import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { elasticSearch, Table, Ibox, CloseX } from 'enspire-manager-framework';
import { navMultipleUrls } from 'components/common/toolbox';
import { numPanels } from 'components/common/toolbox';
import { useHistory, useLocation, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useSelector, useDispatch } from 'react-redux';

const isDev = (process.env.REACT_APP_FIREBASE_ENV == 'development');

const NavbarSearch = (props) => {

    /* Hooks --------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------*/

    const params = useParams();
    const history = useHistory();
    const inputRef = useRef();
    const dispatch = useDispatch();
    const routeLocation = useLocation();

    const user = useSelector((store) => store.users.user);
    const categories = useSelector((store) => store.serviceItems?.service_item_categories);

    const [showPanel, setshowPanel] = useState(false);
    const [search, setSearch] = useState({
        loading: false,
        input: '',
        hits: [],
    });

    /* Effects --------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------*/

    useEffect(() => {
        (search.input) && updateSearch(search.input);
    }, [search.input]);

    useEffect(() => {
        setSearch(prev => ({ ...prev, hits: [], input: '' }));
    }, [params.appId]);

    /* Handlers --------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------*/

    const handleClick = (hit, index, e) => {
        var newLocation;
        switch (hit.type) {
            case 'customer':
                newLocation = toolbox.modifyPath(routeLocation.pathname, 3, 'customers/' + hit.id + '/form/0/account', 7);
                history.push({ pathname: newLocation });
                break;
            case 'service request':
                toolbox.serviceRequestURL(params.handle, params.appId, hit, (locations) => {
                    navMultipleUrls({ actions_users, dispatch, history, numPanels, params, user, urlArray: locations});
                });
                break;
            case 'work order':
                toolbox.workOrderURL(params.handle, params.appId, hit, (locations) => {
                    navMultipleUrls({ actions_users, dispatch, history, numPanels, params, user, urlArray: locations});
                });
                break;
            case 'invoice':
                toolbox.invoiceURL(params.handle, params.appId, hit, (locations) => {
                    navMultipleUrls({ actions_users, dispatch, history, numPanels, params, user, urlArray: locations});
                });
                break;
            case 'service item':
                newLocation = toolbox.modifyPath(routeLocation.pathname, 3, 'service-items/' + hit.id, 3);
                history.push({ pathname: newLocation });
                break;
            default:
                break;
        }

        setshowPanel(false);
        setSearch(prev => ({ ...prev, input: '' }));
    };

    const handleOnFocus = () => {
        setSearch(prev => ({ ...prev, input: "" }));
        setshowPanel(true);
        props.onFocus();
    };

    const handleSearchInput = (event) => {
        setSearch(prev => ({ ...prev, input: event.target.value }));
    };

    const handleEsc = () => {
        setSearch(prev => ({ ...prev, input: '' }));
        setshowPanel(false);
        inputRef.current.blur();
        props.onBlur();
    };

    /* Actions --------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------*/

    const updateSearch = async (search) => {

        try {
            setSearch(prev => ({ ...prev, loading: true }));
            const indexHandle = 'mt-' + params.handle + ((isDev) ? '-dev' : '');
            let raw_hits;
            let config;
            let hits = [];

            /* Search Assets -----------------------------------------------------------------------------------------------*/
            config = {
                table: indexHandle + "-assets",
                fields: ["name", "unitNumber", "description", 'id'],
            };
            raw_hits = await elasticSearch(search, config);
            raw_hits?.forEach((hit) => {
                hits.push({ ...hit,
                    type: 'asset',
                    displayName: hit.name,
                });
            });

            /* Search customers -----------------------------------------------------------------------------------------------*/
            config = {
                table: indexHandle + "-profiles",
                type: 'customer',
                fields: ["displayName", "profileName", "addresses", "contacts", 'id', 'created'],
                appId: params.appId,
            };
            raw_hits = await elasticSearch(search, config);
            raw_hits?.forEach((hit) => {
                hits.push({ ...hit,
                    displayName: hit.displayName,
                    description: `${Object.values(hit.addresses)[0]?.address}`,
                    date: moment(hit.modified, 'X').format('MMM Do, YYYY'),
                });
            });

            /* Search Service Requests -----------------------------------------------------------------------------------------------*/
            config = {
                table: indexHandle + "-service-requests",
                fields: ["id", 'address', 'displayName', 'description', 'summary',],
                appId: params.appId,
            };
            raw_hits = await elasticSearch(search, config);
            raw_hits?.forEach((hit) => {
                hits.push({ ...hit,
                    type: 'service request',
                    displayName: hit.displayName,
                    date: moment(hit.modified, 'X').format('MMM Do, YYYY'),
                });
            });

            /* Search Work Orders -----------------------------------------------------------------------------------------------*/
            config = {
                table: indexHandle + "-work-orders",
                fields: ["id", 'address', 'displayName', 'description', 'endDate'],
                appId: params.appId,
            };
            raw_hits = await elasticSearch(search, config);
            raw_hits?.forEach((hit) => {
                hits.push({ ...hit,
                    type: 'work order',
                    displayName: hit.displayName,
                    date: moment(hit.modified, 'X').format('MMM Do, YYYY'),
                });
            });

            /* Search Invoices -----------------------------------------------------------------------------------------------*/
            config = {
                table: indexHandle + "-invoices",
                fields: ["id", 'address', 'displayName', 'description'],
                appId: params.appId,
            };
            raw_hits = await elasticSearch(search, config);
            raw_hits?.forEach((hit) => {
                hits.push({ ...hit,
                    type: 'invoices',
                });
            });

            /* Search Service Items -----------------------------------------------------------------------------------------------*/
            // config = {
            //     table: indexHandle + '-service-items',
            //     fields: ['name', 'categories', 'description',],
            // };
            // raw_hits = await elasticSearch(search, config);
            // raw_hits?.forEach((hit) => {
            //     hits.push({ ...hit,
            //         type: 'service item',
            //         displayName: hit.name,
            //         categoryList: hit?.categoryIds?.map((id) => ({ style: 1, value: categories.find((o) => o.id == id).name })),
            //         date: moment(hit.modified, 'X').format('MMM Do, YYYY'),
            //     });
            // });

            setSearch(prev => ({ ...prev, hits, loading: false }));
            
            return false; // prevent <Enter> key from reloading

        } catch (error) {
            window.toastr.error(error.message);
        }

    };

    /* Constants --------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------*/

    const columns = [
        { name: 'Id', nowrap: true, field: 'id' },
        { name: 'Result', field: 'displayName' },
        { name: 'Description', field: 'description' },
        { name: 'Modified', nowrap: true, field: 'date' },
    ];

    /* Render --------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------*/

    return (
        <form role="search" className="navbar-form-custom" action="search_results.html" >
            <div className="form-group">
                <input
                    onFocus={handleOnFocus}
                    value={search.input}
                    ref={inputRef}
                    type="text"
                    placeholder="Search for something..."
                    className="form-control"
                    name="top-search"
                    id="top-search"
                    onInput={handleSearchInput}
                    style={{ width: '100%' }}
                    onKeyDown={(e) => { if (e.key === 'Escape') handleEsc(); }}
                    autoComplete='off'
                />
                {showPanel &&
                    <div id="global-search" className="mt-0 mb-0">
                        <div>
                            <Ibox show_spinner={search.loading} title={ 'Search Results' } jsx={ <CloseX style={{ top: 0 }} onClick={() => { handleEsc(); }} /> } custom_title={false}>
                                <Table
                                    columns={columns}
                                    data={search.hits}
                                    click_callback={handleClick}
                                    group_by={{ fields: ['type'], direction: ['asc'] }}
                                />
                            </Ibox>
                        </div>
                    </div>
                }
            </div>
        </form>
    );
};

export default NavbarSearch;
import * as actions_users from 'actions/users-actions';
import * as toolbox from 'components/common/toolbox';
import ExpandDetails from 'components/common/expand-details';
import React, { useEffect, useState } from 'react';
import ResultsActionIcons from './results-actions/results-action-icons';
import _ from 'lodash';
import { APPS, WORKORDERS } from 'components/constants';
import { Avatar, Ibox } from 'enspire-manager-framework';
import { Button, ButtonGroup } from 'react-bootstrap';
import { DashboardDetail } from 'enspire-manager-framework';
import { Table } from 'em-table';
import { imageSize, numPanels, navMultipleUrls } from 'components/common/toolbox';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

const moment = require('moment');

const ResultsMyWorkOrders = (props) => {
    
    /* Hooks --------------------------*/
    
    const params = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
	const routeLocation = useLocation();

    const user = useSelector((store) => store.users.user);
    const employees = useSelector((store) => store.employees.employees);
    const techWorkOrders = useSelector((store) => store.workOrders.work_orders_technician);
    const workOrders = useSelector((store) => store.workOrders);

    const [filter, setFilter] = useState('today');
    const [badges, setBadges] = useState([0, 0, 0]);
    const [data, setData] = useState([]);

	const segments = routeLocation.pathname.split('/').length;
    const isCheckedIn = props.source == 'checkedIn';

    /* UseEffect --------------------------*/

    useEffect(() => {
        const active = _.filter(techWorkOrders, (workOrder) => [WORKORDERS.PAUSED.id, WORKORDERS.ONSITE.id, WORKORDERS.ENROUTE.id, WORKORDERS.INPROGRESS.id].includes(workOrder.statusId));
        const wo_today = _.filter(active, (workOrder) => moment(workOrder.startDate.seconds, 'X').startOf('day').isSame(moment().startOf('day'))).length;
        const wo_week =  _.filter(active, (workOrder) => 
                moment(workOrder.startDate.seconds, 'X').startOf('day') >= moment().startOf('week') &&
                moment(workOrder.startDate.seconds, 'X').endOf('day') <= moment().endOf('week')
            ).length;
        const wo_all = active.length;
        setBadges([ wo_today, wo_week, wo_all ]);

        if (wo_today) setFilter('today')
        else if (wo_week) setFilter('week')
        else if (wo_all) setFilter('all');
    }, [techWorkOrders]);

    useEffect(() => {     
        var work_orders = [];
        var currentEntityId = null;
        var filteredWorkOrders;

        if (props.source == 'checkedIn') {
            filteredWorkOrders = _.filter(techWorkOrders, (workOrder) => [WORKORDERS.ONSITE.id, WORKORDERS.ENROUTE.id, WORKORDERS.INPROGRESS.id].includes(workOrder.statusId));
        } else if (props.type == 'active') {
            filteredWorkOrders = _.filter(techWorkOrders, (workOrder) => [WORKORDERS.PAUSED.id, WORKORDERS.ONSITE.id, WORKORDERS.ENROUTE.id, WORKORDERS.INPROGRESS.id].includes(workOrder.statusId));
        } else if (props.type == 'incomplete') {
            filteredWorkOrders = _.filter(techWorkOrders, (workOrder) => [WORKORDERS.INCOMPLETE.id].includes(workOrder.statusId));
        } else if (props.type == 'assigned') {
            filteredWorkOrders = _.filter(techWorkOrders, (workOrder) => [WORKORDERS.ASSIGNED.id].includes(workOrder.statusId));
        }

        if (props.type == 'active' && filter == 'today') {
            filteredWorkOrders = _.filter(filteredWorkOrders, (workOrder) => 
                moment(workOrder.startDate.seconds, 'X').startOf('day').isSame(moment().startOf('day'))
            );
        } else if (props.type == 'active' && filter == 'week') {
            filteredWorkOrders = _.filter(filteredWorkOrders, (workOrder) =>
                (moment(workOrder.startDate.seconds, 'X').startOf('day').isAfter(moment().startOf('week')) &&
                moment(workOrder.startDate.seconds, 'X').endOf('day').isBefore(moment().endOf('week')))
            );;
        }

        filteredWorkOrders?.forEach((workOrder) => {
            
            const employee = employees?.find((o) => o.id == workOrder.technicianId);
            const entityId = (params.appId == APPS.SERVICE.id) ? workOrder.customerId : workOrder.assetId;
            const status = _.find(Object.values(WORKORDERS), { id: workOrder.statusId });
            const owner = _.find(employees, (o) => o.contact?.email == workOrder?.ownerId);
            
            // Add group heading for customer or asset
            if (entityId != currentEntityId) {
                currentEntityId = entityId;
                work_orders.push({ ...workOrder,
                    status: `${(isCheckedIn) ? 'CURRENTLY CHECKED IN ' : ''} ${status?.name}`,
                    bg_color: (isCheckedIn) ? status?.color : '#aaaaaa',
                    _jsx:
                    <div className="d-flex align-items-center justify-content-between p-2" style={{ backgroundColor: '#cccccc' }}>
                        <div role="button" onClick={() => openEntity(workOrder)}>
                            <Avatar className=""
                                color="white"
                                bgColor="saddleBrown"
                                size={45}
                                fontSize={'20px'}
                                name={(params.appId == APPS.SERVICE.id) ? workOrder._displayName : workOrder._name}
                                image={imageSize(workOrder._imageUrl, 'sm')}
                                imageWidth={60}
                                border="4px solid white"
                                role={ (workOrder._imageUrl) ? "button" : null }
                                style={{ flexShrink: 0 }}
                            />
                        </div>
                        <div className="flex-grow-1">
                            <h2 className="ml-3 m-0 text-black font-weight-normal">{
                                ((params.appId == APPS.SERVICE.id) ? workOrder._displayName : workOrder._name) + 
                                ((workOrder._unitNumber) ? ' #' + workOrder._unitNumber : '')
                            }</h2>
                            { workOrder._address &&
                                <p className="ml-3 m-0">{ workOrder._address }</p>
                            }
                        </div>
                        <ResultsActionIcons />
                    </div> 
                });
            }

            work_orders.push({ ...workOrder,
                name: ((employee)
                    ? employee?.contact?.firstName + ' ' + employee?.contact?.lastName
                    : (workOrder.statusId == WORKORDERS.UNASSIGNED.id) ? 'Unassigned' : 'Unknown'),
                status: ((props.source == 'checkedIn')?'Currently ':'') + status?.name,
                _stripe_color: status?.color,
                details: <>
                    <h4>{`WO #${workOrder.customId}`}</h4>
                    <ExpandDetails className='mt-1' default={ 'closed' } top={ -35 } eTop={ 30 } eRight={ 5 } noMore>
                        <div id="work-order-details" className="mt-2 mb-2">
                            <DashboardDetail field={ 'Start Date' } value={ moment(workOrder.startDate?.seconds, 'X').format('MMM DD, YYYY h:mm A') } />
                            <DashboardDetail field={ 'Description' } value={ workOrder.description } type="text" />
                            <DashboardDetail field={ 'Suggested' } value={ workOrder.suggested } type="text" />
                            <DashboardDetail field={ 'Created By' } value={ (owner) ? `${owner?.contact.firstName} ${owner?.contact.lastName}` : '' } type="text" />
                            <div className="clearfix"></div>
                        </div>
                    </ExpandDetails>
                    { workOrder.reason &&
                        <div className="alert alert-warning">
                            <strong>REASON:</strong>&nbsp; { workOrder.reason }
                        </div>
                    }
                </>
            });
        });
        setData(work_orders);
    }, [techWorkOrders, filter]);
    
    /* Actions ----------------------------*/

    const openWorkOrder = (workOrder) => {
        let num = (segments >= 12) ? -2 : (segments >= 10) ? -1 : 0;
		if (num) history.go(num);

        let entityId = (params.appId == APPS.SERVICE.id) ? workOrder.customerId : workOrder.assetId;
        navMultipleUrls({ actions_users, dispatch, history, numPanels, params, user, urlArray: [
            toolbox.modifyPath( routeLocation?.pathname, 4, `${entityId}/form/0/service_requests/${workOrder.serviceRequestId}/work_orders`, 9),
            toolbox.modifyPath( routeLocation?.pathname, 4, `${entityId}/form/0/service_requests/${workOrder.serviceRequestId}/work_orders/${workOrder.id}/items`, 11)
        ]});
    }
    const openEntity = (workOrder) => {
        const entity = (params.appId == APPS.SERVICE.id) ? 'customers' : 'assets';
        const entityId = (params.appId == APPS.SERVICE.id) ? workOrder.customerId : workOrder.assetId;
        const tab = (params.appId == APPS.SERVICE.id) ? 'tab' : 'service_requests';

        navMultipleUrls({ actions_users, dispatch, history, numPanels, params, user, urlArray: [
            toolbox.modifyPath( routeLocation?.pathname, 3, `${entity}`, 3),
            toolbox.modifyPath( routeLocation?.pathname, 3, `${entity}/${entityId}/form/0/${tab}`, 7),
        ]});    
    }

    /* Constants --------------------------*/

    const columns = [
        { name: 'Details', field: 'details', type: 'jsx', width: 100 },
        { name: 'Summary', field: 'description' },
    ];

    const filterButtons = <ButtonGroup className="d-flex mt-3">
        <Button variant={(filter == 'today') ? 'primary' : 'default'} onClick={ () => setFilter('today') }>
            Today
            { !!badges[0] && <span className="badge badge-danger position-absolute" style={{ zIndex: '99', right: '6px', top: '3px', borderRadius: '0.5rem' }}>{badges[0]}</span> }
        </Button>
        <Button variant={(filter == 'week') ? 'primary' : 'default'} onClick={ () => setFilter('week') }>
            This Week
            { !!badges[1] && <span className="badge badge-danger position-absolute" style={{ zIndex: '99', right: '6px', top: '3px', borderRadius: '0.5rem' }}>{badges[1]}</span> }
        </Button>
        <Button variant={(filter == 'all') ? 'primary' : 'default'} onClick={ () => setFilter('all') }>
            All Scheduled
            { !!badges[2] && <span className="badge badge-danger position-absolute" style={{ zIndex: '99', right: '6px', top: '3px', borderRadius: '0.5rem' }}>{badges[2]}</span> }
        </Button>
    </ButtonGroup> 
   
    return (
        <>
            { (!!data.length || !isCheckedIn) &&
                <>
                    { props.type == 'active' && filterButtons }
                    <Table
                        data={data}
                        columns={columns}
                        active_field={'id'}
                        active_id={params.tab2_id}
                        second_line={'description'}
                        group_by={{ fields: ['statusId'], direction: ['desc'], display: ['status'], bgColor: ['bg_color'] }}
                        hide_header={true}
                        click_callback={openWorkOrder}
                        chevron={ true }
                    />
                </>
            }
        </>
    );
};

export default ResultsMyWorkOrders;
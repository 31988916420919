import React, { Fragment } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { ADDRESSES } from 'components/constants';
import { roundDateTime } from 'components/common/toolbox';

const isDev = (process.env.REACT_APP_FIREBASE_ENV == 'development');

export function service_request_fields(default_duration, addressId, technicianId) {

	return (
		{
			customerId: [],
			addressId,
			description: '',
			imageUrl: '',
			imagesAlt: [],
			requestDate: new Date(),
			serviceDescription: 'See Attached Invoice',
			suggested: '',
			technicianId: technicianId,
			vendorId: [],
			startDate: roundDateTime(moment(), moment.duration(15, "minutes")).toDate(),
			startTime: roundDateTime(moment(), moment.duration(15, "minutes")).toDate(),
			endDate: roundDateTime(moment(), moment.duration(15, "minutes")).add(default_duration, 'hours').toDate(),
			duration: (parseFloat(default_duration ?? '2') * 60).toString(),
		}
	);
};

export function service_request_form_layout({
	firebase, 
	handle, 
	profile, 
	service_request,
	technicians,
	typeButtons, 
}) {

	const technician_options = _.orderBy(technicians, ['contact.lastName'], ['asc']).map((tech, index) => {
		return (
			<option key={index} value={tech.id}>{tech.contact?.firstName + ' ' + tech.contact?.lastName}</option>
		);
	});

	if (profile?.addresses) {
		var address_options = profile.addresses.map((address, index) => {
			let addressType = _.find(Object.values(ADDRESSES), { id: address.addressTypeId });
			return (
				<option key={'address' + address.id} value={address.id}>
					{`${(address.addressName) ? address.addressName + ' ' : ''}${(addressType) ? '(' + addressType?.name + ') - ' : ''}${address.addressLine1}${(address.addressLine2) ? address.addressLine2 : ''}`}
				</option>
			);
		});
	}

	return (
		[{
			column_class: 'col-md-7',
			body: [{
				section:
					<Fragment>
						<h3 className="m-t-none m-b">Customer Information</h3>
					</Fragment>,
				layout: [{
					grid: 'col-md-12',
					label: 'Customer',
					field: 'customerId',
					placeholder: 'Search for Customer',
					type: 'typeahead',
					valid: ['required'],
					options: {
						allow_new: true,
						fields: ["displayName", "addresses", "contacts"],
						id: 'id',
						minLength: 0,
						sort: [{ "displayName": "asc" }],
						table: 'mt-' + handle + (isDev ? '-dev' : '') + '-profiles',
						target: 'displayName',
						type_filter: 'customer',
					},
				}, {
					grid: 'col-12',
					label: 'Service Address',
					field: 'addressId',
					type: 'select',
					valid: ['required'],
					disabled: false,
					options:
						<Fragment>
							<option key={'none'} value={null}>- Select -</option>
							{address_options}
						</Fragment>,
				}]
			}, {
				section:
					<Fragment>
						<h3 className="m-t-none m-b">{`Service Request Details`}</h3>
						{ typeButtons }
					</Fragment>,
				layout: [{
					grid: 'col-sm-4',
					label: `Request Date`,
					field: 'requestDate',
					type: 'date',
					dateFormat: 'MM-DD-YYYY',
					showTimeSelect: true,
					valid: ['required'],
					selectsStart: true,
					// readOnly: (isComplete) ? true : false,
				}, {
					grid: 'col-sm-8',
					label: 'Technician',
					field: 'technicianId',
					type: (service_request.id) ? 'skip' : 'select',
					options:
						<Fragment>
							<option key={'none'} value={''}>- Select -</option>
							{technician_options}
						</Fragment>,
				}, {
					grid: 'col-4',
					label: 'Start Date',
					field: 'startDate',
					type: (service_request.technicianId) ? 'date' : 'skip',
					dateFormat: 'MM-DD-YYYY',
					valid: ['required'],
				}, {
					grid: 'col-4',
					label: 'Start Time',
					field: 'startTime',
					type: (service_request.technicianId) ? 'time' : 'skip',
					dateFormat: 'hh:mm A',
					valid: ['required'],
				}, {
					grid: 'col-4',
					label: 'Duration',
					field: 'duration',
					valid: ['required'],
					type: (service_request.technicianId) ? 'select' : 'skip',
					options:
						<Fragment>
							<option key={'30'} value={'30'}>00:30 hrs</option>
							<option key={'60'} value={'60'}>01:00 hrs</option>
							<option key={'90'} value={'90'}>01:30 hrs</option>
							<option key={'120'} value={'120'}>02:00 hrs</option>
							<option key={'150'} value={'150'}>02:30 hrs</option>
							<option key={'180'} value={'180'}>03:00 hrs</option>
							<option key={'210'} value={'210'}>03:30 hrs</option>
							<option key={'240'} value={'240'}>04:00 hrs</option>
							<option key={'270'} value={'270'}>04:30 hrs</option>
							<option key={'300'} value={'300'}>05:00 hrs</option>
							<option key={'330'} value={'330'}>05:30 hrs</option>
							<option key={'360'} value={'360'}>06:00 hrs</option>
							<option key={'390'} value={'390'}>06:30 hrs</option>
							<option key={'420'} value={'420'}>07:00 hrs</option>
							<option key={'450'} value={'450'}>07:30 hrs</option>
							<option key={'480'} value={'480'}>08:00 hrs</option>
						</Fragment>,
				}, {
					grid: 'col-12',
					label: 'Description of Issue',
					field: 'description',
					type: 'textarea',
					valid: ['required'],
				}],
			}]
		}, {
			column_class: 'col-md-5',
			body: [{
				section:
					<Fragment>
						<h3 className="m-t-none m-b">Main Service Request Image</h3>
					</Fragment>,
				layout: [{
					grid: 'col-12',
					label: 'Service Request Image',
					field: 'imageUrl',
					type: 'dropzone',
					dropzone: {
						camera: true,
						storage: 'firebase',
						storageRef: firebase.storage().ref(),
						bin: handle,
						directory: 'service-requests',
						className: 'mb-3',
						height: "280px",
						multiple: false,
						resultsArray: true,
					}
				}],
			}],
		}]
	);
};
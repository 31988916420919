import * as actions_admin from 'actions/admin-actions';
import * as actions_attachments from 'actions/attachments-actions';
import React, { useEffect, useState } from 'react';
import firebase from 'firebase/compat/app';
import moment from 'moment';
import { FormBuilder, ValidateForm, ModalForm } from 'enspire-manager-framework';
import { LOG, ACTION } from 'components/constants';
import { attachment_fields, attachment_form_layout } from './attachment-form-layout';
import { confirmAdd, confirmDelete, getProfileName, toProperCase } from 'components/common/toolbox';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

var _ = require('lodash');

export default function AttachmentForm(props) {

	const params = useParams();
	const history = useHistory();
	const dispatch = useDispatch();

	const admin = useSelector((store) => store.admin);
    const table = useSelector((store) => store[props.store]);
	const user_permissions = useSelector(store => store.users?.user_permissions);

	const field = (props.field) ? props.field : 'attachments';
    const attachments = table?.[props.singular + '_' + field] ?? [];
	const attachment = attachments[params.attachment_index - 1];

	const [focusedInput, setFocused] = useState(null);
	const [original, setOriginal] = useState({});
	const [state, setState] = useState({
		search: '',
		form_error: null,
		attachment: attachment_fields(),
		expand: false,
	});

    // Permissions ----------------------------------------

    const canView = props.permissions?.canView;
    const canEdit = props.permissions?.canEdit;

	/* Effects -----------------------------------------------------------------------------------------------------------------------*/

	useEffect(() => {
		if (props.filter) setState(prev => ({ ...prev, attachment: { ...prev.attachment, classificationId: props.filter } }));
	}, [props?.filter]);

	useEffect(() => {
		if (parseInt(params.attachment_index)) {
			populateForm();
		}
	}, [params.form_id, attachment]);

	/* Handlers --------------------------*/

	const handleChange = (e, value) => {
		let newValue = (value) ? value : e.target.value;
		let form_error = _.filter(state.form_error, (o) => { return o.field !== e.target.name; });
		setState(prev => ({ ...prev, form_error, attachment: { ...prev.attachment, [e.target.name]: newValue } }));
	};
    const handleDate = (field, date) => {
        let form_error = _.filter(state.form_error, (o) => { return o.field !== field; });
        setState(prev => ({ ...prev, form_error, attachment: { ...prev.attachment, [field]: moment(date).toDate() }}));
    };
    const handleClear = (field) => {
        let form_error = _.filter(state.form_error, (o) => { return o.field !== field; });
        setState(prev => ({ ...prev, form_error, attachment: { ...prev.attachment, [field]: null }}));
    };
	const handlePromote = (field) => {
		var attachment = { ...state.attachment };
		let currentFav = attachment.imageUrl;
		let elementIndex = parseInt(field.split('imageUrlAlt')[1]);
		
		attachment.imageUrl = attachment['imageUrlAlt' + elementIndex];
		attachment['imageUrlAlt' + elementIndex] = currentFav;

        setState(prev => ({ ...prev, attachment: { ...attachment }}));
	}
	const handleUpload = (upload, filename) => {
		let results = actions_attachments.prepUploadedImages(upload, state.attachment);
		setState(prev => ({ ...prev, attachment: results }));
	};

	/* Actions --------------------------*/

	const populateForm = async () => {
		if (attachment) {
			let myAttachment = { ...attachment,
				expiration: (attachment.expiration) ? moment(attachment.expiration?.seconds, 'X').toDate() : null,
			};
			const images = attachment.imagesAlt;
			images?.forEach((image, index) => {
				myAttachment['imageUrlAlt' + index] = image;
			});	
			setState(prev => ({ ...prev, attachment: myAttachment }));
			setOriginal(myAttachment);
		};	
	};		
    const handleDelete = () => {
		var attachmentsArray = [];
		attachments.forEach((attach, index) => {
			if (index != params.attachment_index -1) attachmentsArray.push(attach);
		});
		dispatch(actions_attachments.attachmentSpinner(true));
		dispatch(actions_attachments.saveAttachment(params.handle, props.id, attachmentsArray, props.source, props.prefix, field, (assetId) => {
			dispatch(actions_admin.saveActivity(params.handle, props.prefix, ACTION.DELETED.key, props.id, `Attachment: ${state.attachment.name}`));
			dispatch(actions_attachments.attachmentSpinner(false));
			history.goBack();
		}));
    };

	const submitForm = (assetFormLayout) => {
		var form_error = ValidateForm(state.attachment, assetFormLayout);
		setState(prev => ({ ...prev, form_error }));

		let newAttachment = { ...state.attachment };
		let imagesAlt = [];
		for (let index = 0; index < 5; index++) {
			if (state.attachment['imageUrlAlt' + index]) imagesAlt.push(state.attachment['imageUrlAlt' + index]);
			delete newAttachment['imageUrlAlt' + index]
		}
		newAttachment.imagesAlt = imagesAlt;

		var attachmentsArray = [];	
		if (!parseInt(params.attachment_index)) {
			attachmentsArray = [ ...attachments, newAttachment];
		} else {
			attachments.forEach((attach, index) => {
				if (index == (params.attachment_index -1)) {
					attachmentsArray.push(newAttachment);
				} else {
					attachmentsArray.push(attach);
				}
			});
		}

		if (!form_error.length) {
			dispatch(actions_attachments.attachmentSpinner(true));
			dispatch(actions_attachments.saveAttachment(params.handle, props.id, attachmentsArray, props.source, props.prefix, field, (id) => {
				dispatch(actions_admin.saveActivity(params.handle, props.prefix, (isNew) ? ACTION.CREATED.key : ACTION.MODIFIED.key, props.id, `Attachment: ${state.attachment.name}`));
				dispatch(actions_attachments.attachmentSpinner(false));
				history.goBack();
			}));
		}
	};

	const form_layout = attachment_form_layout({
		attachment: state.attachment, 
		expires: props.expires,
		firebase, 
		handle: params.handle, 
		handlePromote,
		invoice: props.invoice,
	});
	const isNew = (!parseInt(params.attachment_index));

	return (

		<ModalForm {...props}
			history={history}
			style={1}
			size={'md'}
			modal_header={`Attachment`}
			cancel_button_title="Cancel"
			save_button_title={'Save Details'}
			submitFormHandler={() => submitForm(form_layout)}
			delete_button_title={'Delete'}
			delete_button_disabled={ !canEdit }
			delete_button_callback={(isNew || state.attachment.isDefault) ? null : () => confirmDelete('Asset', handleDelete)}
			show_spinner={admin.attachments_spinner}
			focusedInput={(window.flutter) ? focusedInput : null}
			platform={admin.flutter_platform}
			keyboardPadding={admin.flutter_keyboard}
		>
			{state.attachment &&
				<FormBuilder
					callbacks={{
						focus: (event) => setFocused(event.target),
						text: handleChange,
						select: handleChange,
						dropzone: handleUpload,
						date: handleDate,
						clear: handleClear,
					}}
					form_error={state.form_error}
					form={form_layout}
					record={state.attachment}
				/>
			}
		</ModalForm>
	);
}
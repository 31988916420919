import * as actions_quotes from 'actions/quotes-actions';
import * as actions_users from 'actions/users-actions';
import * as toolbox from 'components/common/toolbox';
import React, { useEffect, useState } from 'react';
import { APPS, PERMISSIONS, QUOTES } from 'components/constants';
import { Ibox } from 'enspire-manager-framework';
import { Table } from 'em-table';
import { navMultipleUrls } from 'components/common/toolbox';
import { numPanels } from 'components/common/toolbox';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';

var _ = require('lodash');

export default function QuotesTab(props) {

    /* Hooks ------------------------------------------------------------------------------------------------------------------------------------*/

    const seg = 9;
    const history = useHistory();
    const routeLocation = useLocation();
    const params = useParams();
    const dispatch = useDispatch();

    const quotes = useSelector((store) => store.quotes);
    const user = useSelector((store) => store.users.user);
    const user_permissions = useSelector((store) => store.users.user_permissions);

    const approved_quote = quotes.quote_approved;

    /* Effects ------------------------------------------------------------------------------------------------------------------------------------*/

    useEffect(() => {
        if (params.appId == APPS.SERVICE.id) {
            var field = (props.source == 'customers') ? 'customerId' : 'serviceRequestId';
            var value = (props.source == 'customers') ? params.customer_id : params.request_id;
        } else {
            var field = (props.source == 'customers') ? 'customerId' : 'serviceRequestId';
            var value = (props.source == 'customers') ? params.customer_id : params.request_id;
        }
        dispatch(actions_quotes.subQuotesByField(params.handle, field, value));

        return () => {
            let unsubscribe = quotes.quotes_unsubscribe;
            if (typeof unsubscribe === 'function') unsubscribe();
            dispatch(actions_quotes.clearQuotes());
        };
    }, [params.request_id, params.customer_id, params.tab_id]);

    // Automatically advance to the first item
    useEffect(() => {
        if (user_permissions[PERMISSIONS.QUOTES_VIEW.key]) {
            if (numPanels(2) && params.tab == 'quotes' && params.tab_id == 'auto') {

                var location = toolbox.modifyPath(routeLocation.pathname, seg, `quotes`, seg);
                history.replace({ pathname: location });
                
                if (data?.length) {
                    setTimeout(() => {
                        var location = toolbox.modifyPath(props.location.pathname, seg, `quotes/${data[0]?.id}/items`);
                        history.push({ pathname: location });
                    }, 50);

                    props.history.push({ pathname: location });
                }
            }
        }
    }, [params.tab, quotes?.quotes]);
    
    /* Constants ------------------------------------------------------------------------------------------------------------------------------------*/

    const handleClick = (quote) => {
        if (props.source == "customers") {
            navMultipleUrls({ actions_users, dispatch, history, numPanels, params, replace: true, user, urlArray: [
                toolbox.modifyPath(routeLocation.pathname, seg-2, `service_requests`),
                toolbox.modifyPath(routeLocation.pathname, seg-2, `service_requests/${quote.serviceRequestId}/quotes`),
                toolbox.modifyPath(routeLocation.pathname, seg-2, `service_requests/${quote.serviceRequestId}/quotes/${quote.id}/items`),
            ]});
        } else {
            let location = toolbox.modifyPath(routeLocation.pathname, seg, `quotes/${quote.id}`);
            location = toolbox.modifyPathIfEmpty(location, seg + 2, 'items');
            history.push({ pathname: location });
        }
    };

    /* Constants ------------------------------------------------------------------------------------------------------------------------------------*/

    var data = [];
    quotes?.quotes?.forEach((quote) => {
        let status = _.find(Object.values(QUOTES), { id: quote.statusId });
        if (status) data.push({ ...quote, 
            status: status.name, 
            _stripe_color: status?.color,
            summary: ((quote?.changeOrder) ? 'CO: ' + quote.summary : quote.summary) });
    });

    const quoteDisabled = (!user_permissions?.QUOTES_CREATE) ? true : (!!approved_quote);
    const statusStyle = { position: 'absolute', top: '-36px', left: 0, right: 0, marginLeft: 'auto', marginRight: 'auto', textAlign: 'center' };
    const columns = [
        { name: 'ID', field: 'id', width: 14 },
        { name: 'Created', field: 'created', type: 'date', format: 'MMM Do, YYYY', width: 23 },
        { name: 'Description', field: 'description', width: 63 },
    ];


    /* Render ------------------------------------------------------------------------------------------------------------------------------------*/

    return (

        <Ibox className="" title={'Quotes'} show_spinner={quotes.quotes_pending} no_fade={false}
            button={ (props.source == 'service-requests') ? ' + Quote' : ''}
            button_callback={() => {
                let location = toolbox.modifyPath(props.location.pathname, seg-4, `quote-form/0`);
                history.push({ pathname: location });
            }}
        >
            <Table
                active_field={'id'}
                active_id={params.tab_id}
                chevron={true}
                click_callback={handleClick}
                columns={columns}
                data={data}
                highlight_search={true}
                max_height={'135px'}
                show_limit={false}
                show_search={false}
            />
        </Ibox>
    );
};
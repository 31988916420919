import * as actions_admin from 'actions/admin-actions';
import * as actions_profiles from 'actions/profiles-actions';
import * as actions_quickbooks from 'actions/quickbooks-actions';
import React, { useEffect, useState } from 'react';
import { FormBuilder, ValidateForm, ModalForm } from 'enspire-manager-framework';
import { LOG, ACTION } from 'components/constants';
import { address_fields, address_form_layout } from './address-form-layout';
import { confirmDelete } from 'components/common/toolbox';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

var _ = require('lodash');

const AddressForm = (props) => {

	const params = useParams();
	const dispatch = useDispatch();
	
	const profiles = useSelector((store) => store.profiles);
	const quickbooksSettings = useSelector((store) => store.quickbooks.quickbooks);

	const [original, setOriginal] = useState({});
	const [state, setState] = useState({
		form_error: null,
		address: address_fields(),
	});

	/* Effects --------------------------*/

	useEffect(() => {
		dispatch(actions_profiles.getAddress(params.handle, params.address_id));

		return () => {
			dispatch(actions_profiles.clearAddress());
		};
	}, [params.address_id]);

	useEffect(() => {
		if (parseInt(params.address_id) && profiles.address) populateForm();
	}, [profiles?.address]);

	/* Handlers --------------------------*/

	const handleChange = (event) => {
		let form_error = _.filter(state.form_error, (o) => { return o.field !== event.target.name; });
		setState(prev => ({ ...prev, form_error, address: { ...state.address, [event.target.name]: event.target.value } }));
	};
	const deleteAddress = () => {
		dispatch(actions_profiles.deleteAddress(params.handle, state.address, (addressId) => {
			dispatch(actions_admin.saveActivity(params.handle, LOG.ADDRESS.key, ACTION.DELETED.key, params.profile_id, state.address.addressLine1, state.address ));
			dispatch(actions_quickbooks.updateQuickbooks({ handle: params.handle, type: 'customer' }, quickbooksSettings));
			props.history.goBack();
		}));
	}
	const populateForm = () => {
		setState(prev => ({ ...prev, address: profiles.address }));
		setOriginal(profiles.address);
	}
	const handleAutocomplete = (place) => {
		const address = place.address_components.find((component) => component.types.includes('street_number'))?.long_name + ' ' + place.address_components.find((component) => component.types.includes('route'))?.long_name;
		const city = place.address_components.find((component) => component.types.includes('locality'))?.long_name;
		const stateLocation = place.address_components.find((component) => component.types.includes('administrative_area_level_1'))?.short_name;
		const zip = place.address_components.find((component) => component.types.includes('postal_code'))?.long_name;

		setState(prev => ({ ...prev, address: { ...prev.address, addressLine1: address, city, state: stateLocation, zip } }));
	};

	/* Actions --------------------------*/

	const submitForm = (addressFormLayout) => {
		var form_error = ValidateForm(state.address, addressFormLayout);
		setState(prev => ({ ...prev, form_error }));

		if (!form_error.length) {
			dispatch(actions_profiles.saveAddress(params.handle, params.profile_id, state.address, (new_id) => {
				let action = (parseInt(state.address.id)) ? ACTION.MODIFIED.key : ACTION.CREATED.key;
				dispatch(actions_admin.saveActivity(params.handle, LOG.ADDRESS.key, action, params.profile_id, state.address.addressLine1, state.address, original ));
				dispatch(actions_quickbooks.updateQuickbooks({ handle: params.handle, type: 'customer' }, quickbooksSettings));
				props.history.goBack();
			}));
		}
	};

	/* Constants ---------------------------*/

	var form_layout = address_form_layout(props.type);
	var isNew = (!parseInt(params.address_id));

	return (

		<ModalForm {...props}
			history={props.history}
			modal_header={'Address Details'}
			cancel_button_title="Cancel"
			save_button_title={'Save Details'}
			delete_button_callback={(isNew) ? null : () => confirmDelete('Address', deleteAddress)}
			submitFormHandler={submitForm.bind(this, form_layout)}
			show_spinner={profiles.address_save_pending || profiles.address_pending}
		>
			{
				state.address &&
				<FormBuilder
					callbacks={{
						text: handleChange,
						select: handleChange,
						autocomplete: handleAutocomplete,
					}}
					form_error={state.form_error}
					form={form_layout}
					record={state.address}
				/>
			}
		</ModalForm>
	);
};

export default AddressForm;

import * as actions_admin from 'actions/admin-actions';
import * as actions_quotes from 'actions/quotes-actions';
import * as actions_service_requests from 'actions/service-requests-actions';
import * as actions_users from 'actions/users-actions';
import * as actions_work_orders from 'actions/work-orders-actions';
import * as toolbox from 'components/common/toolbox';
import Authorize from 'components/common/authorize';
import ExpandDetails from 'components/common/expand-details';
import React, { useEffect, useState } from 'react';
import SendEmailForm from 'components/send-email-form/send-email-form';
import SpeedDialButton from 'components/common/speed-dial-button'; 
import StatusSelect from 'components/common/status-select';
import { ACTION, APPS, LOG, SERVICE_REQUEST_TERMS, QUOTES } from 'components/constants';
import { Avatar, DashboardDetail, ModalAlert, Spinner } from 'enspire-manager-framework';
import { PERMISSIONS } from 'components/constants';
import { Sheet } from 'react-modal-sheet';
import { checkPopupBlocker, confirmChangeStatus } from 'components/common/toolbox';
import { imageSize, numPanels, roundDateTime } from 'components/common/toolbox';
import { navMultipleUrls } from 'components/common/toolbox';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';

var _ = require('lodash');
const moment = require('moment');

const QuoteDashboard = (props) => {

    /* Hooks ------------------------------------------------------------------------------------------------------------------------------------*/

    const seg = 5;
    const params = useParams();
    const dispatch = useDispatch();
    const history = useHistory();
    const routeLocation = useLocation();

	const admin = useSelector((store) => store.admin);
    const assets = useSelector((store) => store.assets.assets);
    const employees = useSelector((store) => store.employees.employees);
    const profiles = useSelector((store) => store.profiles);
    const quotes = useSelector((store) => store.quotes);
    const quote = useSelector((store) => store.quotes.quote);
    const service_request = useSelector((state) => state.serviceRequests.service_request);
	const settings = useSelector((store) => store.settings.settings);
    const user = useSelector((store) => store.users.user);
	const user_permissions = useSelector(store => store.users?.user_permissions);

    const [state, setState] = useState({
        fetchedRequest: false,
        fetchedOrder: false,
        showEmailForm: false,
        rendering: false,
    });

    const asset = assets.find((asset) => asset.id == quote?.assetId);
    const pathname = routeLocation.pathname;
	const segments = pathname.split('/').length;
    const owner = _.find(employees, (o) => o.contact?.email == quote?.ownerId);

    // Permissions ----------------------------------------

    const isClosed = quote?.statusId == QUOTES.CLOSED.id;
    const isTeamWO = (service_request?.techIds?.includes(user?.profileId) || service_request?.techIds?.includes(user?.email));
    const canView = (
        quote?.technicianId == user?.profileId || 
        quote?.ownerId == user?.email
    );
    const canEdit = (
        user_permissions[PERMISSIONS.WORK_ORDERS_EDIT.key] || 
        quote?.ownerId == user?.email
    );
    
    /* Effects ------------------------------------------------------------------------------------------------------------------------------------*/

    useEffect(() => {
        dispatch(actions_quotes.subQuoteById(params.handle, params.quote_id));

        return () => {
            let unsubscribe = quotes.quote_unsubscribe;
            if (typeof unsubscribe === 'function') unsubscribe();
        };
    }, [params.quote_id]);

    /* Handlers ------------------------------------------------------------------------------------------------------------------------------------*/

    const handleSpeedDial = (value, action) => {
        if (value == 'email') handleAction('email');
        if (value == 'closed') onChangeStatus(QUOTES.CLOSED.id);
        if (value == 'convert_workorder') convertWorkOrder();
        if (value == 'convert_request') convertServiceRequest();
    }
    const handleAction = (action) => {
        if (action == 'print') {
            createQuotePDF(null);
        } else if (action == 'email') {
            let location = toolbox.modifyPath(routeLocation.pathname, seg, 'send-email/0', seg+7);
            history.push({ pathname: location });
        }
    };

    /* Actions ------------------------------------------------------------------------------------------------------------------------------------*/

    const sendEmail = (email) => {
        createQuotePDF(email);
    };
    const createQuotePDF = (email) => {
        var update = {};

        if (parseInt(quote.statusId) < parseInt(QUOTES.PENDING.id)) {
            if (!email) {
                ModalAlert({
                    title: 'Change Status?',
                    text: "Do you want to change status to Pending?",
                    icon: 'question',
                    // confirm_color: '#8FBC8B',
                    confirm_text: 'Okay!',
                    deny_text: 'No, keep as Draft',
                    deny_color: '#cccccc',
                    show_deny: true,
                    callback_success: () => { 
                        update.statusId = QUOTES.PENDING.id;
                        continueCreate(); 
                    },
                    callback_denied: () => continueCreate()
                });
            } else {
                update.statusId = QUOTES.PENDING.id;
                continueCreate(); 
            }
        } else continueCreate();

        function continueCreate() {

            history.goBack();
            setState(prev => ({ ...prev, rendering: true }));

            let messageFinal = '';
            let validateSignature = Date.now() + (60 * 60 * 48 * 1000);
            let link = `https://manager.mobiletrack.systems/#/${params.handle}/${params.appId}/quote_options/${params.quote_id}/${email?.to?.[0]?.value??'null'}/${validateSignature}`;

            if (email) {
                messageFinal = email.body.replace(/(?:\r\n|\r|\n)/g, '<br>');
                messageFinal += `<br/><br/><a href="${link}" style="padding: 8px 15px; color: white; background-color: green; text-decoration: none">Approve Quote with Signature</a`;
            }

            dispatch(actions_quotes.createQuotePdf({
                handle: params.handle,
                shadeColor: '#116a75',
                customerId: params.customer_id,
                quoteId: params.quote_id,
                selected_options: quote.options,
                email: (!email) ? null : (email?.to?.length) ? email.to.map((to) => to.value) : [], //email.to,
                subject: (!email) ? null : email.subject,
                body: (!email) ? null : messageFinal,

            }, (result) => {
                
                dispatch(actions_quotes.updateQuote(params.handle, params.quote_id, { ...update, validateSignature }));
                if (email) dispatch(actions_admin.saveActivity(params.handle, LOG.QUOTE.key, ACTION.EMAILED.key, params.quote_id, quote.description ));
                setState(prev => ({ ...prev, rendering: false }));

                if (!email) {
                    window.open(result.data.downloadUrl, '_blank');

                    if (!user.dismissed?.checkPopupBlocker) {
                        checkPopupBlocker((isDismissed) => {
                            if (isDismissed) dispatch(actions_users.dismissNotice(params.handle, user.email, 'checkPopupBlocker'));
                        }, () => {});
                    }
            
                } else {
                    window.toastr.success('This Quote has been generated and emailed to ' + email.to.map((to) => to.value).join(', '), 'Quote Emailed!');
                }
            }));
        }
    };
    const convertWorkOrder = () => {
        ModalAlert({
            title: 'Convert Quote to Work Order?',
            text: "The Quote will be converted and you will be redirected to the new Work Order.",
            icon: 'question',
            confirm_text: 'Okay',
            show_cancel: true,
            callback_success: () => { 
                var newWorkOrder = {
                    startDate: roundDateTime(moment(), moment.duration(15, "minutes")).toDate(),
                    endDate: roundDateTime(moment(), moment.duration(15, "minutes")).add(settings?.work_orders?.default_duration ?? 2, 'hours').toDate(),
                    description: quote.description,
                    ownerId: quote.ownerId,
                    fromQuoteId: quote.id,
                };
        
                dispatch(actions_work_orders.saveNewWorkOrder(params.handle, params.request_id, newWorkOrder, user, (workOrderId) => {
                    dispatch(actions_admin.saveActivity(params.handle, LOG.WORK_ORDER.key, ACTION.CREATED.key, workOrderId, newWorkOrder.description, newWorkOrder ));
                    dispatch(actions_quotes.updateQuote(params.handle, params.quote_id, { statusId: QUOTES.CLOSED.id, isConverted: true }));
        
                    history.goBack();
                    navMultipleUrls({ actions_users, dispatch, history, numPanels, params, replace: true, user, urlArray: [
                        toolbox.modifyPath(routeLocation.pathname, seg+4, `work_orders`, seg+4),
                        toolbox.modifyPath(routeLocation.pathname, seg+4, `work_orders/${workOrderId}`, seg+5),
                    ]});
                            
                }, { lineItems: _.filter(quote.serviceItems, (item) => item.option == quote.approvedOption)}));
            },
        });
    }
    const convertServiceRequest = () => {
        ModalAlert({
            title: 'Convert Quote to a new Service Request?',
            text: "The Quote will be converted and you will be redirected to a Work Order on the new Service Request.",
            icon: 'question',
            confirm_text: 'Okay',
            show_cancel: true,
            callback_success: () => { 
                var newWorkOrder = {
                    startDate: roundDateTime(moment(), moment.duration(15, "minutes")).toDate(),
                    endDate: roundDateTime(moment(), moment.duration(15, "minutes")).add(settings?.work_orders?.default_duration ?? 2, 'hours').toDate(),
                    description: quote.description,
                    ownerId: quote.ownerId,
                    fromQuoteId: quote.id,
                };
        
                let newServiceRequest = Object.assign({}, service_request);
                newServiceRequest.description = quote.description;
                newServiceRequest.serviceRequestTermsId = SERVICE_REQUEST_TERMS.PROJECT_QUOTE.id;
                delete newServiceRequest.id;
                delete newServiceRequest.totalCost;
                delete newServiceRequest.totalCompleted;

                dispatch(actions_service_requests.saveServiceRequest(params.handle, newServiceRequest, user, (serviceRequestId, workOrderId) => {
                    dispatch(actions_admin.saveActivity(params.handle, LOG.SERVICE_REQUEST.key, ACTION.CREATED.key, serviceRequestId, newServiceRequest.description, newServiceRequest ));
                    dispatch(actions_work_orders.saveNewWorkOrder(params.handle, serviceRequestId, newWorkOrder, user, (workOrderId) => {
                        dispatch(actions_admin.saveActivity(params.handle, LOG.WORK_ORDER.key, ACTION.CREATED.key, workOrderId, newWorkOrder.description, newWorkOrder ));
                        dispatch(actions_quotes.updateQuote(params.handle, params.quote_id, { statusId: QUOTES.CLOSED.id, isConverted: true }));
            
                        history.go(-2);
                        navMultipleUrls({ actions_users, dispatch, history, numPanels, params, replace: true, user, urlArray: [
                            toolbox.modifyPath(routeLocation.pathname, seg+3, `${serviceRequestId}/work_orders`, seg+4),
                            toolbox.modifyPath(routeLocation.pathname, seg+3, `${serviceRequestId}/work_orders/${workOrderId}`, seg+5),
                        ]});
            
                    }, { lineItems: _.filter(quote.serviceItems, (item) => item.option == quote.approvedOption)}));
                }));
            },
        });
    }
    const setShowBottomSheet = (value) => {
        dispatch(actions_admin.bottomSheetShow(value));
    }
    const openForm = () => {
        let location = toolbox.modifyPath(props.location.pathname, seg, `quote-form/${params.quote_id}`);
        history.push({ pathname: location });
    };
    const onChangeStatus = (statusId) => {
        let status = _.find(Object.values(QUOTES), { id: statusId });
        confirmChangeStatus('Quote', status.name, () => {
            dispatch(actions_quotes.updateQuote(params.handle, params.quote_id, { statusId }));
        });
    };

    /* Constants ------------------------------------------------------------------------------------------------------------------------------------*/

    const serviceAddress = _.find(profiles.profile?.addresses, { id: quote?.addressId });
    const converted = (quote?.isConverted) ? 'CONVERTED TO WORK ORDER' 
    : (quote?.fromWorkOrderId) ? 'CONVERTED FROM WORK ORDER' : '';

    const custAvatar = <Avatar
        className="float-right"
        color="white"
        bgColor="saddleBrown"
        size={35}
        fontSize={'10px'}
        name={(params.appId == APPS.SERVICE.id) ? service_request?._displayName : asset?.name }
        border="3px solid white"
    />

    const serviceAvatar = <Avatar
        className="float-right"
        color="white"
        bgColor="darkseagreen"
        size={35}
        fontSize={'10px'}
        name={service_request?.customId}
        initials={false}
        image={imageSize(service_request?.imageUrl, 'sm')}
        border="3px solid white"
    />

    return (

		<div className="row position-relative">
            { quote
                ?   <Authorize perm={ PERMISSIONS.QUOTES_VIEW } or={ canView } show403>
                        <div className="position-absolute animated fadeIn animation-delay-3" style={{ top: '67px', left: '18px', zIndex: 100 }}>
                            { custAvatar }
                        </div>
                        <div className="position-absolute animated fadeIn animation-delay-4" style={{ top: '67px', left: '50px', zIndex: 100 }}>
                            { serviceAvatar }
                        </div>
                        <div id="quote-avatar" className={ 'col-12 mb-3' }>
                            <div className="profile-image">
                                <div className="mb-2">
                                    <Avatar
                                        color="white"
                                        bgColor="goldenrod"
                                        size={90}
                                        name={'Q1'}
                                        border='4px solid white'
                                    />
                                </div>
                            </div>
                            <div className="profile-info pt-1 pr-2">
                                <div className="d-flex">
                                    <h1 className="main-heading heading-quote align-self-start">{`#${quote.id} ${(quote?.changeOrder) ? 'Change Order ' : 'Quote'}`}</h1>
                                    {segments < seg+10 && !window.flutter &&
                                        <div className={ 'animated fadeIn mt-n2 ml-3' } style={{ cursor: 'pointer', height: '10px' }} onClick={() => { history.goBack(); }}>
                                            <i className="fas fa-times" style={{ fontSize: '38px', color: '#bbbbbb' }}></i>
                                        </div>
                                    }
                                </div>
                                <h2 className="line-clamp-2 ml-2 mt-1">{quote.description}</h2>
                            </div>
                        </div>

                        {/* BUTTON ROWN ------------------------------------------------------------- */}

                        <div className="col-12">
                            <button type="button"
                                className={'btn btn-default btn-sm mr-2'} 
                                disabled={!canEdit}
                                onClick={openForm}><i className="fa-regular fa-gear fa-lg text-muted mr-1"></i>Edit Quote
                            </button>
                            <StatusSelect className="mr-2"
                                selectedStatusId={quote.statusId}
                                statusTable={'QUOTES'}
                                statusTypes={Object.values(QUOTES)}
                                static={true}
                            />
                            {quote.signatureUrl &&
                                <img src={quote.signatureUrl} className="ml-3 mt-n3" height="45" />
                            }
                        </div>

                        {/* EXPANDABLE DETAILS ------------------------------------------------------ */}

                        <div className="col-12">
                            <ExpandDetails more="Show More">
                                <div id="quote-details" className="mt-3 mb-2">
                                    { numPanels(1) && <>
                                        <DashboardDetail field={ custAvatar } value={service_request._displayName} />
                                        <DashboardDetail field={ serviceAvatar } value={service_request.description} />
                                    </>}
                                    <DashboardDetail field={ 'Technician Id' } value={ (quote.technicianId != "unassigned") ? quote.technicianId : "UNASSIGNED" } />
                                    <DashboardDetail field={ 'Effective Date' } value={ moment(quote.effective?.seconds, 'X') } format='MMM DD, YYYY' type="moment" />
                                    <DashboardDetail field={ 'Expiration Date' } value={ moment(quote.expiration?.seconds, 'X') } format='MMM DD, YYYY' type="moment" />
                                    <DashboardDetail field={ 'Start Date' } value={ moment(quote.startDate?.seconds, 'X')} format='MMM DD, YYYY h:mm A' type="moment" />
                                    <DashboardDetail field={ 'End Date' } value={ moment(quote.endDate?.seconds, 'X')} format='MMM DD, YYYY h:mm A' type="moment" />
                                    <DashboardDetail field={ 'Description' } value={ quote.description } type="text" />
                                    <DashboardDetail field={ 'Converted' } value={ converted } type="text" />
                                    <DashboardDetail field={ 'Created By' } value={ `${owner?.contact.firstName} ${owner?.contact.lastName}` } type="text" />
                                    <div className="clearfix"></div>
                                </div>
                            </ExpandDetails>

                            { quote.statusId == QUOTES.ACCEPTED.id &&
                                <div className="alert alert-info mt-3 mb-0 d-flex align-items-center">
                                    <i className="fa-solid fa-circle-info fa-2x mr-3"></i>
                                    <span>
                                        This Quote has been Accepted!  The next step is to convert to a <strong>Work Order</strong> from the <strong>Actions</strong> button.
                                    </span>
                                </div>
                            }

                            { !admin.bottom_sheet_show && params.form == 'form' &&
                                <SpeedDialButton 
                                    columnRef={ props.columnRef }
                                    onClick={handleSpeedDial}
                                    actions={[
                                    ...(!isClosed && user_permissions[PERMISSIONS.QUOTES_CLOSE.key]) ? [{
                                        name: 'Mark Closed', 
                                        value: 'closed', 
                                        className: '', 
                                        icon: <i className="fa-solid fa-xmark fa-xl"></i> 
                                    }] : [],
                                    ...(!isClosed && quote.statusId == QUOTES.ACCEPTED.id) ? [{
                                        name: 'Convert to New Service Request', 
                                        value: 'convert_request', 
                                        className: '', 
                                        icon: <i className="fa-solid fa-folder-plus fa-xl"></i>
                                    }] : [],
                                    ...(!isClosed && quote.statusId == QUOTES.ACCEPTED.id) ? [{
                                        name: 'Convert to Work Order', 
                                        value: 'convert_workorder', 
                                        className: '', 
                                        icon: <i className="fa-solid fa-file-plus fa-xl"></i>
                                    }] : [],
                                    ...(!isClosed && quote.statusId == QUOTES.DRAFT.id || quote.statusId == QUOTES.PENDING.id) ? [{
                                        name: 'Email to Customer', 
                                        value: 'email', 
                                        className: '', 
                                        icon: <i className="fa-solid fa-paper-plane-top fa-xl"></i> 
                                    }] : [],
                                    // print?
                                    ]}
                                />
                            }

                            <Sheet isOpen={admin.bottom_sheet_show} onClose={() => setShowBottomSheet(false)} detent="content-height">
                                <Sheet.Container >
                                    <Sheet.Header />
                                    <Sheet.Content>
                                        <h2>More Options</h2>
                                        {/* <button className="btn btn-info btn-xl mb-2" onClick={ () => openBuilder(SERVICE_ITEMS.LABOR.id) }>LABOR</button>
                                        <button className="btn btn-primary btn-xl mb-2" onClick={ () => openBuilder(SERVICE_ITEMS.STOCK_PARTS.id) }>STOCK PARTS</button>
                                        <button className="btn btn-primary btn-xl mb-2" onClick={ () => openBuilder(SERVICE_ITEMS.NON_STOCK_PARTS.id) }>NON-STOCK PARTS</button>
                                        <button className="btn btn-primary btn-xl mb-2" onClick={ () => openBuilder(SERVICE_ITEMS.TRAVEL.id) }>TRAVEL</button>
                                        <button className="btn btn-default btn-xl mb-2" onClick={ () => setShowBottomSheet(false) }>CANCEL</button> */}
                                    </Sheet.Content>
                                </Sheet.Container>
                                <Sheet.Backdrop onTap={ () => setShowBottomSheet(false) } />
                            </Sheet>

                        </div>
                    </Authorize>
                :   <Spinner center />
            }

            {/* Integrate Routes under this component -------------------------------------------------- */}

            { params.form == 'send-email' &&
                <SendEmailForm
                    contacts={profiles.profile?.contacts}
                    submit_callback={sendEmail}
                    type={'Quote'}
                    id={params.quote_id}
                />
            }
        </div>
    );
};

export default QuoteDashboard;
